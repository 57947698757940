import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import Http from '@/services/Http'

export const useTwitterBookmarkStore = defineStore(
  'twitter_bookmarks',
  () => {
    const items = ref([])
    const loading = ref(false)
    const hasError = ref(false)

    const count = computed(() => items.value.length)
    const hasItems = computed(() => count.value > 0)
    const tweets = computed(() => items.value.map((b) => b.tweet))

    async function loadAll(onlyLatest = false) {
      if (loading.value) return
      try {
        loading.value = true
        const { data } = await Http.get(
          `twitter/bookmarks` +
            (onlyLatest && hasItems.value ? `?since_id=${items.value[0].id}` : ``)
        )
        items.value = onlyLatest && hasItems.value ? [...data, ...items.value] : data || []
      } catch (e) {
        hasError.value = true
      } finally {
        loading.value = false
      }
    }

    async function reject(bookmarkId: string): Promise<void> {
      await Http.delete(`twitter/bookmarks/${bookmarkId}`)
    }

    return { count, items, loading, hasError, hasItems, loadAll, reject, tweets }
  },
  {
    persist: {
      paths: ['items'],
    },
  }
)
