import { BaseModel } from '@/stores/models/BaseModel'
// @ts-ignore
import AssetDTO = Brainy.Asset.Contracts.DataTransferObjects.AssetDTO
import type { UserModel } from '@/stores/models/UserModel'

export class AssetModel extends BaseModel<AssetDTO> implements AssetDTO {
  declare filename: string
  declare hash: string
  declare id: string
  declare mime_type: string
  declare size: number
  declare src: string
  declare path: string
  declare uploaded_by: string

  declare creator: UserModel
}
