// @ts-ignore
import InviteDTO = Brainy.Workspace.Contracts.DataTransferObjects.InviteDTO
import { BaseModel } from '@/stores/models/BaseModel'

export class InviteModel extends BaseModel<InviteDTO> implements InviteDTO {
  declare created_at: string
  declare email: string
  declare id: string
  declare role: any
  declare token: string
  declare updated_at: string
  declare url: string
}
