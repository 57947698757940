<template>
  <div
    v-if="show"
    class="relative w-full rounded border border-grey-50 shadow-progress dark:border-black-800 dark:shadow-dark-dropdown"
  >
    <div
      v-if="expanded && completedTasks.length === tasks.length"
      class="absolute inset-0 bg-white bg-opacity-50 backdrop-blur dark:bg-black"
    >
      <div class="px-8 text-center space-y-4" :class="tasks.length > 3 ? 'pt-10' : 'pt-6'">
        <BdsText size="f13">
          <span class="font-medium">Great!</span>
          You’re now ready to organize your brain.
        </BdsText>
        <BdsButton xs primary @click="dismiss">Let's go</BdsButton>
      </div>
    </div>
    <div v-if="expanded" class="flex flex-col space-y-3 px-4 pt-4 pb-2">
      <div
        v-for="(task, idx) in tasks"
        :key="`task-${idx}`"
        class="flex items-center justify-between"
      >
        <BdsText
          :line-through="task.completed()"
          size="xs"
          class="cursor-pointer"
          :class="{
            'text-grey-400 hover:text-grey-800 dark:text-black-300 dark:hover:text-black-100':
              !task.completed(),
          }"
          leading-none
          @click="task.perform()"
        >
          {{ `${idx + 1}. ${task.label}` }}
        </BdsText>
        <BdsText
          v-if="task.external || task.shortcut"
          size="xs"
          class="text-grey-400 dark:text-black-300"
          leading-none
        >
          <BdsIcon v-if="task.external" name="External" class="w-2.5 h-2.5" />
          <template v-else>{{ task.shortcut }}</template>
        </BdsText>
      </div>
    </div>
    <div
      class="p-4 flex flex-col space-y-3 cursor-pointer select-none"
      @click="expanded = !expanded"
    >
      <div class="flex items-center justify-between">
        <BdsText size="f13">{{ completedTasks.length }}/{{ tasks.length }} tasks</BdsText>
        <div
          class="py-1 px-1.5 rounded bg-blurple-50 text-blurple-500 text-xs leading-none dark:bg-blurple-900"
        >
          Getting started
        </div>
      </div>
      <div class="relative rounded w-full h-1 bg-blurple-50 dark:bg-blurple-900">
        <div
          class="absolute inset-0 rounded bg-blurple-500"
          :style="{ width: `${progress}%` }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BdsButton, BdsIcon, BdsText } from '@getbrainy/bds-components'
import { onMounted, ref } from 'vue'

import useConfetti from '@/hooks/useConfetti'
import useOnboardingProgress from '@/hooks/useOnboardingProgress'

const show = ref(false)
const expanded = ref(false)

const emit = defineEmits(['compose', 'status'])

const { tasks, completedTasks, progress } = useOnboardingProgress(emit)

onMounted(() => {
  show.value = tasks.value.length !== completedTasks.value.length
  emit('status', show.value)
})

const { blast } = useConfetti()
function dismiss() {
  blast()
  show.value = false
  emit('status', show.value)
}
</script>
