import { computed, ref, watchEffect } from 'vue'
import { createConfirmDialog } from 'vuejs-confirm-dialog'

import MediaOverlay from '@/components/Modals/MediaOverlay.vue'

const hasActiveOverlay = ref(false)
export function useMediaOverlayStatus() {
  return computed(() => hasActiveOverlay.value)
}

function useMediaOverlay(image: string, images: string[] = []): void {
  const { reveal, isRevealed } = createConfirmDialog(MediaOverlay, {
    image,
    images,
  })

  watchEffect(() => (hasActiveOverlay.value = isRevealed.value))

  reveal()
}

export default useMediaOverlay
