import { type ComputedRef, computed } from 'vue'

import {
  type Subscription,
  type Topic,
  type User,
  useMemberStore,
  useSubscriptionStore,
} from '@/stores'
import type { Asset } from '@/stores/asset.store'
import { useAssetStore } from '@/stores/asset.store'

export interface Follower {
  id?: string
  name?: string
  initials?: string
  avatar?: Asset | null
}

function useFollowers(topic: ComputedRef<Topic | null>): ComputedRef<Follower[]> {
  const subscriptionStore = useSubscriptionStore()
  const memberStore = useMemberStore()
  const assetStore = useAssetStore()

  const subs = computed(() => subscriptionStore.groupedByEntity)
  const users = computed(() => memberStore.users)

  return computed(
    () =>
      subs.value?.[topic.value?.id]?.map((sub: Subscription) => {
        const user = users.value.find((u) => u.id === sub.user_id) as User

        if (!user) {
          return { initials: 'UN' }
        }

        const avatar = user.avatar_asset_id ? assetStore.byId(user.avatar_asset_id) : null

        return {
          id: user.id,
          name: user.name,
          initials: user.initials,
          avatar: avatar,
        } as Follower
      }) ?? []
  )
}

export default useFollowers
