<script lang="ts">
import { BdsAvatar } from '@getbrainy/bds-components'
import { defineComponent, h } from 'vue'

import { useUserAvatar } from '@/hooks'

export default defineComponent({
  name: 'RenderlessUserAvatar',
  props: {
    userId: { type: String, required: true },
  },
  setup(props) {
    const avatar = useUserAvatar(props.userId)

    return () => {
      return h(BdsAvatar, {
        initials: avatar.value?.initials,
        src: avatar.value?.src,
        active: avatar.value?.active,
      })
    }
  },
})
</script>
