import type { ComputedRef } from 'vue'
import { computed } from 'vue'

import type { Workspace } from '@/stores/workspace.store'
import { useWorkspaceStore } from '@/stores/workspace.store'

function useCurrentWorkspace(): ComputedRef<Workspace | null> {
  const workspace = useWorkspaceStore()

  return computed(() => workspace.current)
}

export default useCurrentWorkspace
