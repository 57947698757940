// @ts-ignore
import TweetDTO = Brainy.Document.Contracts.DataTransferObjects.Twitter.TweetDTO
import { BaseModel } from '@/stores/models/BaseModel'

export class TweetModel extends BaseModel<TweetDTO> implements TweetDTO {
  declare author: Brainy.Document.Contracts.DataTransferObjects.Twitter.TwitterAuthorDTO
  declare conversation_id: string | null
  declare created_at: string
  declare id: string
  declare media: any
  declare text: string
  declare tweets: Array<Brainy.Document.Contracts.DataTransferObjects.Twitter.TweetDTO>
  declare urls: any
}
