import * as Sentry from '@sentry/vue'
import { collect } from 'collect.js'

import { makeError, makeToast } from '@/helpers'
import { useBrainyStores } from '@/stores'

export default async function checkContentMismatch(notify = false) {
  const stores = useBrainyStores()
  const result = collect(Object.keys(stores))
    .map(async (name: string) => {
      const store = stores[name]
      if (!store) {
        return null
      }

      const snapshot = new Map(store.items)

      const current = await store.$fetch()
      // console.log('snapshot', snapshot)
      // current.push(snapshot.test)
      // console.log('current', current)
      // console.log('same count?', snapshot.length == current.length)
      if ([...snapshot.values()] != current) {
        // console.log('not matching')
        const diff = collect(current)
          .keyBy('id')
          .filter((c) => c)
          .map((c: any) => {
            if (!c?.id) {
              // debugger
            }
            const old = snapshot.get(c.id)
            return collect(Object.keys(c))
              .filter((key) => old?.[key])
              .map((key) => ({
                local: old[key],
                remote: c[key],
              }))
              .reject((x) => JSON.stringify(x.local) == JSON.stringify(x.remote))
              .all()
          })
          .filter((x: any) => x.length)
          .all()

        const currentIds = collect(current).keyBy('id').keys().all()
        const notDeleted = collect([...snapshot.keys()])
          .reject((id) => currentIds.includes(id))
          .all()
        const snapshottedIds = [...snapshot.keys()]
        const notCreated = collect(currentIds)
          .reject((id) => snapshottedIds.includes(id))
          .all()

        const remoteSyncId = collect(current)
          .filter((d) => d.updated_at)
          .map((x) => ({
            id: x.id,
            sync: Date.parse(x.updated_at),
          }))
          .values()
          .sortBy('sync')
          .first() as { id: string; sync: number }

        const isUpToDate =
          notCreated.length === 0 && notDeleted.length === 0 && Object.keys(diff).length === 0

        notDeleted.forEach((d: string) => store.remove(d))

        const result = {
          store: store.$id,
          isUpToDate,
          diff,
          notDeleted,
          notCreated,
          syncId: store.syncId,
          storeSyncId: store._syncId,
          remoteSyncId: remoteSyncId,
          isBehind: remoteSyncId?.sync > store._syncId,
        }

        return result
      }
    })
    .filter((x) => x !== null)

  const processed = collect(await Promise.all(result.all())).reject((a) => {
    return !a || a.isUpToDate
  })

  if (processed.all().length === 0) {
    if (notify) {
      makeToast('Up to date')
    }
    console.log('Stores up to date')
  } else {
    console.log('data mismatch')
    processed.each((entry) => {
      const { store, diff, notCreated, notDeleted, remoteSyncId, ...rest } = entry
      console.log(`Store mismatch: ${store}`, {
        ...rest,
        remoteSyncId: remoteSyncId?.sync,
      })
      console.log('Not created', notCreated)
      console.log('Not deleted', notDeleted)
      console.log('Diff:')
      Object.keys(diff).forEach((key) => {
        console.log(key, JSON.stringify(diff[key]))
      })

      Sentry.captureMessage(`Data sync check failed: ${store}`, {
        extra: {
          mismatches: processed.count(),
          result: processed
            .map((p) => {
              const { diff, notCreated, notDeleted, remoteSyncId, ...rest } = p
              return {
                ...rest,
                remoteSyncId: remoteSyncId?.sync,
              }
            })
            .all(),
        },
      })
    })

    console.log(processed.all())

    if (notify) {
      makeError(`Some data was missing. Updated ${processed.count()} items.`)
    }
  }
}
