import type { MakeMutationOptions } from './index'

const noop = async (): Promise<void> => {}

export const useMakeMutation = async <T extends any>(options: Partial<MakeMutationOptions<T>>) => {
  const {
    onError = noop,
    onCommit = noop,
    onLocalCommit = noop,
    onRollback = noop,
    localAction = noop,
    remoteAction = noop,
  } = options

  try {
    // @ts-ignore
    await onLocalCommit(await localAction())

    try {
      const result = await remoteAction()
      // @ts-ignore
      return (await onCommit(result)) || result
    } catch (e) {
      onError(e)
      onRollback(e)
      throw e
    }
  } finally {
  }
}

export default useMakeMutation
