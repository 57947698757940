import { defineStore } from 'pinia'
import { computed } from 'vue'

import { useBrainyStore } from '@/hooks'
import { ReminderModel } from '@/stores/models/ReminderModel'

export type Reminder = ReminderModel

export const useReminderStore = defineStore('reminders', () => {
  const state = useBrainyStore<Reminder>('reminders', {
    model: 'ReminderDTO',
    makeModel: (attrs) => new ReminderModel(attrs),
  })

  const read = computed(() => state.all.value.filter((x) => x.read_at !== null))
  const unread = computed(() => state.all.value.reverse().filter((x) => !x.read_at))

  return {
    ...state,
    read,
    unread,

    async markRead(id: ReminderModel['id']) {
      state.update(id, { read_at: new Date().toISOString() })
    },
    async markUnread(id: ReminderModel['id']) {
      state.update(id, { read_at: null })
    },
    async deleteAllRead() {
      read.value.forEach((r) => state.delete(r.id))
    },
    async deleteAll() {
      state.all.value.forEach((r) => state.delete(r.id))
    },
  }
})
