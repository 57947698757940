import type { Router, RouteRecordRaw } from 'vue-router'

const moduleRoute: RouteRecordRaw = {
  path: '/p/:username',
  component: () => import('./Module.vue'),
  children: [
    {
      path: '',
      name: 'profiles.show',
      component: () => import('./views/Show.vue'),
      meta: {
        title: 'Profile',
      },
    },
  ],
}

export default (router: Router) => {
  router.addRoute(moduleRoute)
}
