import { defineStore } from 'pinia'
import { computed, reactive, toRefs } from 'vue'

type SidebarValue = 'open' | 'closed'
type Layout = 'list' | 'grid' | 'timeline'

interface PreferenceState {
  topicView: Layout
  navigation: SidebarValue
  mobileNavigation: SidebarValue
  sidebar: SidebarValue
}

export const usePreferencesStore = defineStore(
  'preferences',
  () => {
    const state = reactive<PreferenceState>({
      topicView: 'grid',
      navigation: 'open',
      mobileNavigation: 'closed',
      sidebar: 'open',
    })

    return {
      ...toRefs(state),
      navigationOpen: computed(() => state.navigation === 'open'),
      mobileNavigationOpen: computed(() => state.mobileNavigation === 'open'),
      sidebarOpen: computed(() => state.sidebar === 'open'),

      setTopicView(view: Layout) {
        state.topicView = view
      },
      toggleNavigation() {
        state.navigation === 'open' ? this.closeNavigation() : this.openNavigation()
      },
      toggleMobileNavigation() {
        state.mobileNavigation = state.mobileNavigation === 'open' ? 'closed' : 'open'
      },
      toggleSidebar() {
        state.sidebar = state.sidebar === 'open' ? 'closed' : 'open'
      },
      closeNavigation() {
        state.navigation = 'closed'
      },
      openNavigation() {
        state.navigation = 'open'
      },
    }
  },
  { persist: true }
)
