<template>
  <FollowersModal :followers="followers" show @close="$emit('cancel')" />
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue'

import { useFollowers } from '@/hooks'
import FollowersModal from '@/modules/topics/components/Modals/FollowersModal.vue'
import { Topic } from '@/stores'

const props = defineProps({
  topic: { type: Object as PropType<Topic>, required: true },
})

defineEmits(['cancel'])

const topic = computed(() => props.topic)
const followers = useFollowers(topic)
</script>
