import type { ConfigurableFlush, MaybeComputedRef, RemovableRef } from '@vueuse/shared'
import { resolveUnref } from '@vueuse/shared'
import { createStore, del, get, set, update } from 'idb-keyval'
import type { Ref } from 'vue'
import { ref, shallowRef, watch } from 'vue'

export interface UseIDBOptions extends ConfigurableFlush {
  /**
   * Watch for deep changes
   *
   * @default true
   */
  deep?: boolean

  /**
   * On error callback
   *
   * Default log error to `console.error`
   */
  onError?: (error: unknown) => void

  /**
   * Use shallow ref as reference
   *
   * @default false
   */
  shallow?: boolean
  /**
   * Write the default value to the storage when it does not exist
   *
   * @default true
   */
  writeDefaults?: boolean
}

/**
 *
 * @param key
 * @param initialValue
 * @param options
 */
export function useBrainyIDBKeyval<T>(
  key: IDBValidKey,
  initialValue: MaybeComputedRef<T>,
  storeName = 'keyval',
  options: UseIDBOptions = {}
): { data: RemovableRef<T>; isFinished: Ref<boolean> } {
  const {
    flush = 'pre',
    deep = true,
    shallow = false,
    onError = (e) => {
      console.error(e)
    },
    writeDefaults = true,
  } = options

  const isFinished = ref(false)
  const data = (shallow ? shallowRef : ref)(initialValue) as Ref<T>
  const store = createStore(storeName, 'keyval')

  const rawInit: T = resolveUnref(initialValue)

  function replacer(key, value) {
    if (value instanceof Map) {
      return { __type: 'Map', value: Object.fromEntries(value) }
    }
    if (value instanceof Set) {
      return { __type: 'Set', value: Array.from(value) }
    }
    return value
  }

  function reviver(key, value) {
    if (value?.__type === 'Set') {
      return new Set(value.value)
    }
    if (value?.__type === 'Map') {
      return new Map(Object.entries(value.value))
    }
    return value
  }

  async function read() {
    try {
      const rawValue = await get<T>(key, store)
      if (rawValue === undefined) {
        if (rawInit !== undefined && rawInit !== null && writeDefaults)
          await set(key, rawInit, store)
      } else {
        data.value = rawValue
      }
    } catch (e) {
      onError(e)
    }
    isFinished.value = true
  }

  read()

  async function write() {
    try {
      if (data.value == null) {
        await del(key)
      } else {
        // IndexedDB does not support saving proxies, convert from proxy before saving
        if (Array.isArray(data.value))
          await update(key, () => JSON.parse(JSON.stringify(data.value)), store)
        else if (data.value instanceof Map)
          await update(key, () => JSON.parse(JSON.stringify(data.value, replacer), reviver), store)
        else if (typeof data.value === 'object') await update(key, () => ({ ...data.value }), store)
        else await update(key, () => data.value, store)
      }
    } catch (e) {
      onError(e)
    }
  }

  watch(data, () => write(), { flush, deep })

  return { isFinished, data: data as RemovableRef<T> }
}
