// @ts-ignore
import EditorDTO = Brainy.Document.Contracts.DataTransferObjects.EditorDTO
import { BaseModel } from '@/stores/models/BaseModel'
import type { UserModel } from '@/stores/models/UserModel'

export class EditorModel extends BaseModel<EditorDTO> implements EditorDTO {
  declare created_at: string
  declare document_id: string
  declare id: string
  declare user_id: string

  declare user: UserModel
}
