import { defineStore } from 'pinia'
import { computed } from 'vue'

import { useBrainyStore } from '@/hooks'
import { useMemberStore } from '@/stores/member.store'
import { EditorModel } from '@/stores/models/EditorModel'

export type Editor = EditorModel

export const useEditorStore = defineStore('editors', () => {
  const state = useBrainyStore<Editor>('editors', {
    model: 'EditorDTO',
    makeModel: (attrs) => new EditorModel(attrs),
    relations: ['user'],
    resolveRelation: (name, target) => {
      if (name == 'user') {
        const members = useMemberStore()
        return members.userById(target.user_id)
      }
    },
  })

  const groupedByDocument = state.$groupedBy('document_id', {
    multiple: true,
  })

  return {
    ...state,

    groupedByDocument,
    byDocumentId: computed(() => {
      return (id: Editor['document_id']) => {
        return groupedByDocument.value[id]
      }
    }),
  }
})
