// @ts-ignore
import WorkspaceDTO = Brainy.Workspace.Contracts.DataTransferObjects.WorkspaceDTO
import { useCurrentUser } from '@/hooks'
import { useMemberStore } from '@/stores'
import { BaseModel } from '@/stores/models/BaseModel'
import { WorkspaceType } from '@/types/enums'

export class WorkspaceModel extends BaseModel<WorkspaceDTO> implements WorkspaceDTO {
  declare id: string
  declare type: WorkspaceType
  declare initials: string
  declare logo_asset_id: string | null
  declare logo_url: string | null
  declare name: string
  declare slug: string
  declare industry: string | null
  declare size: string | null
  declare oauth_providers: OAuthProviderDTO[]

  get isCurrentUserAdmin() {
    const user = useCurrentUser()
    const members = useMemberStore()
    const me = members.byUserId(user.value?.id)

    return me?.isWorkspaceOwner || me?.isAdmin
  }

  get isPersonal() {
    return this.get('type') === WorkspaceType.PERSONAL
  }

  get isTeam() {
    return this.get('type') === WorkspaceType.TEAM
  }

  hasActiveProvider(provider: string): boolean {
    return this.oauth_providers?.data?.some((p) => p.provider === provider && p.is_active) || false
  }

  hasProvider(provider: string | string[]): boolean {
    return (
      this.oauth_providers?.data?.some((p) =>
        Array.isArray(provider) ? provider.includes(p.provider) : p.provider === provider
      ) || false
    )
  }
}
