import type { PusherPrivateChannel } from 'laravel-echo/dist/channel'
import { onMounted, ref, watch, watchEffect } from 'vue'

import Http from '@/services/Http'
import { useAuthStore } from '@/stores'
import { createPiniaSyncPlugin, useLastSyncId } from '@/stores/plugins/sync-plugin'

import useEcho from '../../hooks/useEcho'

let workspaceChannel: PusherPrivateChannel
let personalChannel: PusherPrivateChannel

const workspaceQueue: Function[] = []
const personalQueue: Function[] = []

const readyForBootstrap = ref(false)

const lastSyncId = useLastSyncId()

watch(readyForBootstrap, (ready) => {
  if (!ready) {
    return console.log('not ready yet')
  }

  if (lastSyncId.value > 0) {
    // setTimeout(() => {
    Http.get('/sync/bootstrap?lastSync=' + lastSyncId.value)
    // }, 2500)
  }
})

let hasBeenRegistred = false
const registerPlugin = () => {
  if (hasBeenRegistred) {
    return
  }
  hasBeenRegistred = true

  onMounted(async () => {
    const echo = await useEcho()

    const store = useAuthStore()
    watchEffect(() => {
      const userId = store.currentUser?.id
      if (!userId) {
        return
      }

      if (!personalChannel || !personalChannel.name.endsWith(userId)) {
        personalChannel = echo.private('user.' + userId) as PusherPrivateChannel
        personalChannel.subscribed(() => (readyForBootstrap.value = true))
      }

      let handler
      while ((handler = personalQueue.pop())) {
        personalChannel.listen('.EntityChanged', handler)
      }
    })

    watchEffect(() => {
      const workspaceId = store.currentWorkspace
      if (!workspaceId) {
        return
      }

      if (!workspaceChannel || !workspaceChannel.name.endsWith(workspaceId)) {
        workspaceChannel = echo.private('workspace.' + workspaceId) as PusherPrivateChannel
      }

      let handler
      while ((handler = workspaceQueue.pop())) {
        workspaceChannel.listen('.EntityChanged', handler)
      }
    })
  })
}

export function brainySyncPlugin() {
  const queueWorkspaceChannel = (handler: Function) => {
    if (workspaceChannel) {
      workspaceChannel.listen('.EntityChanged', handler)
    } else {
      workspaceQueue.push(handler)
    }
  }

  const queuePersonalChannel = (handler: Function) => {
    if (personalChannel) {
      personalChannel.listen('.EntityChanged', handler)
    } else {
      personalQueue.push(handler)
    }
  }

  return createPiniaSyncPlugin((updateHandler) => {
    registerPlugin()
    queueWorkspaceChannel(updateHandler)
    queuePersonalChannel(updateHandler)
  })
}
