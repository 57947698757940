import { BaseModel } from '@/stores/models/BaseModel'
// @ts-ignore
import DocumentTopicDTO = Brainy.Document.Contracts.DataTransferObjects.DocumentTopicDTO
import { useTopicStore } from '@/stores'
import { TopicType } from '@/types/enums'

export class DocumentTopicModel extends BaseModel<DocumentTopicDTO> implements DocumentTopicDTO {
  declare created_at: string
  declare document_id: string
  declare id: string
  declare topic_id: string
  declare type: typeof TopicType

  get isAI() {
    return this.get('type') === TopicType.AI
  }

  get emoji() {
    const topics = useTopicStore()

    return topics.byId(this.get('topic_id'))?.emoji
  }
  get name() {
    const topics = useTopicStore()

    return topics.byId(this.get('topic_id'))?.name
  }
  get label() {
    const topics = useTopicStore()

    return topics.byId(this.get('topic_id'))?.label
  }
  get color() {
    const topics = useTopicStore()

    return topics.byId(this.get('topic_id'))?.color
  }

  get urls() {
    const topics = useTopicStore()

    return topics.byId(this.get('topic_id'))?.urls
  }
}
