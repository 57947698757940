import { computed } from 'vue'

import { useCurrentUser, useDocuments } from '@/hooks/index'
import { type Subscription, useSubscriptionStore, useTopicStore } from '@/stores'
import { type Reader, useReaderStore } from '@/stores/reader.store'

function useCatchUp() {
  const topicStore = useTopicStore()
  const wip = computed(() => topicStore.WIP)
  const readStore = useReaderStore()
  const subscriptions = useSubscriptionStore()

  const userId = useCurrentUser().value?.id
  const mySubs = subscriptions.allMine
    .map((s: Subscription) => s.entity_id)
    .filter((s) => s.startsWith('top_'))
  const readDocs = readStore.allMine.map((r: Reader) => r.document_id)

  return computed(() =>
    useDocuments({
      filter: (doc) => {
        const isCurrentUser = doc.user_id === userId
        const haveRead = readDocs.includes(doc.id)

        return !isCurrentUser && !haveRead
      },
    }).value.filter((doc) => {
      const isWIP = wip.value?.id ? doc.topics?.some((t) => t.topic_id === wip.value!.id) : false
      const isMySub = doc.topics?.some((topic) => mySubs.includes(topic.topic_id))

      return isMySub && !isWIP
    })
  )
}

export default useCatchUp
