import './assets/main.css'

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { useRegisterSW } from 'virtual:pwa-register/vue'
import { createApp } from 'vue'
import { plugin as VueTippy } from 'vue-tippy'
import Toast, { type PluginOptions, POSITION } from 'vue-toastification'

import { registerModules } from '@/register-modules'
import { brainySyncPlugin } from '@/stores/plugins/brainy-sync-plugin'

import App from './App.vue'
import authModule from './modules/auth'
import documentModule from './modules/documents'
import profileModule from './modules/profiles'
import topicModule from './modules/topics'
import workspaceModule from './modules/workspace'
import router from './router'

const app = createApp(App)
app.config.performance = import.meta.env.DEV

// eslint-disable-next-line simple-import-sort/imports
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
// @ts-ignore
pinia.use(brainySyncPlugin())

const intervalMS = 60 * 60 * 1000

const updateServiceWorker = useRegisterSW({
  onRegisteredSW(swUrl, r) {
    r &&
      setInterval(async () => {
        if (!(!r.installing && navigator)) return

        if ('connection' in navigator && !navigator.onLine) return

        const resp = await fetch(swUrl, {
          cache: 'no-store',
          headers: {
            cache: 'no-store',
            'cache-control': 'no-cache',
          },
        })

        if (resp?.status === 200) await r.update()
      }, intervalMS)
  },
})

const toastOptions: PluginOptions = {
  timeout: 2500,
  icon: false,
  transition: {
    enter: 'slide-in-active',
    leave: 'slide-out-active',
    move: 'fade-move',
  },
  position: POSITION.BOTTOM_RIGHT,
  closeButton: false,
  closeOnClick: false,
  toastClassName: 'toast',
  hideProgressBar: true,
}
app
  .use(pinia)
  .use(VueTippy, {
    defaultProps: {
      placement: 'bottom',
    },
  })
  .use(Toast, toastOptions)

registerModules({
  auth: authModule,
  document: documentModule,
  profile: profileModule,
  topic: topicModule,
  workspace: workspaceModule,
})

const fallbackEnvironment: string =
  import.meta.env.VITE_APP_ENV === 'local' ? 'local' : import.meta.env.VITE_BRANCH_NAME
const environment =
  {
    main: 'production',
    develop: 'staging',
    demo: 'demo',
  }[import.meta.env.VITE_BRANCH_NAME] ?? fallbackEnvironment

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    Vue: app,
    dsn: import.meta.env.VITE_SENTRY_DSN,

    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    release: import.meta.env.VITE_RELEASE_HASH ?? 'brainy@dev',

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // integrations: [new Integrations.BrowserTracing()],
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', 'app.brainy.so', 'api.brainy.so', /^\//],
      }),
      new ExtraErrorDataIntegration({
        depth: 7,
      }),
      new Sentry.Replay({
        maskAllText: false,
      }),
    ],

    environment,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0 * parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
  })
}

app.use(router)

app.mount('#app')
