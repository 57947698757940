import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { useBrainyStore, useCurrentUser } from '@/hooks'
import { useDocumentStore } from '@/stores/document.store'
import { useMemberStore } from '@/stores/member.store'
import { ReaderModel } from '@/stores/models/ReaderModel'

export type Reader = ReaderModel

export const useReaderStore = defineStore('readers', () => {
  const state = useBrainyStore<Reader>('readers', {
    model: 'ReaderDTO',
    makeModel: (attrs) => new ReaderModel(attrs),
    relations: ['document', 'user'],
    resolveRelation: (name, target) => {
      if (name === 'document') {
        const documentStore = useDocumentStore()
        return documentStore.byId(target.document_id)
      }
      if (name === 'user') {
        const memberStore = useMemberStore()
        return memberStore.userById(target.user_id)
      }
    },
  })

  const readerModalId = ref(null)

  const groupedByDocument = state.$groupedBy('document_id', {
    multiple: true,
  })

  const byDocumentAndUserId = (userId: Reader['user_id'], documentId: Reader['document_id']) => {
    return state.filter({
      filter: (e) => e.document_id === documentId && e.user_id === userId,
    }).value[0]
  }

  return {
    ...state,

    readerModalId,
    groupedByDocument,

    byDocumentId: computed(() => {
      return (id: Reader['document_id']) => {
        return groupedByDocument.value[id]
      }
    }),
    byDocumentAndUserId: computed(() => byDocumentAndUserId),

    async getOrCreate(documentId: Reader['document_id']) {
      const user = useCurrentUser()
      const reader = byDocumentAndUserId(user.value!.id, documentId)
      if (reader) {
        return reader
      }

      const created = await state.create({
        user_id: user.value!.id,
        document_id: documentId,
        progress: 0,
      })

      if (created) {
        this.upsert(created)
      }

      return created
    },
    setReaderModalId: (id: string | null) => {
      readerModalId.value = id
    },
  }
})
