<template>
  <div class="mt-6 pt-1 border-t border-light-divider">
    <div
      class="text-sm rounded-lg flex flex-col items-center justify-center mt-12 mb-6 space-y-4"
      @click="onClick"
    >
      <BdsSpinner v-if="isLoading" />
      <TwitterBird
        v-else
        class="h-6 w-6"
        :class="{ 'text-red': hasError, 'text-[#4A99E9]': !hasError }"
      />

      <span v-if="isLoading && isSlow">
        This is taking a while.. We're still working on getting the tweets.
      </span>
      <span v-if="!isLoading && hasNewTweets && !hasError" class="block cursor-pointer mt-2">
        <span class="underline">Click here to load the thread</span>
      </span>
      <span v-if="hasError" class="text-center">
        We're missing a few tweets here, sorry about that!
        <br />
        Check back again later, and we might've been able to fetch them by then.
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BdsSpinner } from '@getbrainy/bds-components'

import TwitterBird from './TwitterBird.vue'

const emit = defineEmits(['reload'])

const props = withDefaults(
  defineProps<{
    isLoading: boolean
    isSlow: boolean
    hasError: boolean
    hasNewTweets: boolean
  }>(),
  {
    isLoading: false,
    isSlow: false,
    hasError: false,
    hasNewTweets: false,
  }
)

const onClick = () => {
  if (props.isLoading) {
    return
  }

  emit('reload')
}
</script>
