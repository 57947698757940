<template>
  <input
    ref="file"
    type="file"
    class="w-px h-px opacity-0 overflow-hidden absolute"
    :multiple="multiple"
    :name="name"
    :accept="accept"
    tabindex="-1"
    @change="onChange"
  />

  <UploadModal :show="showGlobalModal" :uploading="processing" />

  <div v-if="(preview || current) && !editing" class="flex">
    <slot
      name="show"
      v-bind="{
        src: preview || current,
        circle,
        edit: () => (editing = true),
        select: () => file.click(),
      }"
    >
      <img
        :src="preview || current"
        :class="[circle ? 'rounded-full object-cover h-full w-full' : 'mx-auto']"
      />

      <BdsButtonIcon
        v-tippy="{ content: 'Upload photo' }"
        class="absolute bottom-0 right-6"
        xs
        icon="Pencil"
        @click.prevent="handlePencilClick"
      />
    </slot>
  </div>
  <template v-else-if="onlyBtn">
    <div :class="wrapperClass">
      <slot name="trigger" v-bind="{ select: () => file.click(), preview, processing }">
        <div
          class="flex text-sm text-grey-800 dark:text-black-100 justify-center items-center text-center py-6"
        >
          <a href="#" class="no-underline" @click.prevent="file.click()">+ Upload new file</a>
        </div>
      </slot>
    </div>
  </template>
  <div
    v-else
    class="flex justify-center items-center text-center bg-grey-50 text-sm text-grey-800 dark:bg-black-800 dark:text-black-100"
    :class="[circle ? 'rounded-full w-full h-full p-4' : 'rounded-md p-10']"
    @dragover.prevent="onDragover"
    @dragleave.prevent="onDragleave"
    @drop.stop.prevent="onDrop"
  >
    <BdsSpinner v-if="processing" lg />
    <div v-else>
      Drag and drop your {{ multiple ? 'images' : 'image ' }} or
      <a href="#" class="text-inherit underline" @click.prevent="file.click()">browse</a>
    </div>
  </div>
  <div v-if="errorMessage" class="mt-1 text-red">{{ errorMessage }}</div>
</template>

<script lang="ts">
import { BdsButtonIcon, BdsSpinner } from '@getbrainy/bds-components'
import { useEventListener } from '@vueuse/core'
import { computed, onMounted, ref, watch } from 'vue'

import UploadModal from './UploadModal.vue'

export default {
  components: { BdsSpinner, BdsButtonIcon, UploadModal },
  props: {
    accept: {
      type: String,
      default: 'image/*',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    processing: {
      type: Boolean,
      default: false,
    },
    circle: {
      type: Boolean,
      default: false,
    },
    current: {
      type: String,
    },
    onlyBtn: {
      type: Boolean,
      default: false,
    },
    wrapperClass: {
      type: [Array, String],
      default: 'relative',
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
    globalDrag: {
      type: Boolean,
      default: false,
    },
    selectOnEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['upload'],
  setup(props, { emit }) {
    const current = computed(() => props.current)
    const preview = ref(null)
    const file = ref(null)
    const fileList = ref([])
    const errorMessage = ref('')
    const editing = ref(false)
    const multiple = computed(() => props.multiple)
    const processing = computed(() => props.processing)

    const onChange = (event = null) => {
      fileList.value = [...file.value.files]
      validateFiles()

      if (errorMessage.value) {
        return
      }

      emit('upload', fileList.value)

      if (event !== null) {
        event.target.value = null
      }
    }

    const validateFiles = () => {
      if (multiple.value === false && fileList.value.length > 1) {
        errorMessage.value = 'Too many files selected'
        return
      }

      errorMessage.value = ''
    }

    const showGlobalModal = ref(false)
    onMounted(() => {
      const body = document.body
      const getRootNode = (element) => ('getRootNode' in element ? element.getRootNode() : document)
      const elementFromPoint = (root, point) => {
        if (!('elementFromPoint' in root)) {
          root = document
        }
        return root.elementFromPoint(point.x, point.y)
      }
      const isEventTarget = (e, target) => {
        // get root
        const root = getRootNode(target)

        // get element at position
        // if root is not actual shadow DOM and does not have elementFromPoint method, use the one on document
        const elementAtPosition = elementFromPoint(root, {
          x: e.pageX - window.pageXOffset,
          y: e.pageY - window.pageYOffset,
        })

        // test if target is the element or if one of its children is
        return elementAtPosition === target || target.contains(elementAtPosition)
      }

      let listeners = []
      watch(
        () => props.globalDrag,
        (enabled) => {
          if (!enabled) {
            listeners.forEach((cb) => cb())
            listeners = []
            return
          }

          listeners.push(
            useEventListener(body, 'dragstart', (ev: DragEvent) => {
              ev.preventDefault()
            })
          )
          listeners.push(
            useEventListener(body, 'dragenter', (ev: DragEvent) => {
              ev.preventDefault()
              showGlobalModal.value = true
            })
          )
          listeners.push(
            useEventListener(body, 'dragover', (ev: DragEvent) => {
              ev.preventDefault()
              ev.dataTransfer.dropEffect = 'copy'
            })
          )
          listeners.push(
            useEventListener(body, 'dragleave', (ev: DragEvent) => {
              ev.preventDefault()

              if (!isEventTarget(ev, body) || (ev.x == 0 && ev.y == 0)) {
                showGlobalModal.value = false
              }
            })
          )
          listeners.push(
            useEventListener(body, 'drop', (event: DragEvent) => {
              event.stopPropagation()
              event.preventDefault()

              console.log('on drop', event.dataTransfer.files)

              file.value.files = event.dataTransfer.files
              onChange()
              showGlobalModal.value = false
            })
          )
        },
        { immediate: true }
      )
    })

    return {
      showGlobalModal,
      current,
      preview,
      file,
      fileList,
      errorMessage,
      multiple,
      editing,
      processing,
      onChange,
      onDragover: (event) => {
        if (event.currentTarget.classList.contains('bg-grey-100')) {
          return
        }

        event.currentTarget.classList.remove('bg-grey-50')
        event.currentTarget.classList.add('bg-grey-100')
      },
      onDragleave: (event) => {
        event.currentTarget.classList.add('bg-grey-50')
        event.currentTarget.classList.remove('bg-grey-100')
      },
      onDrop: (event) => {
        event.currentTarget.classList.add('bg-grey-50')
        event.currentTarget.classList.remove('bg-lime')

        file.value.files = event.dataTransfer.files
        onChange()
      },
      setPreview: (url) => (preview.value = url),
      removePreview: () => (preview.value = null),
      done: () => (editing.value = false),
      handlePencilClick: () => {
        if (props.selectOnEdit) {
          file.value.click()
        } else {
          editing.value = true
        }
      },
    }
  },
}
</script>
