import type { Editor } from '@tiptap/core'

import useCurrentUser from '@/hooks/useCurrentUser'
import { uuid } from './functions'

export const insertMediaFileViaWindow = (file: File, editor: Editor): boolean => {
  const nodeType = editor.schema.nodes['media-file']

  const matchingType = editor.extensionManager.extensions
    .find((e) => e.name === 'media-file')
    .options.types.find((type) => type.shouldHandle([file]))
  if (!matchingType) {
    return false
  }

  const id = 'tmp-' + uuid()
  const user = useCurrentUser()
  editor.storage['media-file'].tmp[id] = { file, user: user.value.id }

  const node = nodeType.create({
    provider: matchingType.name,
    id,
  })

  return editor
    .chain()
    .command(({ tr, dispatch, view }) => {
      const { selection } = tr
      if (dispatch) {
        tr.replaceRangeWith(selection.from, selection.to, node)
      }

      return true
    })
    .createParagraphNear()
    .run()
}
