import { BaseModel } from '@/stores/models/BaseModel'
// @ts-ignore
import ReminderDTO = Brainy.Reminder.Contracts.DataTransferObjects.ReminderDTO

export class ReminderModel extends BaseModel<ReminderDTO> implements ReminderDTO {
  declare id: string
  declare user_id: string
  declare document_id: string
  declare reason: string | null
  declare due_at: string
  declare read_at: string | null
  declare created_at: string
  declare updated_at: string
}
