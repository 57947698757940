import Fuse from 'fuse.js'
import { defineStore } from 'pinia'
import { computed, watchEffect } from 'vue'

import { makeError, makeToast } from '@/helpers'
import { makeSorter } from '@/helpers/sort'
import { useBrainyStore } from '@/hooks'
import { MemberModel } from '@/stores/models/MemberModel'
import { TopicModel } from '@/stores/models/TopicModel'
import { useSubscriptionStore } from '@/stores/subscription.store'
import { TopicType } from '@/types/enums'

export type Topic = TopicModel

export const useTopicStore = defineStore('topics', () => {
  const state = useBrainyStore<Topic>('topics', {
    model: 'TopicDTO',
    makeModel: (attrs) => new TopicModel(attrs),
  })

  const fuse = new Fuse([], {
    keys: [
      { name: 'name', weight: 1.5 },
      { name: 'description', weight: 0.5 },
    ],
    includeScore: true,
    threshold: 0.35,
  })

  // @ts-ignore
  watchEffect(() => fuse.setCollection(state.all.value))

  return {
    ...state,

    // getters
    search: computed(() => {
      return (query: string, withScore: boolean = false) => {
        if (!query) {
          return withScore
            ? state.all.value.map((t) => ({
                item: t,
                score: t.name,
              }))
            : state.all.value
        }

        const res = fuse.search(query)

        if (!withScore) {
          return res.map((r) => r.item as TopicModel)
        }

        return res.map((r) => ({
          score: r.score,
          item: r.item as TopicModel,
        }))
      }
    }),
    WIP: computed(() =>
      state.all.value.find((t) => t.name === 'WIP' && t.type === TopicType.SYSTEM)
    ),
    bySlug: computed(() => {
      return (slug: Topic['slug']) => {
        return state.filter({
          filter: (item) => item.slug === slug,
        }).value?.[0]
      }
    }),

    // actions
    async follow(topic: Topic) {
      const subscriptions = useSubscriptionStore()
      await subscriptions.subscribe(topic.id, null, {
        onError: () => makeError(`Could not follow ${topic.name}`),
        onCommit: () => makeToast(`Followed ${topic.name}`),
      })
    },
    async unfollow(topic: Topic) {
      const subscriptions = useSubscriptionStore()
      subscriptions.unsubscribe(topic.id)
    },
    // topic store
    mute(topic: Topic) {
      const subscriptions = useSubscriptionStore()
      subscriptions.mute(topic.id)
    },
    unmute(topic: Topic) {
      const subscriptions = useSubscriptionStore()
      subscriptions.unmute(topic.id)
    },
  }
})
