import * as Sentry from '@sentry/vue'
import {
  type Fn,
  tryOnScopeDispose,
  until,
  useLocalStorage,
  useSessionStorage,
  watchDebounced,
} from '@vueuse/core'
import { useIDBKeyval } from '@vueuse/integrations/useIDBKeyval'
import { collect } from 'collect.js'
import { type Ref, computed, nextTick, reactive, ref, unref, watch, watchEffect } from 'vue'

import { debounce, defaultWindow } from '@/helpers'
import {
  type BrainyStoreItems,
  type BrainyStoreOptions,
  type BrainyStoreState,
  useCurrentUser,
  useMakeStoreGetters,
  useWebsocketUpdates,
} from '@/hooks'
import { useHttpSync } from '@/hooks/sync/useHttpSync'
import useMakeStoreMutations from '@/hooks/sync/useMakeStoreMutations'
import { useBrainyIDBKeyval } from '@/hooks/useBrainyIDBKeyval'
import useIsBrainy from '@/hooks/useIsBrainy'
import Http from '@/services/Http'
import { useAuthStore } from '@/stores'
import { BaseModel } from '@/stores/models'
import type { Model } from '@/stores/plugins/sync-plugin'

const _syncId = ref(-1)
const _latestStore = ref('')
const _lastSyncCall = ref(-1)
const _loadAll = ref<Fn[]>([])
const registerSyncId = (syncId: number, loadAll: Fn, store: string = '') => {
  if (syncId <= StoreState.INITIALIZED) {
    console.log(`[registerSyncId][${store}] Init store, forcing load`)
    loadAll()
    return
  } else if (syncId === StoreState.SYNCED) {
    console.log(`[registerSyncId][${store}] Store initialized, no updates. Skipping`)
    return
  }

  if (_syncId.value < syncId) {
    _latestStore.value = store
  }
  _syncId.value = Math.max(_syncId.value, syncId)
  _loadAll.value.push(loadAll)
  console.log(`[registerSyncId][${store}]`, syncId)
}
const triggerDeltaSync = debounce(() => {
  console.log(`[triggerDeltaSync][${_latestStore.value}] current id`, {
    syncId: _syncId.value,
    lastSync: _lastSyncCall.value,
  })
  if (_syncId.value === StoreState.UNINITIALIZED) {
    console.log(`[triggerDeltaSync][${_latestStore.value}] skip`)
    return
  }

  if (_syncId.value > StoreState.INITIALIZED) {
    if (_syncId.value > _lastSyncCall.value) {
      _lastSyncCall.value = _syncId.value
      Http.get('/sync/bootstrap?lastSync=' + _syncId.value)
    } else {
      console.log(`[triggerDeltaSync][${_latestStore.value}] skipping id`, {
        syncId: _syncId.value,
        lastSync: _lastSyncCall.value,
      })
    }
  } else {
    let loader
    while ((loader = _loadAll.value.pop())) {
      loader()
    }
  }
}, 250)

const urlsToFetch = new Map()
const doBatchFetch = debounce(async () => {
  const urls = collect(Array.from(urlsToFetch.keys()))
    .chunk(Math.max(urlsToFetch.size / 6, 2))
    .filter((chunk) => chunk.count() > 0)
    .toArray<string[]>()

  const snapshot = new Map(urlsToFetch)
  urlsToFetch.clear()

  urls.forEach(async (chunk) => {
    const res = await Http.get('/batch', {
      params: {
        urls: chunk,
      },
    })

    chunk.forEach((url) => {
      snapshot.get(url)(res[url])
    })
  })
}, 200)
const batchFetch = async (url: string, cb: Fn) => {
  urlsToFetch.set(url, cb)

  doBatchFetch()
}

const StoreState = {
  UNINITIALIZED: -1,
  INITIALIZED: 0,
  SYNCED: 1,
} as const

const websocketReady = ref(false)

function useBrainyStore<T extends Model>(id: string, options: BrainyStoreOptions<T>) {
  const {
    version = 0,
    model = '',
    baseUrl = '',
    resourceName = id,
    endpoints = {},
    makeModel = (a: T) => new BaseModel(a),
    relations = [],
    resolveRelation = () => null,
    websocketConfig = {},
  } = options

  function wrapModel(data: T | null) {
    return data
      ? new Proxy(makeModel(data) as T, {
          set(target: T, p: string | symbol, value: any, receiver: any): boolean {
            mutations.update(target.id, {
              ...target.toArray(),
              [p]: value,
            })

            return true
          },
          get(target, prop, receiver) {
            const propName = prop.toString()
            const isRelationProp =
              relations?.includes(propName as any) ||
              (Reflect.has(target, `${propName}_id`) && !Reflect.has(target, prop))
            if (isRelationProp) {
              return resolveRelation(propName as any, target)
            }

            // @ts-ignore
            return Reflect.get(...arguments)
          },
        })
      : null
  }

  function replacer(key, value) {
    if (value instanceof Map) {
      return { __type: 'Map', value: Object.fromEntries(value) }
    }
    if (value instanceof Set) {
      return { __type: 'Set', value: Array.from(value) }
    }
    return value
  }

  function reviver(key, value) {
    if (value?.__type === 'Set') {
      return new Set(value.value)
    }
    if (value?.__type === 'Map') {
      return new Map(Object.entries(value.value))
    }
    return value
  }

  const state = useLocalStorage<BrainyStoreState<T>>(
    `_${id}`,
    {
      syncId: 0,
      items: new Map() as BrainyStoreItems<T>,
      version: StoreState.UNINITIALIZED,
    },
    {
      serializer: {
        read: (v: any) => (v ? JSON.parse(v, reviver) : null),
        write: (v: any) => JSON.stringify(v, replacer),
      },
      flush: 'sync',
      mergeDefaults: true,
      onError: (error) => {
        Sentry.captureException(error, {
          extra: {
            store: id,
            syncId: state.value.syncId,
          },
        })
      },
    }
  )

  if (!((state.value.items as any) instanceof Map)) {
    state.value.items = new Map()
  }

  // const items = useLocalStorage(`_${id}_items`, new Map() as BrainyStoreItems<T>, {
  //   serializer: {
  //     read: (v: any) => (v ? JSON.parse(v, reviver) : null),
  //     write: (v: any) => JSON.stringify(v, replacer),
  //   },
  //   onError: (error) => {
  //     Sentry.captureException(error, {
  //       extra: {
  //         store: id,
  //         syncId: state.value.syncId,
  //       },
  //     })
  //   },
  // })
  const user = useCurrentUser()
  const isInit = ref(false)
  // const isLoaded = ref(false)

  const { data: items, isFinished: isLoadingState } = useBrainyIDBKeyval(
    `_${id}_items`,
    null as BrainyStoreItems<T> | null,
    user.value?.current_workspace_id
  )

  // const items = ref(new Map() as BrainyStoreItems<T>)
  // watch(idbItems, (v) => {
  //   if (v && !isInit.value && isLoaded.value) {
  //     isLoaded.value = true
  //     items.value = v
  //   }
  // })
  // watch(items, (v) => {
  //   console.log('idb write', v)
  //   idbItems.value = v
  // })

  // new Promise((resolve) => {
  //   setTimeout(() => {
  //     isLoaded.value = true
  //     resolve(1)
  //   }, 500)
  // })

  // const items = ref({} as BrainyStoreItems<T>) as Ref<BrainyStoreItems<T>>
  const _storeSyncId = computed(() =>
    Math.max(
      state.value.syncId,
      isLoadingState.value && items.value
        ? collect(Array.from(items.value.values()))
            .map((i: T) => i.updated_at)
            .filter((d) => d)
            .map((x) => Date.parse(x))
            .values()
            .max()
        : -1
    )
  )

  // todo: perf implications?
  // watchDebounced(state.value, (upd) => {
  //   console.log('EFFECT', upd)
  //   if (upd.items) {
  //     items.value = upd.items
  //   }
  // })

  const models = computed<Record<T['id'], T>>(() => {
    return isLoadingState.value && items.value
      ? [...(items.value?.values() ?? [])].reduce(
          (carry, item) => Object.assign(carry, { [item.id]: wrapModel(item) }),
          {}
        )
      : {}
  })

  watch(items, (v) => {
    console.log('ITEM CHANGE', id, v)
  })

  // todo: track create/update/deletes seperately?
  const pending = {} as BrainyStoreItems<T>

  const mutations = useMakeStoreMutations(
    items,
    useHttpSync({
      baseUrl,
      resourceName,
      endpoints,
    })
  )

  if (model !== '') {
    useWebsocketUpdates(items, {
      model,
      customEvents: websocketConfig?.customEvents ?? [],
      onCustom: websocketConfig?.onCustom,
      // todo: stage updates via Pending
      onCreate: (id, data, syncId) => {
        // setTimeout(() => {
        //   nextTick(() => {
        mutations.upsert(id, data)
        websocketConfig?.onCreate?.(id, data, syncId)
        // })
        // }, 25)
        // state.value.syncId = syncId
      },
      onUpdate: (id, data, syncId) => {
        // todo: this seems to fuck up bootstrapped updates, but was put in place to fix another issue
        // if (!items.value[id]) {
        //   return
        // }

        mutations.upsert(id, data)
        // state.value.syncId = syncId
        websocketConfig?.onUpdate?.(id, data, syncId)
      },
      onDelete: (id, syncId) => {
        mutations.remove(id)
        // state.value.syncId = syncId
        websocketConfig?.onDelete?.(id, syncId)
      },
      onReady: () => {
        websocketReady.value = true
      },
    })
  }

  const flushPending = (ids: T['id'][] = []) => {
    return Object.keys(pending)
      .map((id: T['id']) => {
        if (ids.length === 0 || ids.includes(id)) {
          const updated = mutations.upsert(id, pending[id])
          delete pending[id]

          return updated
        }
      })
      .filter((x) => typeof x !== 'undefined') as T[]
  }

  tryOnScopeDispose(flushPending)

  const loading = ref<boolean>(false)
  const triedModels = useSessionStorage<T['id'][]>(`retried_${id}`, [])
  const missingModels = reactive<T['id'][]>([])
  watchDebounced(
    missingModels,
    async (m: T['id'][]) => {
      if (m.length == 0) {
        return
      }

      const missing = m.filter((s) => s && !triedModels.value.includes(s)).join(',')

      if (missing.trim() == '' || missing.trim() == ',') {
        return
      }

      console.warn('Unable to find model(s): ', missing)
      missingModels.splice(0, m.length)
      triedModels.value.push(...missing.split(/,/).filter((d) => d))
      $fetch({
        id: missing,
      })
    },
    { debounce: 25, maxWait: 200 }
  )
  const onMissing = (id: T['id']) => {
    missingModels.push(id)
  }

  const getters = useMakeStoreGetters(models, pending, flushPending, onMissing)

  const inFlight: Record<string, Promise<any>> = {}
  const $fetch = (
    condition: Record<string, string> = {},
    onResult: (data: T[]) => void = (data) => {
      data.forEach((model) => mutations.upsert(model.id, model))
    }
  ) => {
    const base = baseUrl ?? `/`
    const params = new URLSearchParams(condition)
    const url = `${base}/${resourceName}?` + params.toString()

    if (!inFlight[url]) {
      if (typeof defaultWindow?.batch_fetch === 'undefined') {
        defaultWindow.batch_fetch = useIsBrainy()
      }

      inFlight[url] = (
        defaultWindow?.batch_fetch
          ? new Promise((resolve, reject) => {
              batchFetch(url, (data) => {
                resolve(data)
              })
            })
          : Http.get(url)
      )
        .then((res) => {
          onResult(res.data)

          return res.data
        })
        .finally(() => {
          delete inFlight[url]
        })
    }

    return inFlight[url]
  }

  const loadAll = async (resetState: boolean = true) => {
    if (resetState) {
      items.value = new Map() as BrainyStoreItems<T>
      state.value.syncId = StoreState.SYNCED
    }

    loading.value = true
    await $fetch({}, (data) => mutations.init(data))
    loading.value = false
  }

  until(isLoadingState)
    .toBeTruthy()
    .then(() => {
      if (items.value === null) {
        loadAll()
      }
    })

  const bootstrap = () =>
    registerSyncId(
      _storeSyncId.value,
      async () => {
        if (!items.value || Object.keys(items.value).length == 0) {
          await loadAll()
        }
      },
      id
    )

  const authStore = useAuthStore()
  const isLoggedIn = ref(false)
  watchEffect(() => {
    isLoggedIn.value = authStore.isLoggedIn
  })

  watch(
    [isLoggedIn, websocketReady, isLoadingState],
    (v) => {
      if (!v[0]) {
        // logged out
        _syncId.value = StoreState.INITIALIZED
        _loadAll.value = []
        console.log('State change, logged out')
      }

      if (v[0] && v[2]) {
        // console.log('State change', {
        //   id,
        //   isLoggedIn: v[0],
        //   websocketReady: v[1],
        //   _storeSyncId: _storeSyncId.value,
        //   syncId: state.value.syncId,
        // })
        bootstrap()
        if (websocketReady.value) {
          triggerDeltaSync()
        }
      }
    },
    {
      immediate: true,
    }
  )

  if (
    isLoggedIn.value &&
    (typeof state.value?.version === 'undefined' || state.value?.version < version)
  ) {
    console.warn('Version mismatch, updating', id)
    loadAll(false)
    state.value.version = version
  }

  return {
    items: items,
    models,
    pending,
    loadAll,
    $isLoadingState: isLoadingState,
    $loading: loading.value,
    $isReady: computed(() => isLoadingState.value || !loading.value),
    $fetch,
    syncId: computed(() => state.value.syncId),
    _syncId: _storeSyncId,
    ...mutations,
    ...getters,
  }
}

export default useBrainyStore
