import type { HttpSyncOptions } from '@/hooks'
import Http from '@/services/Http'
import type { SyncConfig } from '@/stores/plugins/sync-plugin'

export const useHttpSync = (options: Partial<HttpSyncOptions> = {}) => {
  const { baseUrl = '', resourceName = '', endpoints = {} } = options

  return <R>(
    name: 'create' | 'update' | 'delete' | keyof HttpSyncOptions['endpoints'],
    payload: any
  ) => {
    let objId = payload?.['id'] || null
    if (!objId && typeof payload === 'string') {
      objId = payload
    }

    // todo: patch method?
    const defaults: Record<string, SyncConfig> = {
      create: { endpoint: '/', method: 'post' },
      update: { endpoint: `/${objId}`, method: 'put' },
      delete: { endpoint: `/${objId}`, method: 'delete' },
    }

    const syncOpts = endpoints?.[name] ?? defaults?.[name]

    if (!syncOpts) {
      return
    }

    const path = (
      typeof syncOpts.endpoint == 'function' ? syncOpts.endpoint : () => syncOpts.endpoint
    )(objId)
    const method = syncOpts.method as NonNullable<SyncConfig['method']>

    if (method && path) {
      return Http.send<R>(method, `${baseUrl}/${resourceName}${path}`, payload)
    }
  }
}

export default useHttpSync
