<template>
  <div
    class="not-prose flex items-center sm:w-full sm:py-10 bg-egg-white dark:bg-black-800 rounded preview:py-0"
  >
    <div
      class="relative bg-white dark:bg-black-700 dark:border-black-600 dark:divide-black-600 border border-grey-50 rounded-lg p-4 sm:p-6 sm:w-full sm:w-[540px] max-w-[540px] mx-auto dark:text-black-100 preview:sm:w-full"
      :style="styleVar"
    >
      <div v-if="loading" class="z-10 absolute inset-0 flex items-center justify-center">
        <BdsSpinner lg />
      </div>

      <div class="z-10 flex absolute top-4 right-5">
        <a :href="original" target="_blank">
          <BdsIcon name="LinkedIn" class="w-3.5 h-3.5 text-[#0072b1]" />
        </a>
      </div>

      <iframe
        class="h-full w-full top-0 z-0 rounded-lg"
        :class="{
          'absolute right-0': !loading,
          'fixed right-[300rem]': loading,
        }"
        :src="url"
        frameborder="0"
        allowfullscreen
        @load="onLoad"
      ></iframe>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BdsIcon, BdsSpinner } from '@getbrainy/bds-components'
import { computed, ref, watch } from 'vue'

const props = defineProps({
  url: { type: String, required: true },
  original: { type: String, default: '' },
  loading: { type: Boolean, default: false },
})

const emit = defineEmits(['load'])

const loading = ref(true)

const styleVar = computed(() => {
  return 'padding-bottom: 65%'
})

watch(
  () => props.loading,
  (v) => (loading.value = v)
)

function onLoad() {
  loading.value = false
  emit('load')
}
</script>
