<template>
  <BdsDropdown :fixed="fixed">
    <template #trigger>
      <slot></slot>
    </template>

    <BdsDropdownItem @click.prevent.stop="copyLink">
      <BdsIcon class="w-3.5 h-3.5" name="Link" />
      Copy link
    </BdsDropdownItem>
    <BdsDropdownItem v-if="workspace?.isTeam" @click.prevent.stop="showFollowers">
      <BdsIcon class="w-3.5 h-3.5" name="UserSearch" />
      View all followers
    </BdsDropdownItem>
    <BdsDropdownItem @click.prevent.stop="editTopic">
      <BdsIcon class="w-3.5 h-3.5" name="Pen" />
      Edit
    </BdsDropdownItem>

    <BdsDropdownDivider />

    <template v-if="subscription">
      <template v-if="showMuteActions">
        <BdsDropdownItem v-if="subscription.is_muted" @click="topicStore.unmute(topic)">
          <BdsIcon class="w-3.5 h-3.5" name="Mute" />
          Unmute
        </BdsDropdownItem>
        <BdsDropdownItem v-else @click="topicStore.mute(topic)">
          <BdsIcon class="w-3.5 h-3.5" name="Mute" />
          Mute
        </BdsDropdownItem>
      </template>

      <BdsDropdownItem @click="topicStore.unfollow(topic)">
        <BdsIcon class="w-3.5 h-3.5" name="ArrowLeft" />
        Unfollow
      </BdsDropdownItem>
    </template>
    <template v-else>
      <BdsDropdownItem @click="topicStore.follow(topic)">
        <BdsIcon class="w-3.5 h-3.5" name="ArrowRight" />
        Follow
      </BdsDropdownItem>
    </template>

    <BdsDropdownItem v-if="false">
      <BdsIcon class="w-3.5 h-3.5" name="Trash" />
      Delete
    </BdsDropdownItem>
  </BdsDropdown>
</template>

<script setup lang="ts">
import {
  BdsDropdown,
  BdsDropdownDivider,
  BdsDropdownItem,
  BdsIcon,
} from '@getbrainy/bds-components'
import { computed } from 'vue'

import { useCurrentUser, useCurrentWorkspace, useEditTopic } from '@/hooks'
import { useCopyTopicLink, useShowTopicFollowers } from '@/hooks/useEditTopic'
import { Subscription, useSubscriptionStore, useTopicStore } from '@/stores'

const props = defineProps({
  topicId: { type: String, required: true },
  fixed: { type: Boolean, default: false },
  showMuteActions: { type: Boolean, default: false },
  redirect: { type: Boolean, default: false },
})

const topicStore = useTopicStore()
const topic = computed(() => topicStore.byId(props.topicId))

const subscriptions = useSubscriptionStore()
const subscription = computed(() => {
  return subscriptions.byEntityId(
    topic.value!.id,
    useCurrentUser().value?.id as string
  ) as Subscription
})

function copyLink(): void {
  useCopyTopicLink(topic.value)
}

function editTopic(): void {
  useEditTopic(topic.value, { noRedirect: !props.redirect })
}

function showFollowers(): void {
  useShowTopicFollowers(topic.value)
}

const workspace = useCurrentWorkspace()
</script>
