import { defineStore } from 'pinia'
import { computed } from 'vue'

import { makeNotification, makeToast } from '@/helpers'
import { useBrainyStore, useUserAvatar } from '@/hooks'
import Http from '@/services/Http'
import { NotificationModel } from '@/stores/models/NotificationModel'
// @ts-ignore
import NotificationDTO = Brainy.Notification.Contracts.DataTransferObjects.NotificationDTO

import { getNotificationDescription, taskNotificationTypes } from '@/hooks/useTodayTasks'
import { useAuthStore } from '@/stores/auth.store'
import { useDocumentStore } from '@/stores/document.store'
import { useMemberStore } from '@/stores/member.store'

export type Notification = NotificationDTO & {}

export const useNotificationStore = defineStore('notifications', () => {
  const state = useBrainyStore<NotificationModel>('notifications', {
    model: 'NotificationDTO',
    makeModel: (attrs) => new NotificationModel(attrs),

    relations: ['actor'],

    resolveRelation: (name, target) => {
      if (name == 'actor') {
        const members = useMemberStore()
        return members.userById(target.data.actor_id)
      }
    },

    websocketConfig: {
      onCreate: (id, data): void => {
        const notification = new NotificationModel(data)

        if (taskNotificationTypes.includes(notification.data.type)) {
          return
        }

        const avatar = useUserAvatar(notification.data?.actor_id)
        const description = getNotificationDescription(notification, avatar.value.user)
        const documentStore = useDocumentStore()
        const doc = documentStore.byId(notification.data.doc_id ?? notification.data.entity_id)
        makeNotification(description, avatar.value, doc?.title ?? 'Untitled doc')
      },
    },
  })

  const auth = useAuthStore()

  if (auth.isLoggedIn) {
    state.$fetch()
  }

  const read = computed(() => state.all.value.filter((x) => x.read_at !== null))
  const unread = computed(() => state.all.value.reverse().filter((x) => !x.read_at))

  const upsert = (
    id: Notification['id'] | (Pick<Notification, 'id'> & Partial<Notification>),
    notification: null | Partial<Notification> = null,
    fromBoot: boolean = false
  ) => {
    const notificationId = typeof id === 'string' ? id : id.id
    let newlyCreated = true

    if (state.byId(notificationId)) {
      newlyCreated = false
    }

    // @ts-ignore
    state.upsert(id, notification)

    if (newlyCreated && !fromBoot) {
      makeToast('Added notification')
    }
  }
  return {
    ...state,
    read,
    unread,

    upsert,
    async markRead(notification: NotificationModel) {
      state.update(notification.id, { read_at: new Date().toISOString() })
    },
    async markUnread(notification: NotificationModel) {
      state.update(notification.id, { read_at: null })
    },
    async markAllRead() {
      unread.value.forEach((n) => upsert(n.id, { read_at: new Date().toDateString() }))
      await Http.post(`notifications`)
    },
    async deleteAllRead() {
      read.value.forEach((n) => state.remove(n.id))
      await Http.delete(`notifications`, { read: true })
    },
    async deleteAll() {
      state.all.value.forEach((n) => state.remove(n.id))
      await Http.delete(`notifications`)
    },
  }
})
