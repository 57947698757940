import endOfDay from 'date-fns/endOfDay'
import type { ComputedRef } from 'vue'
import { computed } from 'vue'

import { makeSorter } from '@/helpers/sort'
import { useCurrentUser } from '@/hooks/index'
import { type User, useDocumentStore, useNotificationStore, useReminderStore } from '@/stores'
import type { DocumentModel } from '@/stores/models/DocumentModel'
import type { NotificationModel } from '@/stores/models/NotificationModel'
import { DocumentType } from '@/types/enums'

export type TodayTask = {
  id: NotificationModel['id']
  notification: NotificationModel
  document: DocumentModel

  type:
    | 'RemoveWIP'
    | 'NoTitle'
    | 'EmptyDocument'
    | 'Poked'
    | 'Remind'
    | 'InvitedToEdit'
    | 'UserClapped'

  title: string
  subtitle: string
  read_at: string
  timestamp: string
}

const _endOfDay = endOfDay(new Date())

const taskTitles: Record<string, string> = {
  NoTitle: 'Untitled doc',
  EmptyDocument: 'Empty doc',
}

const taskSubtitle: Record<string, string | ((t: TodayTask, actor?: User) => string)> = {
  RemoveWIP: (task: TodayTask, actor?: User) => {
    if (task.document.type !== DocumentType.ARTICLE) {
      return 'You found a gem. Review it or remove WIP.'
    }

    return 'Your doc is marked as WIP'
  },
  NoTitle: 'Your doc as no title',
  EmptyDocument: 'Your doc is completely empty',
  InvitedToEdit: (task: TodayTask, actor?: User) => {
    const _actor = actor || task.notification.actor

    return `${_actor?.name} wants to collaborate`
  },
  UserClapped: (task: TodayTask, actor?: User) => {
    const _actor = actor || task.notification.actor

    return `${_actor?.name} clapped ${task.notification.data?.claps} times`
  },
  Poked: (task: TodayTask, actor?: User) => {
    const _actor = actor || task.notification.actor

    return `${_actor?.name} wants you to see this`
  },
  NewComment: (task: TodayTask, actor?: User) => {
    const _actor = actor || task.notification.actor

    return `<b>${_actor?.name}</b>: ${task.notification.data.text}`
  },
}

export const taskNotificationTypes = ['RemoveWIP', 'NoTitle', 'EmptyDocument']

export function getNotificationDescription(notification: NotificationModel, actor?: User) {
  const task = {
    id: notification.id,
    type: notification.data.type,
    notification,
    read_at: notification.read_at,
    timestamp: notification.created_at,
  } as TodayTask

  const subtitle =
    typeof taskSubtitle[task.type] === 'function'
      ? taskSubtitle[task.type]
      : () => taskSubtitle[task.type]

  // @ts-ignore
  return subtitle(task, actor)
}

function useTodayTasks(): ComputedRef<TodayTask[]> {
  const documentStore = useDocumentStore()
  const currentUser = useCurrentUser()
  const notifications = useNotificationStore()

  return computed(() => {
    if (!currentUser.value) {
      return []
    }

    const userId = currentUser.value.id

    const allNotifications = computed(() => {
      return notifications.all
        .filter((n) => n.notifiable_id === userId)
        .map((n) => {
          const docId = n.data.doc_id ?? n.data.entity_id
          const doc = documentStore.byId(docId)

          return {
            id: n.id,
            type: n.data.type,
            notification: n,
            document: doc,
            read_at: n.read_at,
            timestamp: n.created_at,
          } as TodayTask
        })
        .filter((n) => n && n.document)
        .map((task) => {
          task.title = taskTitles[task.type] ?? task.document?.title ?? taskTitles.NoTitle

          const subtitle =
            typeof taskSubtitle[task.type] === 'function'
              ? taskSubtitle[task.type]
              : () => taskSubtitle[task.type]

          // @ts-ignore
          task.subtitle = subtitle(task)

          return task
        })
    })

    const reminderStore = useReminderStore()
    const reminders = computed(() => {
      return reminderStore.allMine
        .filter((r) => Date.parse(r.due_at) < _endOfDay.getTime())
        .map((reminder) => {
          const doc = documentStore.byId(reminder.document_id)

          return {
            id: reminder.id,
            type: 'Remind',
            document: doc,
            title: doc?.title || taskTitles.NoTitle,
            subtitle: 'You asked to be reminded today',
            read_at: reminder.read_at,
            timestamp: reminder.due_at,
          } as TodayTask
        })
        .filter((r) => r.document)
    })

    return [...allNotifications.value, ...reminders.value].sort(makeSorter('-timestamp'))
  })
}

export default useTodayTasks
