import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import Http from '@/services/Http'

export const useGithubStore = defineStore(
  'github',
  () => {
    const items = ref([])
    const loading = ref(false)
    const hasError = ref(false)

    const count = computed(() => items.value.length)
    const hasItems = computed(() => count.value > 0)

    function remove(id: string) {
      const idx = items.value.findIndex((x) => x.id === id)
      if (idx !== -1) {
        items.value.splice(idx, 1)
      }
    }

    async function loadAll(onlyLatest = false) {
      if (loading.value) return
      try {
        loading.value = true
        const { data } = await Http.get(
          `github/stars` + (onlyLatest && hasItems.value ? `?since_id=${items.value[0].id}` : ``)
        )
        items.value = onlyLatest && hasItems.value ? [...data, ...items.value] : data || []
      } catch (e) {
        hasError.value = true
      } finally {
        loading.value = false
      }
    }

    async function reject(bookmarkId: string): Promise<void> {
      await Http.delete(`github/stars/${bookmarkId}`)
    }

    return { count, items, loading, hasError, hasItems, loadAll, reject, remove }
  },
  {
    persist: {
      paths: ['items'],
    },
  }
)
