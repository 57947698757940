import { type ComputedRef, computed } from 'vue'

type OperatingSystem = 'Unknown' | 'Windows' | 'MacOS' | 'Linux' | 'Unix'

interface UseOSReturn {
  state: ComputedRef<OperatingSystem>
  isWindows: ComputedRef<boolean>
  isMacOS: ComputedRef<boolean>
  isLinux: ComputedRef<boolean>
}

function useOS(): UseOSReturn {
  const state = computed(() => {
    const userAgent = navigator.userAgent

    if (userAgent.indexOf('Win') !== -1) return 'Windows'
    if (userAgent.indexOf('Mac') !== -1) return 'MacOS'
    if (userAgent.indexOf('X11') !== -1) return 'Unix'
    if (userAgent.indexOf('Linux') !== -1) return 'Linux'

    return 'Unknown'
  })

  return {
    state,
    isWindows: computed(() => state.value === 'Windows'),
    isMacOS: computed(() => state.value === 'MacOS'),
    isLinux: computed(() => state.value === 'Linux'),
  }
}

export default useOS
