<template>
  <component :is="layoutComponent" :class="{ 'flex flex-1': layoutComponent === 'div' }">
    <RouterView />
  </component>

  <DialogsWrapper />
</template>

<script setup lang="ts">
import { computed, markRaw, provide, ref, watch, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { DialogsWrapper } from 'vuejs-confirm-dialog'

import { useCurrentUser, useCurrentWorkspace } from '@/hooks'
import Layout from '@/Layout.vue'
import { useAppStore, useAuthStore } from '@/stores'

const app = useAppStore()
const auth = useAuthStore()

if (auth.isLoggedIn) {
  await Promise.all([
    auth.loadWorkspaces(),
    app.bootstrap(),
    new Promise((r) => setTimeout(r, 500)),
  ])
  console.log('Main booted')
  performance.mark('main-booted')
}

const shouldBootstrap = computed(() => auth.isLoggedIn && auth.hasWorkspace && !app.isBooted)
watch(shouldBootstrap, async (v) => {
  if (v) {
    await app.bootstrap()
  }
})

const route = useRoute()
const layoutComponent = ref('div')
watchEffect(() => {
  if (typeof route.name === 'undefined' || route.meta?.register || route.meta?.public) {
    layoutComponent.value = 'div'
  } else {
    layoutComponent.value = markRaw(Layout)
  }
})

const user = useCurrentUser()
const workspace = useCurrentWorkspace()
provide('user', user)
provide('workspace', workspace)
</script>
