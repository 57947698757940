import type { Router, RouteRecordRaw } from 'vue-router'

const editorRoutes: RouteRecordRaw = {
  path: '/doc/:id',
  component: () => import('./EditorModule.vue'),
  children: [
    {
      path: '',
      name: 'document.read',
      component: () => import('./views/ReadDocument.vue'),
      props: { keyboard: true },
    },
    {
      path: 'edit',
      name: 'document.edit',
      component: () => import('./views/EditDocument.vue'),
    },
  ],
}

export default (router: Router) => {
  router.addRoute(editorRoutes)
}
