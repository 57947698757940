import { type ComputedRef, computed } from 'vue'

import { useThemeControl } from '@/hooks/index'
import useActiveUsers from '@/hooks/useActiveUsers'
import { type User, useMemberStore } from '@/stores'
import { useAssetStore } from '@/stores/asset.store'

interface UseUserAvatarReturn {
  user?: User
  initials?: string
  src?: string
  active?: boolean
}

function useUserAvatar(userId: string): ComputedRef<UseUserAvatarReturn> {
  const { isDark } = useThemeControl()
  if (userId === 'brainy') {
    return computed(() => ({
      src: isDark.value ? '/favicon_white.png' : '/favicon_black.png',
      initials: 'BR',
      active: true,
    }))
  }
  if (userId.startsWith('isusr_')) {
    return computed(() => ({
      src: '/slack.png',
      initials: 'SU',
      active: false,
    }))
  }

  const memberStore = useMemberStore()
  const user = computed(() => memberStore.userById(userId))

  const assets = useAssetStore()
  const avatar = computed(() =>
    user.value?.avatar_asset_id ? assets.byId(<string>user.value.avatar_asset_id) : null
  )

  const { users: activeUsers } = useActiveUsers()
  const isOnline = computed(() => user.value && Object.keys(activeUsers).includes(user.value.id))

  return computed(() => {
    return {
      user: user.value as User,
      initials: user.value?.initials,
      src: avatar.value?.src || user.value?.avatar_url,
      active: isOnline.value,
    }
  })
}

export default useUserAvatar
