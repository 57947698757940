<template>
  <BdsModal
    show
    title="Invite people to join workspace"
    @close="$emit('cancel')"
    @keydown.enter.meta.prevent="onSubmit"
  >
    <BdsInput
      v-for="(invite, idx) in invites"
      :key="idx"
      v-model="invite.value.email"
      placeholder="name@work-email.com"
    />
    <div class="pt-3">
      <a
        href="#"
        class="text-f13 text-grey-800 dark:text-black-300 hover:text-grey dark:hover:text-black-100"
        @click.prevent="onAddInvite"
      >
        + Add another
      </a>
    </div>

    <template #footer>
      <div></div>

      <BdsStack items-center gap-sm>
        <BdsButton sm @click="$emit('cancel')">Cancel</BdsButton>
        <BdsButton sm primary :loading="sendingInvites" @click="onSubmit">Send invites</BdsButton>
      </BdsStack>
    </template>
  </BdsModal>
</template>

<script setup lang="ts">
import { BdsButton, BdsInput, BdsModal, BdsStack } from '@getbrainy/bds-components'
import { useFieldArray, useForm } from 'vee-validate'

import { makeToast } from '@/helpers'
import Http from '@/services/Http'

const emit = defineEmits(['cancel', 'confirm'])

const { handleSubmit, isSubmitting: sendingInvites } = useForm({
  initialValues: {
    invites: [{ email: '' }],
  },
})

const { push, fields: invites } = useFieldArray('invites')

const onSubmit = handleSubmit(async (form, { resetForm }) => {
  const reqs = form.invites.map(({ email }) => {
    return Http.post(`invites`, { email })
  })

  try {
    await Promise.all(reqs)

    resetForm()

    emit('confirm')
    makeToast('Invites are on the way')
  } catch (e) {
    makeToast('Something went wrong', null, true)

    throw e
  }
})

const onAddInvite = () => {
  push({ email: '' })
}
</script>
