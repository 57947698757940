import { useCurrentWorkspace } from '@/hooks'

export * from './app.store'
export * from './asset.store'
export * from './auth.store'
export * from './author.store'
export * from './clap.store'
export * from './comment.store'
export * from './document.store'
export * from './documentMetadata.store'
export * from './documentTopic.store'
export * from './editor.store'
export * from './github.store'
export * from './invite.store'
export * from './member.store'
export * from './notification.store'
export * from './poke.store'
export * from './preferences.store'
export * from './reminder.store'
export * from './searchHistory.store'
export * from './subscription.store'
export * from './topic.store'
export * from './twitterBookmark.store'
export * from './workspace.store'

import { useAssetStore } from './asset.store'
import { useAuthorStore } from './author.store'
import { useClapStore } from './clap.store'
import { useCommentStore } from './comment.store'
import { useDocumentStore } from './document.store'
import { useDocumentMetadataStore } from './documentMetadata.store'
import { useDocumentTopicStore } from './documentTopic.store'
import { useEditorStore } from './editor.store'
import { useInviteStore } from './invite.store'
import { useMemberStore } from './member.store'
import { useNotificationStore } from './notification.store'
import { usePokeStore } from './poke.store'
import { useReaderStore } from './reader.store'
import { useReminderStore } from './reminder.store'
import { useSubscriptionStore } from './subscription.store'
import { useTopicStore } from './topic.store'
import { useTweetsStore } from './tweet.store'
import { useWorkspaceStore } from './workspace.store'

export const useBrainyStores = () => ({
  assets: useAssetStore(),
  authors: useAuthorStore(),
  claps: useClapStore(),
  documents: useDocumentStore(),
  documentMetadata: useDocumentMetadataStore(),
  documentTopics: useDocumentTopicStore(),
  editors: useEditorStore(),
  invites: useCurrentWorkspace()?.value?.isCurrentUserAdmin ? useInviteStore() : null,
  members: useMemberStore(),
  notifications: useNotificationStore(),
  pokes: usePokeStore(),
  readers: useReaderStore(),
  reminders: useReminderStore(),
  subscriptions: useSubscriptionStore(),
  topics: useTopicStore(),
  tweets: useTweetsStore(),
  workspaces: useWorkspaceStore(),
  comments: useCommentStore(),
})
