<template>
  <BdsSelectSearch
    v-model="model"
    :items="topicStore.search"
    placeholder="Search for collections"
    multiple
    allow-create
    clear-on-select
    clearable
    create-text="Add new collection:"
    :on-create="() => (showEmojiPicker = true)"
    class="not-prose"
    @add="$emit('add', $event)"
    @remove="$emit('remove', $event)"
  >
    <template #trigger="{ open }">
      <slot name="trigger" v-bind="{ open }">
        <BdsButton transparent xs @click="open">
          <BdsText size="f13" class="text-grey-400">+ Add to collections</BdsText>
        </BdsButton>
      </slot>
    </template>

    <template #item="{ item }">
      <BdsStack v-memo="[item.color, item.name]" items-center gap-sm>
        <div v-if="item.emoji" class="w-3 h-3 flex items-center justify-center text-xs">
          {{ item.emoji }}
        </div>
        <div v-else class="w-3 h-3 flex items-center justify-center">
          <div class="w-1.5 h-1.5 rounded-full" :class="getBackgroundColor(item)"></div>
        </div>
        <span>{{ item.name }}</span>
      </BdsStack>
    </template>

    <template #create="{ createText, query, close, clear }">
      <BdsEmojiPicker
        v-model="emoji"
        fixed
        top
        @update:model-value="onSelected(query, close, clear)"
      >
        <template #trigger="{ toggle }">
          <div class="flex items-center space-x-1" @click.stop="toggle">
            <span>+ {{ createText }}</span>
            <span class="font-medium">{{ query }}</span>
          </div>
        </template>
      </BdsEmojiPicker>
    </template>
  </BdsSelectSearch>
</template>

<script setup lang="ts">
import { BdsButton, BdsSelectSearch, BdsStack, BdsText } from '@getbrainy/bds-components'
import { collect } from 'collect.js'
import { nextTick, onMounted, ref, watchEffect } from 'vue'

import BdsEmojiPicker from '@/components/BdsEmojiPicker.vue'
import { useSubscriptionStore, useTopicStore } from '@/stores'

const props = defineProps({
  modelValue: { type: Array, default: () => [] },
})

const model = ref([])

watchEffect(() => {
  model.value = props.modelValue
})

const emit = defineEmits(['created', 'add', 'remove'])

const topicStore = useTopicStore()
const subscriptionStore = useSubscriptionStore()

const colorMap = {
  purple: 'bg-neon-purple',
  pink: 'bg-neon-pink',
  blue: 'bg-neon-blue',
  skyblue: 'bg-neon-skyblue',
  'ice-blue': 'bg-ice-blue',
  green: 'bg-neon-green',
  lime: 'bg-neon-lime',
  yellow: 'bg-neon-yellow',
  orange: 'bg-neon-orange',
  red: 'bg-neon-red',
}

const color = ref('')
const emoji = ref('😀')

function resetColor() {
  color.value = collect(colorMap).keys().random() as string
}

onMounted(() => resetColor())

async function onSelected(name: string, close: any, clear: any) {
  await nextTick(() => {
    clear()
    close()
  })

  const data = await topicStore.create({
    name,
    color: color.value,
    emoji: emoji.value,
  })

  await subscriptionStore.subscribe(data.id, null, { onCommit: () => {} })

  resetColor()

  emit('created', data)
}

function getBackgroundColor(item: { color: string }) {
  return colorMap[item.color]
}
</script>
