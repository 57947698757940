import { tryOnMounted } from '@vueuse/core'
import type { PusherPrivateChannel } from 'laravel-echo/dist/channel'
import { type Ref, ref, watchEffect } from 'vue'

import { useAuthStore } from '@/stores'
import type { Model, ModelChangeEvent } from '@/stores/plugins/sync-plugin'
import { ChangeSet } from '@/stores/plugins/sync-plugin'

import useEcho from '../useEcho'
import type { BrainyStoreItems, WebsocketUpdatesOptions } from './index'

let workspaceChannel: PusherPrivateChannel
let personalChannel: PusherPrivateChannel

const workspaceQueue: Function[] = []
const personalQueue: Function[] = []

const workspaceReady = ref(false)
const personalReady = ref(false)
const haveTriggeredReady = ref(false)

let hasBeenRegistred = false
const registerPlugin = () => {
  if (hasBeenRegistred) {
    return
  }
  hasBeenRegistred = true

  tryOnMounted(async () => {
    const echo = await useEcho()

    const store = useAuthStore()
    watchEffect(() => {
      const userId = store.currentUser?.id
      if (!userId) {
        return
      }

      if (!personalChannel || !personalChannel.name.endsWith(userId)) {
        personalChannel = echo.private('user.' + userId) as PusherPrivateChannel
        personalChannel.subscribed(() => (personalReady.value = true))
      }

      let handler
      while ((handler = personalQueue.pop())) {
        personalChannel.listen('.EntityChanged', handler)
      }
    })

    watchEffect(() => {
      const workspaceId = store.currentWorkspace
      if (!workspaceId) {
        return
      }

      if (!workspaceChannel || !workspaceChannel.name.endsWith(workspaceId)) {
        workspaceChannel = echo.private('workspace.' + workspaceId) as PusherPrivateChannel
        workspaceChannel.subscribed(() => (workspaceReady.value = true))
      }

      let handler
      while ((handler = workspaceQueue.pop())) {
        workspaceChannel.listen('.EntityChanged', handler)
      }
    })
  })
}

const queueWorkspaceChannel = (handler: Function, customEvents: string[] = []) => {
  if (workspaceChannel) {
    workspaceChannel.listen('.EntityChanged', handler)
    customEvents.forEach((e) => workspaceChannel.listen(e, handler))
  } else {
    workspaceQueue.push(handler)
  }
}

const queuePersonalChannel = (handler: Function, customEvents: string[] = []) => {
  if (personalChannel) {
    personalChannel.listen('.EntityChanged', handler)
    customEvents.forEach((e) => personalChannel.listen(e, handler))
  } else {
    personalQueue.push(handler)
  }
}

export const useWebsocketUpdates = async <T extends Model>(
  items: Ref<BrainyStoreItems<T>>,
  options: WebsocketUpdatesOptions<T>
) => {
  const {
    model,
    onCreate = () => {},
    onUpdate = () => {},
    onDelete = () => {},
    onCustom = () => {},
    customEvents = [],
  } = options

  registerPlugin()

  const updateHandler = (ev: ModelChangeEvent<T>) => {
    if (typeof ev.syncId === 'undefined') {
      return onCustom(ev)
    }

    if (ev.type !== model) {
      return
    }

    if (![ChangeSet.UPDATE, ChangeSet.CREATE, ChangeSet.DELETE].includes(ev.cmd)) {
      return
    }

    if (ev.cmd === ChangeSet.DELETE) {
      onDelete(ev.id, ev.syncId)
    } else if (ev.cmd === ChangeSet.CREATE) {
      onCreate(ev.id, ev.model, ev.syncId)
    } else if (ev.cmd === ChangeSet.UPDATE) {
      onUpdate(ev.id, ev.model, ev.syncId)
    }
  }

  queueWorkspaceChannel(updateHandler, customEvents)
  queuePersonalChannel(updateHandler, customEvents)

  watchEffect(() => {
    if (personalReady.value && workspaceReady.value) {
      if (!haveTriggeredReady.value) {
        haveTriggeredReady.value = true
        options.onReady?.()
      }
    }
  })
}

export default useWebsocketUpdates
