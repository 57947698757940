import { MediaFile } from '@getbrainy/extension-media-file'
import type { MediaFileType } from '@getbrainy/extension-media-file/src/media-file'
import { Plugin } from 'prosemirror-state'

import { insertMediaFileViaWindow } from '@/helpers/editor-helpers'

import useMediaFileUpload from '../../hooks/useMediaFileUpload'
import MediaFileComponent from './Component.vue'

export const file: MediaFileType = {
  name: 'file',
  shouldHandle: () => true,
  uploadHandler: (onUpload, onError) => useMediaFileUpload(onUpload, onError),
}
export const image: MediaFileType = {
  name: 'image',
  shouldHandle: (files) =>
    files.every((f) => ['image/jpeg', 'image/png', 'image/gif', 'image/webp'].includes(f.type)),
  uploadHandler: (onUpload, onError) => useMediaFileUpload(onUpload, onError),
}

export const Extension = MediaFile.extend({
  addStorage() {
    return {
      tmp: {},
    }
  },
  addProseMirrorPlugins() {
    const editor = this.editor
    return [
      new Plugin({
        props: {
          handleDOMEvents: {
            paste(view, event: ClipboardEvent) {
              const items: DataTransferItemList = // @ts-ignore
                (event.clipboardData || event.originalEvent.clipboardData)
                  .items as DataTransferItemList

              new Array(...items).forEach(async (item: DataTransferItem) => {
                const file = item.getAsFile()
                // Return here, otherwise copying texts won't possible anymore
                if (!file) {
                  return
                }

                if (insertMediaFileViaWindow(file, editor)) {
                  event.preventDefault()
                }
              })

              return true
            },
          },
        },
      }),
    ]
  },
}).configure({
  types: [image, file],
  component: MediaFileComponent,
})

export default Extension
