import type { Ref } from 'vue'

import { uuid } from '@/helpers'
import type { useHttpSync } from '@/hooks/sync/useHttpSync'
import type { Model } from '@/stores/plugins/sync-plugin'

import type { BrainyStoreItems, MakeMutationHooks } from './index'
import { useMakeMutation } from './index'

export const useMakeStoreMutations = <T extends Model>(
  items: Ref<BrainyStoreItems<T>>,
  httpSync: ReturnType<typeof useHttpSync>
) => {
  const mutations = {
    init(data: T[]) {
      // data.forEach((model) => mutations.upsert(model))
      const tmp = new Map<T['id'], T>()
      data.forEach((model) => (model.id ? tmp.set(model.id, model) : {}))
      items.value = tmp
    },
    upsert: (
      id: T['id'] | (Required<Pick<T, 'id'>> & Partial<T>),
      data: null | Partial<T> = null
    ): T => {
      const modelId: T['id'] = typeof id === 'string' ? id : id.id

      if (!modelId) {
        return
      }

      const updated = {
        ...items.value.get(modelId),
        ...(typeof id === 'string' ? data : id),
      } as T

      // we need to move it
      if (modelId !== updated.id) {
        if (!items.value.get(updated.id as T['id'])?.id) {
          mutations.upsert(updated)
        }

        mutations.remove(modelId)
      } else {
        items.value.set(modelId, updated)
      }

      return items.value.get(modelId) as T
    },
    remove(id: T['id']) {
      if (!items.value.get(id)) {
        return
      }
      items.value.delete(id)
    },

    async create(payload: Partial<T>, hooks: Partial<MakeMutationHooks> = {}) {
      let tmp: T | null = {
        id: uuid(),
        ...payload,
      } as T

      const onRollback = async () => {
        mutations.remove(tmp!.id)
        tmp = null

        hooks.onRollback?.()
      }

      return await useMakeMutation<T>({
        ...hooks,
        localAction: async () => {
          mutations.upsert(tmp!.id, tmp)
        },
        remoteAction: async () => {
          return (await httpSync<{ data: T }>('create', payload))?.data || null
        },
        onRollback,
        onCommit: async (data) => {
          if (data?.id) {
            mutations.upsert(tmp!.id, data)

            return hooks.onCommit?.(data) || data
          } else {
            await onRollback()
          }
        },
      })
    },
    update: async function update(
      id: T['id'] | (Required<Pick<T, 'id'>> & Partial<T>),
      data: null | Partial<T> = null,
      hooks: Partial<MakeMutationHooks> = {}
    ) {
      let old: T | null = null

      return await useMakeMutation({
        ...hooks,
        onRollback: async (e) => {
          if ((e as any)?.response?.status === 404) {
            const modelId: T['id'] = typeof id === 'string' ? id : id.id
            mutations.remove(modelId)
          } else {
            mutations.upsert(old!)
            old = null
          }

          hooks.onRollback?.()
        },
        localAction: async () => {
          const modelId: T['id'] = typeof id === 'string' ? id : id.id
          old = items.value.get(modelId) ?? null
          mutations.upsert(id, data)
        },
        remoteAction: async () => {
          return (
            (
              await httpSync<{ data: T }>(
                'update',
                items.value.get(typeof id === 'string' ? id : id.id)
              )
            )?.data || null
          )
        },
      })
    },
    async delete(id: T['id'], hooks: Partial<MakeMutationHooks> = {}) {
      let old: T | null = null
      return await useMakeMutation({
        onRollback: async (e) => {
          if ((e as any)?.response?.status === 404) {
            mutations.remove(id)
          } else {
            mutations.upsert(old!)
            old = null
          }

          hooks.onRollback?.()
        },
        localAction: async () => {
          old = items.value.get(id) ?? null
          mutations.remove(id)
        },
        remoteAction: async () => {
          return (await httpSync<{ data: T }>('delete', id))?.data || null
        },
        ...hooks,
      })
    },
  }

  return mutations
}

export default useMakeStoreMutations
