import type { Router, RouteRecordRaw } from 'vue-router'

const moduleRoute: RouteRecordRaw = {
  path: '/topics',
  component: () => import('./TopicsModule.vue'),
  children: [
    {
      path: '/t/:topic',
      name: 'topics.show',
      component: () => import('./views/Show.vue'),
    },
    {
      path: '',
      name: 'topics.index',
      component: () => import('./views/Index.vue'),
      meta: {
        title: 'All collections',
      },
    },
  ],
}

export default (router: Router) => {
  router.addRoute(moduleRoute)
}
