import * as Sentry from '@sentry/vue'
import { defineStore } from 'pinia'

import router from '@/router'
import Http from '@/services/Http'
import { useWorkspaceStore } from '@/stores/workspace.store'
// @ts-ignore
import UserDTO = Brainy.Auth.Contracts.DataTransferObjects.UserDTO
import { toRefs } from '@vueuse/core'
import { computed, nextTick, reactive, watch } from 'vue'

import Analytics from '@/services/Analytics'
import { UserModel } from '@/stores/models/UserModel'
import { WorkspaceType } from '@/types/enums'

export type User = UserDTO & {
  profile_photo_url?: string

  workspaces: string[]
  current_workspace_id?: string
}

interface AuthState {
  user: User | null
  returnUrl: string | null
  triedLoadingWorkspaces: boolean
}

export const useAuthStore = defineStore(
  'auth',
  () => {
    const state = reactive<AuthState>({
      user: null,
      returnUrl: null,
      triedLoadingWorkspaces: false,
      changingWorkspace: false,
    })

    if (import.meta.env.VITE_SENTRY_DSN) {
      watch(
        () => state.user,
        (u) => {
          if (u) {
            Sentry.setUser({
              id: u.id,
              username: u.username,
              current_workspace_id: u.current_workspace_id,
            })
          } else {
            Sentry.setUser(null)
          }
        }
      )
    }

    return {
      ...toRefs(state),

      isLoggedIn: computed(() => state.user !== null),
      currentUser: computed(() => (state.user ? new UserModel({ ...state.user }) : null)),
      currentWorkspace: computed(
        () => state.user?.current_workspace_id || state.user?.workspaces?.[0]
      ),
      hasWorkspace: computed(
        () =>
          (state.user?.workspaces?.length || 0) > 0 ||
          typeof state.user?.current_workspace_id === 'string'
      ),

      upsert(user: Pick<User, 'id'> & Partial<User>) {
        state.user = {
          ...state.user,
          ...user,
        } as User
      },
      async createWorkspace(
        type: number,
        name: string,
        slug?: string,
        onCreated?: () => Promise<void>
      ) {
        if (!state.user) {
          return
        }

        const store = useWorkspaceStore()
        const workspace = await store.create(type, name, slug)
        if (workspace && state.user) {
          state.user.workspaces = [workspace.id, ...(state.user.workspaces || [])]
          state.user.current_workspace_id = workspace.id

          if (onCreated) {
            await onCreated()
          }

          await router.push({
            name: 'workspaces.about',
          })
        }
      },
      async joinWorkspace(workspaceId: string) {
        if (!state.user) {
          return
        }

        await Http.post(`workspaces/${workspaceId}/join`)
        await this.loadMe()
        state.triedLoadingWorkspaces = false
        await this.loadWorkspaces()

        await router.push({
          name: 'explore',
        })
      },
      async register(name: string, email: string, password: string) {
        const user = await Http.post<{ data: { user: User } }>(`register`, {
          name,
          email,
          password,
        })

        // update pinia state
        state.user = user.data.user

        await router.push({
          name: 'workspaces.new',
        })
      },
      async login(email: string, password: string, skipRedirect = false) {
        await Http.post<{ data: { user: User } }>(`login`, {
          email,
          password,
        })

        await this.loadMe()

        if (state.user) {
          Analytics.track(
            'Signed In',
            {
              email: state.user.email,
            },
            {
              context: {
                groupId: state.user.current_workspace_id,
              },
            }
          )
        }

        // redirect to previous url or default to home page
        if (!skipRedirect) {
          await router.push(state.returnUrl || { name: 'explore' })
        }
      },
      async logout() {
        state.user = null
        localStorage.removeItem('user')

        Analytics.reset()

        try {
          await Http.post(`logout`)
        } catch (e) {
          console.log('[auth] logout failed')
        } finally {
          await router.push({ name: 'login' })
          setTimeout(() => {
            const keysToKeep = ['showIntroTour', 'showCatchUp', 'showInboxTip']
            const currentKeys = Object.keys(localStorage)
            currentKeys.forEach((key) => {
              if (!keysToKeep.includes(key)) {
                localStorage.removeItem(key)
              }
            })
            sessionStorage.clear()
          }, 100)
        }
      },
      async loadWorkspaces() {
        if (state.triedLoadingWorkspaces) return

        state.triedLoadingWorkspaces = true

        try {
          const store = useWorkspaceStore()
          await store.loadAll()

          if (state.user) {
            state.user.workspaces = store.all.map((ws: any) => ws.id)
          }
          console.log('Loaded workspace')
        } catch (e) {
          console.log('Failed to load workspaces', { error: e })
        }
        return true
      },
      async loadMe() {
        const user = await Http.get<{ data: User }>(`me`)
        state.user = user.data
      },
    }
  },
  {
    persist: {
      paths: ['user'],
    },
  }
)
