// see https://stackoverflow.com/a/8052100
import type { Model, Sortable } from '@/stores/plugins/sync-plugin'

function getDescendantProp(obj: any, desc: string) {
  var arr = desc.split('.')
  // @ts-ignore
  while (arr.length && (obj = obj[arr.shift()]));
  return obj
}

export function makeSorter<T extends Model>(sort: string | Sortable<T> = '-created_at') {
  let _sort = [sort]

  if (typeof sort === 'object') {
    _sort = Object.keys(sort).map((s) => {
      // @ts-ignore
      return `${s}:${sort[s]}`
    })
  }

  return dynamicSortMultiple(..._sort)
}

// see https://stackoverflow.com/a/4760279
export function dynamicSort(property: string) {
  var sortOrder = 1

  if (property.indexOf(':') >= 0) {
    const parts = property.split(':') as string[]
    property = parts[0]

    if (parts[1] === 'desc') {
      sortOrder = -1
    }
  } else if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }

  return function (a: any, b: any) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    const aProp = getDescendantProp(a, property)
    const bProp = getDescendantProp(b, property)
    var result = aProp < bProp ? -1 : aProp > bProp ? 1 : 0
    return result * sortOrder
  }
}

export function dynamicSortMultiple(...args: any[]) {
  /*
   * save the arguments object as it will be overwritten
   * note that arguments object is an array-like object
   * consisting of the names of the properties to sort by
   */
  var props = args
  return function (obj1: any, obj2: any) {
    var i = 0,
      result = 0,
      numberOfProperties = props.length
    /* try getting a different result from 0 (equal)
     * as long as we have extra properties to compare
     */
    while (result === 0 && i < numberOfProperties) {
      result = dynamicSort(props[i])(obj1, obj2)
      i++
    }
    return result
  }
}
