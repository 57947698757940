import { until } from '@vueuse/core'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import PusherBatchAuthorizer from 'pusher-js-auth'
import { ref } from 'vue'

import { useAppStore } from '@/stores'

const EchoClient = ref<Echo | null>(null)

const useEcho = async (): Promise<Echo> => {
  if (!EchoClient.value) {
    const app = useAppStore()
    await until(app.websocketConfig).toMatch((c) => Object.keys(c).length > 0)

    const options = {
      authEndpoint: import.meta.env.VITE_ECHO_AUTH_ENDPOINT || '/broadcasting/auth',
      // authorizer: PusherBatchAuthorizer,
      // authDelay: 300,
      Pusher,
      auth: {
        withCredentials: true,
      },

      ...app.websocketConfig,
    }
    console.log('Starting Echo', options)
    EchoClient.value = new Echo(options)
  }

  return EchoClient.value as Echo
}

export default useEcho
