import { defineStore } from 'pinia'

import Http from '@/services/Http'
// @ts-ignore
import TweetDTO = Brainy.Document.Contracts.DataTransferObjects.Twitter.TweetDTO
import { computed, ref } from 'vue'

import { makeSorter } from '@/helpers/sort'
import { useBrainyStore } from '@/hooks'
import { TweetModel } from '@/stores/models/TweetModel'

export type Tweet = TweetDTO

export const useTweetsStore = defineStore('tweets', () => {
  const state = useBrainyStore<Tweet>('tweets', {
    model: 'TweetDTO',
    makeModel: (attrs) => new TweetModel(attrs),
  })

  const fetchHistory = ref([])

  return {
    ...state,
    getOrFetchTweet: computed(() => {
      return async (id: Tweet['id']) => {
        if (!state.byId(id) && !fetchHistory.value.includes(id)) {
          fetchHistory.value.push(id)
          const { data: tweet } = await Http.get<{ data: Tweet }>(`/tweets/${id}`)
          state.upsert(tweet)
        }

        return state.byId(id)
      }
    }),
    byConversationId: computed(() => {
      return (
        conversationId: Tweet['conversation_id'],
        authorId: Tweet['author']['id'] | null = null
      ) => {
        if (typeof conversationId === 'undefined' || conversationId == null) {
          return []
        }

        const items = state.all.value
          .sort(makeSorter('id'))
          .filter(
            (item) =>
              item.conversation_id === conversationId && (!authorId || item.author.id === authorId)
          )

        if (items.length <= 1) {
          state.$fetch({
            conversation_id: conversationId,
          })
        }

        return items
      }
    }),
  }
})
