import { defineStore } from 'pinia'

// @ts-ignore
import MemberDTO = Brainy.Workspace.Contracts.DataTransferObjects.MemberDTO
import Fuse from 'fuse.js'
import { computed, watchEffect } from 'vue'

import { useBrainyStore } from '@/hooks'
import { MemberModel } from '@/stores/models/MemberModel'

export type Member = MemberDTO

export const useMemberStore = defineStore('members', () => {
  const state = useBrainyStore<Member>('members', {
    version: 1,
    model: 'MemberDTO',
    makeModel: (attrs) => new MemberModel(attrs),
  })

  const users = computed(() => {
    return state.all.value.map((m) => m.user).filter((u) => u) as NonNullable<Member['user']>[]
  })

  const fuse = new Fuse([], {
    keys: [
      { name: 'personal_site', weight: 0.7 },
      { name: 'role', weight: 0.25 },
      { name: 'user.email', weight: 1.0 },
      { name: 'user.username', weight: 1.0 },
      { name: 'user.name', weight: 1.8 },
    ],
    includeScore: true,
    threshold: 0.35,
  })

  // @ts-ignore
  watchEffect(() => fuse.setCollection(state.all.value))

  return {
    ...state,

    admins: computed(() => {
      return state.filter({ filter: (m: Member) => ['admin', 'workspace_owner'].includes(m.role) })
        .value
    }),
    members: computed(() => {
      return state.filter({ filter: (m: Member) => m.role === 'member' }).value
    }),
    users,
    userById: computed(() => {
      return (userId: Member['user_id']) => {
        if (userId === 'brainy') {
          return {
            id: 'brainy',
            name: 'Brainy',
          }
        }

        // todo: we should probably consider making a store for slack users
        //       and then fetch the name from there
        if (userId.startsWith('isusr_')) {
          return {
            id: userId,
            name: 'Slack user',
          }
        }

        return users.value.filter((u) => u.id === userId)?.[0]
      }
    }),
    byUserId: computed(() => {
      return (userId: string) => {
        return state.all.value.find((m) => m.user_id === userId)
      }
    }),
    byUsername: computed(() => {
      return (username: string) => {
        return state.all.value.find((m) => m?.user?.username === username)
      }
    }),
    search: computed(() => {
      return (query: string, withScore: boolean = false) => {
        if (!query) {
          return withScore
            ? state.all.value.map((m) => ({
                item: m.user,
                score: m.user.name,
              }))
            : state.all.value
        }

        const res = fuse.search(query)

        if (!withScore) {
          return res.map((r) => r.item as MemberModel)
        }

        return res.map((r) => ({
          score: r.score,
          item: (r.item as MemberModel).user,
        }))
      }
    }),
    userSearch: computed(() => {
      return (query: string) => {
        return users.value.filter((u) =>
          query.length ? u.name.toLowerCase().includes(query.toLowerCase()) : true
        )
      }
    }),
  }
})
