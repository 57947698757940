import { type Ref, ref } from 'vue'

import Http from '@/services/Http'
import { useSourceMetadataStore } from '@/stores/sourceMetadata.store'

interface OpenGraphData {
  title?: string
  description?: string
  url?: string
  site_name?: string
  image?: string
  type?: string
}

interface UseOpenGraphResponse {
  loading: Ref<boolean>
  data: Ref<OpenGraphData | null>
  fetchData: Function
}

interface UseOpenGraphOptions {
  onError?: Function
}

function useOpenGraph(options: UseOpenGraphOptions = {}): UseOpenGraphResponse {
  const loading = ref(false)
  const state = ref(null)

  const sources = useSourceMetadataStore()

  const { onError = (e: any) => {} } = options

  async function fetchData(url: string) {
    if (loading.value) return

    try {
      loading.value = true

      const cached = sources.byUrl(url)
      if (cached) {
        state.value = cached
        return
      }

      const { data } = await Http.post(`og-data`, { url })

      if (!data) return
      state.value = data.data
      sources.addMetadata(url, data.data)
    } catch (e) {
      onError(e)
    } finally {
      loading.value = false
    }
  }

  return {
    loading,
    data: state,
    fetchData,
  }
}

export default useOpenGraph
