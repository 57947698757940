import type { MaybeRef } from '@vueuse/core'
import { computed, ref, unref, watchEffect } from 'vue'

import { type Document, useDocumentStore } from '@/stores'

interface UseDocumentsOptions {
  filter?: (x: Document) => boolean
  sort?: (obj1: any, obj2: any) => number
  limit?: number
}

function useDocuments(options: MaybeRef<UseDocumentsOptions> = {}) {
  const store = useDocumentStore()

  const _options = ref<UseDocumentsOptions>({})

  watchEffect(() => {
    _options.value = unref(options)
  })

  return computed(() => {
    let docs = store.filter({
      filter: _options.value.filter,
      sort: _options.value.sort,
    })

    if (_options.value?.limit) {
      return docs.value.slice(0, _options.value?.limit)
    }

    return docs.value
  })
}

export default useDocuments
