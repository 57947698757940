import Http from '@/services/Http'

export interface UploadedFile {
  id: string
  content_type: string
  filename: string
}

export async function useFileUpload(file: File): Promise<UploadedFile> {
  const { data } = await Http.post('signed-storage-url', {
    content_type: file.type,
  })

  // eslint-disable-next-line no-unused-vars
  const { Host, ...headers } = data.headers
  await Http.put(data.url, file, {
    headers,
    // onUploadProgress(e) {
    // progress(e.lengthComputable, e.loaded, e.total)
    // },
  })

  return {
    id: data.id,
    content_type: file.type,
    filename: file.name,
  }
}
