// @ts-ignore
import SubscriptionDTO = Brainy.Subscription.Contracts.DataTransferObjects.SubscriptionDTO
import { BaseModel } from '@/stores/models/BaseModel'
import { TopicModel } from '@/stores/models/TopicModel'

export class SubscriptionModel extends BaseModel<SubscriptionDTO> implements SubscriptionDTO {
  declare entity_id: string
  declare id: string
  declare is_muted: boolean
  declare user_id: string
  declare workspace_id: string
  // relations
  declare topic: TopicModel
}
