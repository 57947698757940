import { createConfirmDialog } from 'vuejs-confirm-dialog'

import InviteModal from '@/modules/workspace/components/Modals/InviteModal.vue'
import Analytics from '@/services/Analytics'

interface UseInviteModalOptions {
  onSuccess?: Function
}
function useInviteModal(options: UseInviteModalOptions = {}) {
  const { onSuccess = () => {} } = options

  const { reveal, onConfirm } = createConfirmDialog(InviteModal)

  Analytics.track('Invite Model Opened')

  onConfirm(() => {
    Analytics.track('Invite Model Submitted')
    onSuccess()
  })

  reveal()
}

export default useInviteModal
