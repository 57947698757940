<template>
  <SlideOver show :title="title" inner-class="max-w-[320px]" @close="$emit('cancel')">
    <BdsButtonGroup class="w-full mb-5">
      <BdsButton xs full :transparent="tab !== 'reads'" @click="tab = 'reads'">Reads</BdsButton>
      <BdsButton xs full :transparent="tab !== 'claps'" @click="tab = 'claps'">Claps</BdsButton>
      <BdsButton xs full :transparent="tab !== 'pokes'" @click="tab = 'pokes'">Pokes</BdsButton>
    </BdsButtonGroup>

    <template v-if="tab === 'reads'">
      <BdsStack items-center>
        <div class="flex flex-col flex-1 space-y-1">
          <div class="text-f19 text-grey-800 dark:text-black-100 font-medium leading-5">
            {{ readsGotten }}
          </div>
          <BdsText size="xs" class="text-grey-500">You got</BdsText>
        </div>
        <div class="flex flex-col flex-1 space-y-1">
          <div class="text-f19 text-grey-800 dark:text-black-100 font-medium leading-5">
            {{ readsDone }}
          </div>
          <BdsText size="xs" class="text-grey-500">You read</BdsText>
        </div>
      </BdsStack>

      <BdsList
        v-if="reads?.length"
        no-padding
        class="mt-5 border-t border-grey-50 dark:border-black-700 dark:!divide-black-700"
      >
        <BdsStack v-for="read in reads" :key="read.id" items-start gap-sm class="py-4">
          <RenderlessUserAvatar :user-id="read.user_id" sm />

          <BdsStack direction-column gap-xs class="max-w-[230px]">
            <router-link :to="read.document?.urls?.read" tabindex="-1" @click="$emit('cancel')">
              <BdsText size="sm" medium leading-none truncate>
                {{ read.document?.title || 'Untitled doc' }}
              </BdsText>
            </router-link>
            <BdsText size="f13">{{ read.user?.name }} read your doc</BdsText>
            <BdsText size="xs" class="text-grey-500 dark:text-black-300">
              <TimeAgo :timestamp="read.created_at" />
            </BdsText>
          </BdsStack>
        </BdsStack>
      </BdsList>
      <EmptyState v-else icon="Progress" class="h-full">No reads yet.</EmptyState>
    </template>
    <template v-else-if="tab === 'claps'">
      <BdsStack items-center>
        <div class="flex flex-col flex-1 space-y-1">
          <div class="text-f19 text-grey-800 dark:text-black-100 font-medium leading-5">
            {{ clapsGotten }}
          </div>
          <BdsText size="xs" class="text-grey-500">You got</BdsText>
        </div>
        <div class="flex flex-col flex-1 space-y-1">
          <div class="text-f19 text-grey-800 dark:text-black-100 font-medium leading-5">
            {{ clapsMade }}
          </div>
          <BdsText size="xs" class="text-grey-500">You clapped</BdsText>
        </div>
      </BdsStack>

      <BdsList
        v-if="claps?.length"
        no-padding
        class="mt-5 border-t border-grey-50 dark:border-black-700 dark:!divide-black-700"
      >
        <BdsStack v-for="clap in claps" :key="clap.id" items-start gap-sm class="py-4">
          <RenderlessUserAvatar :user-id="clap.user_id" sm />

          <BdsStack direction-column gap-xs class="max-w-[230px]">
            <router-link :to="clap.document?.urls?.read" tabindex="-1" @click="$emit('cancel')">
              <BdsText size="sm" medium leading-none truncate>
                {{ clap.document?.title || 'Untitled doc' }}
              </BdsText>
            </router-link>
            <BdsText size="f13">{{ clap.user?.name }} clapped {{ clap.claps }} times</BdsText>
            <BdsText size="xs" class="text-grey-500 dark:text-black-300">
              <TimeAgo :timestamp="clap.created_at" />
            </BdsText>
          </BdsStack>
        </BdsStack>
      </BdsList>
      <EmptyState v-else icon="Clap" class="h-full">No claps yet.</EmptyState>
    </template>
    <template v-else-if="tab === 'pokes'">
      <BdsStack items-center>
        <div class="flex flex-col flex-1 space-y-1">
          <div class="text-f19 text-grey-800 dark:text-black-100 font-medium leading-5">
            {{ pokesGotten }}
          </div>
          <BdsText size="xs" class="text-grey-500">You got</BdsText>
        </div>
        <div class="flex flex-col flex-1 space-y-1">
          <div class="text-f19 text-grey-800 dark:text-black-100 font-medium leading-5">
            {{ pokesMade }}
          </div>
          <BdsText size="xs" class="text-grey-500">You poked</BdsText>
        </div>
      </BdsStack>

      <BdsList
        v-if="pokes?.length"
        no-padding
        class="mt-5 border-t border-grey-50 dark:border-black-700 dark:!divide-black-700"
      >
        <BdsStack v-for="poke in pokes" :key="poke.id" items-start gap-sm class="py-4">
          <RenderlessUserAvatar :user-id="poke.user_id" sm />

          <BdsStack direction-column gap-xs class="max-w-[230px]">
            <router-link :to="poke.document?.urls?.read" tabindex="-1" @click="$emit('cancel')">
              <BdsText size="sm" medium leading-none truncate>
                {{ poke.document?.title || 'Untitled doc' }}
              </BdsText>
            </router-link>
            <BdsText size="f13">{{ poke.poker?.name }} poked {{ poke.user?.name }}</BdsText>
            <BdsText size="xs" class="text-grey-500 dark:text-black-300">
              <TimeAgo :timestamp="poke.created_at" />
            </BdsText>
          </BdsStack>
        </BdsStack>
      </BdsList>
      <EmptyState v-else icon="Poke" class="h-full">No pokes yet.</EmptyState>
    </template>
  </SlideOver>
</template>

<script setup lang="ts">
import { BdsButton, BdsButtonGroup, BdsList, BdsStack, BdsText } from '@getbrainy/bds-components'
import { computed, ref } from 'vue'

import EmptyState from '@/components/EmptyState.vue'
import SlideOver from '@/components/Modals/SlideOver.vue'
import RenderlessUserAvatar from '@/components/RenderlessUserAvatar.vue'
import TimeAgo from '@/components/TimeAgo.vue'
import { makeSorter } from '@/helpers/sort'
import { Clap, Poke, useClapStore, useDocumentStore, useMemberStore, usePokeStore } from '@/stores'
import { Reader, useReaderStore } from '@/stores/reader.store'

const props = defineProps({
  userId: { type: String, required: true },
  title: { type: String, default: 'Stats' },
})

defineEmits(['cancel'])

const tab = ref('reads')

const memberStore = useMemberStore()
const clapStore = useClapStore()
const readStore = useReaderStore()
const docStore = useDocumentStore()
const pokeStore = usePokeStore()

const user = computed(() => memberStore.userById(props.userId))

const myDocIds = computed(() =>
  docStore.all.filter((d) => d.user_id === user.value.id).map((d) => d.id)
)

// claps received
const clapsGotten = computed(() =>
  clapStore.all
    .filter((c: Clap) => c.user_id !== user.value?.id)
    .filter((c: Clap) => myDocIds.value.includes(c.document_id))
    .reduce((carry, clap: Clap) => carry + clap.claps, 0)
)
// reads received
const readsGotten = computed(
  () =>
    readStore.all
      .filter((r: Reader) => r.user_id !== user.value?.id)
      .filter((r: Reader) => r.progress > 90)
      .filter((r: Reader) => myDocIds.value.includes(r.document_id)).length
)
const pokesGotten = computed(
  () => pokeStore.all.filter((p: Poke) => p.user_id === user.value?.id).length
)
// claps made
const clapsMade = computed(() =>
  clapStore.allMine
    .filter((c: Clap) => !myDocIds.value.includes(c.document_id))
    .reduce((carry, clap: Clap) => carry + clap.claps, 0)
)
// read docs
const readsDone = computed(
  () =>
    readStore.allMine
      .filter((r: Reader) => r.progress > 90)
      .filter((r: Reader) => !myDocIds.value.includes(r.document_id)).length
)
const pokesMade = computed(
  () => pokeStore.all.filter((p: Poke) => p.poker_id === user.value.id).length
)

const reads = computed(() =>
  readStore.all
    .filter((r: Reader) => r.user_id !== user.value?.id)
    .filter((r: Reader) => r.progress > 90)
    .filter((r: Reader) => myDocIds.value.includes(r.document_id))
    .sort(makeSorter('-created_at'))
)

const claps = computed(() =>
  clapStore.all
    .filter((c: Clap) => c.user_id !== user.value?.id)
    .filter((c: Clap) => myDocIds.value.includes(c.document_id))
    .sort(makeSorter('-created_at'))
)

const pokes = computed(() =>
  pokeStore.all.filter((p: Poke) => p.user_id === user.value?.id).sort(makeSorter('-created_at'))
)
</script>
