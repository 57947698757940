import { type ComputedRef, computed } from 'vue'

import { usePreferencesStore } from '@/stores'

interface MenuControlResponse {
  navigationOpen: ComputedRef<boolean>
  mobileNavigationOpen: ComputedRef<boolean>
  sidebarOpen: ComputedRef<boolean>
  toggleNavigation: Function
  toggleMobileNavigation: Function
  toggleSidebar: Function
  closeNavigation: Function
  openNavigation: Function
}

export default function (): MenuControlResponse {
  const store = usePreferencesStore()
  return {
    navigationOpen: computed(() => store.navigationOpen),
    mobileNavigationOpen: computed(() => store.mobileNavigationOpen),
    sidebarOpen: computed(() => store.sidebarOpen),
    toggleNavigation: store.toggleNavigation,
    toggleMobileNavigation: store.toggleMobileNavigation,
    toggleSidebar: store.toggleSidebar,
    closeNavigation: store.closeNavigation,
    openNavigation: store.openNavigation,
  }
}
