<template>
  <component
    :is="as === 'div' ? 'div' : 'li'"
    class="m-0 px-5 py-4 flex flex-row justify-between items-center rounded border border-light-divider dark:border-dark-button-border shadow-sm"
  >
    <div :class="loading ? 'text-cool-grey' : 'text-blurple'">
      <BdsIcon name="Doc" class="w-5 h-5 mr-6" />
    </div>

    <div class="text-sm text-black flex-1">
      <GhostBox v-if="loading" class="w-12" />

      <template v-else-if="file">
        <div class="font-semibold">
          <a :href="file.src || file.url" target="_blank" class="no-underline hover:underline">
            {{ file.filename }}
          </a>
        </div>

        <div v-if="file?.size" class="text-xs text-cool-grey">
          {{ (file.size / (1024 * 1024)).toFixed(2) }}MB
        </div>
      </template>
    </div>

    <div v-if="showActions" class="flex space-x-4">
      <BdsSpinner v-if="loading" />
      <slot v-else name="icons"></slot>
    </div>
  </component>
</template>

<script lang="ts">
import { BdsIcon, BdsSpinner } from '@getbrainy/bds-components'

import GhostBox from '@/modules/documents/components/Editor/GhostBox.vue'

export default {
  components: {
    GhostBox,
    BdsSpinner,
    BdsIcon,
  },

  props: {
    file: Object,
    as: {
      type: String,
      default: 'div',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
