import { createEventHook } from '@vueuse/core'
import type { PusherPresenceChannel } from 'laravel-echo/dist/channel'
import { reactive, ref } from 'vue'

import { useCurrentWorkspace, useEchoChannel } from '@/hooks/index'
import useCurrentUser from '@/hooks/useCurrentUser'
import type { EchoReturn } from '@/hooks/useEchoChannel'
import router from '@/router'
// import useFeatureFlags, { SPLIT_NAMES } from '@/stores/featureFlags'

export interface PresenceUser {
  id: string
  status: 'online' | 'offline'

  page: string
  mouse: {
    x: number
    y: number
  }
}

let echo: EchoReturn
const isSetup = ref(false)
const users = reactive<Record<string, PresenceUser>>({})
const following = ref<string | null>(null)
const navigateEvent = createEventHook<{ user: string; url: string }>()
export default function useActiveUsers() {
  const currentUser = useCurrentUser()
  const workspace = useCurrentWorkspace()

  const navigate = (url: string) => {
    if (!currentUser.value) {
      return
    }

    console.log('sending navigation', url)
    ;(echo.channel as PusherPresenceChannel).whisper('navigate', {
      user: currentUser.value!.id,
      url,
    })
  }

  if (!echo && !isSetup.value && currentUser.value?.id && workspace.value?.id) {
    console.log('[useActiveUsers] init')
    isSetup.value = true
    useEchoChannel({
      channel: `workspace.${workspace.value.id}`,
      presence: true,
      here: (here) => {
        here.forEach((u: PresenceUser) => (users[u.id] = { ...u, status: 'online' }))
        console.log('[useActiveUsers] Current users', users, Date.now())
      },
      joining: (user) => {
        console.log('[useActiveUsers] joining', user, Date.now())
        users[user.id] = { ...user, status: 'online' }

        console.log(
          '[useActiveUsers] sending my status',
          currentUser.value!.id,
          router.currentRoute.value.fullPath
        )
        if (currentUser.value?.id) {
          ;(echo.channel as PusherPresenceChannel).whisper('navigate', {
            user: currentUser.value.id,
            url: router.currentRoute.value.fullPath,
          })
        }
      },
      leaving: (user) => {
        console.log('[useActiveUsers] leaving', user)
        delete users[user.id]
      },

      whispers: {
        navigate: ({ user, url }) => {
          console.log('[useActiveUsers] navigate', user, url)
          users[user].page = url
          navigateEvent.trigger({ user, url })
        },
      },
    }).then((e) => {
      echo = e
      console.log('connected', Date.now())
      navigate(router.currentRoute.value.fullPath)
      navigateEvent.on(({ user, url }) => {
        console.log('[navigate] someone navigated ', user, url)
        if (user !== following.value) {
          return
        }

        console.log('[follow] following users navigation ', user, url)

        router.push(url)
      })
    })

    isSetup.value = true
    router.afterEach((to) => {
      if (!echo) {
        return
      }

      navigate(to.fullPath)

      if (following.value && to.fullPath !== users[following.value].page) {
        following.value = null
      }
    })
  }

  // const features = useFeatureFlags()

  return {
    echo,
    users,

    follow: (id: string) => {
      // if (features.getTreatmentValue(SPLIT_NAMES.followUser) === 'on') {
      following.value = id
      // }
    },
    unfollow: () => (following.value = null),
    following,

    onUserNavigate: navigateEvent.on,

    navigate,
  }
}
