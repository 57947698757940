import 'pinia'

import { isFunction } from '@vueuse/core'
import type { PiniaPluginContext, StoreActions } from 'pinia'
import { computed, reactive, ref } from 'vue'

import { makeSorter } from '@/helpers/sort'
import { useCurrentUser } from '@/hooks'
import Http from '@/services/Http'
import { BaseModel } from '@/stores/models'
import type { addPrefix, addPrefixToObject } from '@/types/utils'

export type ModelChangeEvent<T extends Model = Model> = {
  syncId: number
  cmd: ChangeSet
  type: string
  id: string
  model: T
  changes: Partial<T>
}

export enum ChangeSet {
  CREATE = 1,
  UPDATE = 2,
  DELETE = 3,
}

export interface SyncConfig {
  method?: 'post' | 'put' | 'patch' | 'delete'
  endpoint?: string | ((entityId: string) => string)
}

export interface SyncOptions {
  enabled: boolean
  baseUrl?: string

  directEdits?: true
  model: string
  relations?: string[]
  methods?: string[]
}

export interface Model {
  id: string
  [k: string]: any
}

export type Sortable<T> =
  | {
      [P in keyof T]: 'asc' | 'desc'
    }
  | keyof T
  | addPrefix<keyof T, '-'>

declare module 'pinia' {
  export interface PiniaCustomProperties<Id, S, G, A> {
    $items: Record<string, Model>

    $fetch(condition?: Record<string, string | number | boolean>): void

    lastSyncId?: number

    upsert<T extends Model>(id: T['id'], data: Omit<Partial<T>, 'id'>): void
    upsert<T extends Model>(id: Required<Pick<T, 'id'>> & Partial<T>): void
    create<T extends Model>(payload: Partial<T>): Promise<T>
    update<T extends Model>(id: T['id'], data: Omit<Partial<T>, 'id'>): Promise<T>
    update<T extends Model>(id: Required<Pick<T, 'id'>> & Partial<T>): Promise<T>
    delete<T extends Model>(id: T['id']): void
    remove<T extends Model>(id: T['id']): void

    $upsert<T extends Model>(id: T['id'], data: Omit<Partial<T>, 'id'>): void
    $upsert<T extends Model>(id: Required<Pick<T, 'id'>> & Partial<T>): void
    $create<T extends Model>(payload: Partial<T>): Promise<T>
    $update<T extends Model>(id: T['id'], data: Omit<Partial<T>, 'id'>): Promise<T>
    $update<T extends Model>(id: Required<Pick<T, 'id'>> & Partial<T>): Promise<T>
    $delete<T extends Model>(id: T['id']): void
    $remove<T extends Model>(id: T['id']): void

    loadAll(): void

    all: Model[]
    byId: <T extends Model = Model>(id: T['id']) => T
    allMine: Model[]
  }

  export interface PiniaCustomStateProperties<S> {
    all: Model[]
    byId: <T extends Model = Model>(id: T['id']) => T
    allMine: Model[]
  }

  export interface DefineStoreOptionsBase<S, Store> {
    makeModel?: (attrs: any) => BaseModel
    sync?: SyncOptions & {
      actions?: Partial<Record<keyof StoreActions<Store>, SyncConfig | false>>
    }
  }
}

const lastSyncId = ref(parseInt(localStorage.getItem('lastSyncId') ?? '0'))

export const useLastSyncId = () => computed(() => lastSyncId.value)

export function createPiniaSyncPlugin(onUpdate?: (f: Function) => void) {
  return ({ store, options }: PiniaPluginContext) => {
    if (!import.meta.env.PROD) {
      // add any keys you set on the store
      store._customProperties.add('lastSyncId')
    }

    if (!options?.sync?.enabled) {
      return
    }

    const modelInit = options.makeModel ? options.makeModel : (a: Model) => new BaseModel(a)

    function wrapModel<T extends Model = Model>(data: T | Model) {
      return data
        ? new Proxy(modelInit(data), {
            set(target: Model | T, p: string | symbol, value: any, receiver: any): boolean {
              crud.update(target.id, {
                ...target.toArray(),
                [p]: value,
              })

              return true
            },
            get(target, prop, receiver) {
              const isRelationProp =
                options.sync?.relations?.includes(prop.toString()) ||
                (Reflect.has(target, `${prop.toString()}_id`) && !Reflect.has(target, prop))
              if (isRelationProp) {
                return store.resolveRelation(prop, target)
              }

              if (options.sync?.methods?.includes(prop.toString())) {
                return store[prop.toString()]()
              }

              // @ts-ignore
              return Reflect.get(...arguments)
            },
          })
        : null
    }

    function httpSync<T = any>(name: string, payload: any) {
      const objId = payload?.['id'] || ''
      const defaults: Record<string, SyncConfig> = {
        create: { endpoint: '/', method: 'post' },
        update: { endpoint: `/${objId}`, method: 'put' },
        delete: { endpoint: `/${objId}`, method: 'delete' },
      }

      const syncOpts = options.sync?.actions?.[name] ?? defaults?.[name]

      if (!syncOpts) {
        return
      }

      const base = options.sync?.baseUrl ?? ``

      const path = (
        typeof syncOpts.endpoint == 'function' ? syncOpts.endpoint : () => syncOpts.endpoint
      )(objId)
      const method = syncOpts.method as NonNullable<SyncConfig['method']>

      if (method && path) {
        return Http.send<T>(method, `${base}/${store.$id}${path}`, payload)
      }
    }

    const state = reactive<{
      items: Record<string, Model>
      updates: Record<string, Model>
      creations: Record<string, Model>
      deletions: Record<string, Model>
    }>({
      items: store.$state.$items ?? {},
      updates: {},
      creations: {},
      deletions: {},
    })

    store.$state.$items = state.items
    store.$state.items = Object.keys(state.items).reduce(
      (carry, key) => Object.assign(carry, { [key]: wrapModel(state.items[key]) }),
      {}
    )

    const inFlight: Record<string, Promise<any>> = {}
    store.$fetch = (condition: Record<string, string> = {}) => {
      const base = options.sync?.baseUrl ?? `/`
      const params = new URLSearchParams(condition)
      const url = `${base}/${store.$id}?` + params.toString()

      if (!inFlight[url]) {
        inFlight[url] = Http.get(url).then((res) => {
          crud.init(res.data)
        })
      }

      return inFlight[url]
    }

    const crud = {
      init<T extends Model>(data: T[]) {
        data.forEach((model) => crud.upsert(model))
      },
      upsert<T extends Model>(
        id: T['id'] | (Required<Pick<T, 'id'>> & Partial<T>),
        data: null | Partial<T> = null
      ): T {
        const modelId: string = typeof id === 'string' ? id : id.id

        state.items[modelId] = {
          ...state.items[modelId],
          ...(typeof id === 'string' ? data : id),
        }

        if (store.$state.items[modelId]) {
          store.$state.items[modelId].setAttributes(typeof id === 'string' ? data : id)
        } else {
          store.$state.items[modelId] = reactive(wrapModel(typeof id === 'string' ? data : id))
        }

        return state.items[modelId] as T
      },
      async create<T extends Model>(payload: Partial<T>) {
        if (typeof payload.id === 'string') {
          crud.upsert(payload as T)
        }

        const result = await httpSync<{ data: T }>('create', payload)

        return result?.data
      },
      async update<T extends Model>(
        id: T['id'] | (Required<Pick<T, 'id'>> & Partial<T>),
        data: null | Partial<T> = null
      ) {
        const payload = crud.upsert(id, data)

        const result = await httpSync('update', payload)

        return result?.data
      },
      remove<T extends Model>(id: T['id']) {
        if (!state.items[id]) {
          return
        }
        delete state.items[id]
        delete store.$state.items[id]
      },
      delete<T extends Model>(id: T['id']) {
        crud.remove(id)

        httpSync('delete', { id })
      },
    }
    store.loadAll = (resetState: boolean = true) => {
      if (resetState) {
        state.items = {}
      }

      store.$fetch()
    }

    // const _hasUpdates = () => Object.values(state.updates).length > 0
    // const _hasCreates = () => Object.values(state.creations).length > 0
    // const _hasDeletions = () => Object.values(state.deletions).length > 0
    //
    // store.hasUpdatedItems = computed(_hasUpdates)
    // store.hasNewItems = computed(_hasCreates)
    // store.hasDeletedItems = computed(_hasDeletions)

    // $pendingUpdates
    // $pendingCreations
    // $pendingDeletions
    // $upsert
    // $remove
    // hasUpdatedItems
    // hasNewItems
    // hasRemovedItems

    const updateHandler = (ev: ModelChangeEvent) => {
      if (ev.type !== options.sync?.model) {
        return
      }

      if (lastSyncId.value > ev.syncId) {
        // discarding old event
        return
      }

      if (![ChangeSet.UPDATE, ChangeSet.CREATE, ChangeSet.DELETE].includes(ev.cmd)) {
        return
      }

      if (ev.cmd === ChangeSet.DELETE) {
        crud.delete(ev.id)
      } else {
        crud.upsert(ev.id, ev.model)
      }

      store.lastSyncId = ev.syncId
      lastSyncId.value = ev.syncId
      localStorage.setItem('lastSyncId', lastSyncId.value.toString())
    }

    if (isFunction(onUpdate)) {
      onUpdate(updateHandler)
    }

    store.$subscribe((mutation) => {
      if (options.sync?.directEdits) {
        // @ts-ignore-next-line
        const targetId: string | undefined = mutation.events?.target?.id
        if (mutation.type !== 'direct' || !targetId) {
          return {}
        }

        const data: Record<string, any> = {}
        data[mutation.events.key] = mutation.events.newValue
        if (state.items[mutation.events.key] !== mutation.events.newValue) {
          store.update(targetId, data)
        }
      }
    })

    const getters = {
      all: computed(() => Object.values(store.$state.items).sort(makeSorter('-id'))),
      allMine: computed(() => {
        const user = useCurrentUser()
        return getters.all.value.filter((m: any) => m.user_id === user.value?.id)
      }),
      byId: computed(
        () =>
          <T extends Model>(id: T['id']) =>
            store.$state.items[id]
      ),
    }

    const mapped = Object.keys(crud).reduce((carry, key: string) => {
      carry = Object.assign(carry, {
        [`$${key}`]: crud[key as keyof typeof crud],
      })
      if (!store[key]) {
        // @ts-ignore
        carry[key] = (...args: any[]) => crud[key as keyof typeof crud](...args)
      }
      return carry
    }, {}) as {
      [K in keyof typeof crud]: typeof crud[K]
    } & addPrefixToObject<typeof crud, '$'>

    return {
      _syncState: state,
      ...getters,
      ...mapped,
    }
  }
}

export function syncPiniaPlugin(context: PiniaPluginContext) {
  return createPiniaSyncPlugin()(context)
}
