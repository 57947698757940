<template>
  <button
    class="h-8 w-8 flex items-center justify-center"
    :class="{
      'text-blurple': active,
    }"
  >
    <BdsIcon :name="icon" :class="iconClasses" />
  </button>
</template>
<script lang="ts">
import { BdsIcon } from '@getbrainy/bds-components'

export default {
  name: 'BubbleMenuButton',
  components: { BdsIcon },
  props: {
    icon: { type: String, required: true },
    active: { type: Boolean, default: false },
    iconClasses: { type: String, default: 'w-4 h-4' },
  },
}
</script>
