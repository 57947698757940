<template>
  <div
    class="file my-4 dark:bg-dark-dropdown"
    :class="[showSearch && !loading.value ? 'rounded' : '']"
  >
    <div v-if="(!showSearch || readOnly || loading.value) && (processing ?? file)">
      <template v-if="isImage">
        <SizeBubbleMenu
          :size="currentSize"
          :show="!readOnly"
          hide-change-btn
          @size="setSize"
          @remove="remove"
        >
          <div
            v-if="loading.value || !(preview?.value ?? file?.src)"
            class="absolute inset-0 bg-white dark:bg-black bg-opacity-80 dark:bg-opacity-90 flex items-center justify-center"
          >
            <BdsSpinner lg />
          </div>
          <img
            v-if="preview?.value ?? file?.src"
            :src="preview?.value ?? file?.src"
            class="media-file mx-auto"
            :class="{ 'w-full': currentSize !== 'original' }"
            @click="useMediaOverlay(preview?.value ?? file?.src)"
          />
        </SizeBubbleMenu>
      </template>
      <FileItem v-else :file="processing ?? file" :loading="loading.value">
        <template v-if="!readOnly" #icons>
          <a
            v-tippy="{ content: 'Change file' }"
            href="#"
            class="py-1"
            @click.prevent="showSearch = true"
          >
            <svg
              class="w-4 h-4 text-black dark:text-white"
              viewBox="0 0 16 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.87624 17.2686L2.30371 14.696M2.30371 14.696L4.87624 12.1235M2.30371 14.696L11.1664 14.696C13.3755 14.696 15.1664 12.9052 15.1664 10.696L15.1664 8.08094"
                stroke="currentColor"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.1247 0.731445L13.6973 3.30398M13.6973 3.30398L11.1247 5.87651M13.6973 3.30398L4.8346 3.30398C2.62546 3.30398 0.834599 5.09484 0.834599 7.30398L0.834599 9.91906"
                stroke="currentColor"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
          <a v-tippy="{ content: 'Remove file' }" href="#" class="py-1" @click.prevent="remove">
            <BdsIcon name="Trash" class="w-4 h-4 text-black dark:text-white" />
          </a>
        </template>
        <template v-if="readOnly" #icons>
          <a v-tippy="{ content: 'Copy link' }" href="#" class="py-1" @click.prevent="copyLink">
            <BdsIcon name="Anchor" class="w-4 h-4 text-black dark:text-white" />
          </a>
          <a v-tippy="{ content: 'Download file' }" :href="file.url" class="py-1">
            <BdsIcon name="Download" class="w-4 h-4 text-black dark:text-white" />
          </a>
        </template>
      </FileItem>
    </div>

    <div v-else-if="!loading.value || showSearch">
      <Search v-if="!isImage" @select="onSelect" @close="remove" />

      <div class="-mb-4">
        <Upload
          :accept="isImage ? 'image/*' : '*'"
          :only-btn="!isImage"
          :processing="false"
          @upload="doUpload"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { BdsIcon, BdsSpinner } from '@getbrainy/bds-components'
import type { MediaFileProperties } from '@getbrainy/extension-media-file'
import { useClipboard } from '@vueuse/core'
import { computed, nextTick, ref, watch } from 'vue'

import { useMediaOverlay } from '@/hooks'
import useCurrentUser from '@/hooks/useCurrentUser'
import SizeBubbleMenu from '@/modules/documents/components/Editor/SizeBubbleMenu.vue'
import type { Asset } from '@/stores/asset.store'

import Upload from '../../Upload.vue'
import FileItem from './FileItem.vue'
import Search from './Search.vue'

export default {
  name: 'MediaFileComponent',
  components: {
    Upload,
    SizeBubbleMenu,
    BdsSpinner,
    BdsIcon,
    Search,
    FileItem,
  },
  props: [
    'readOnly',
    'currentSize',
    'provider',
    'setSize',
    'remove',
    'error',
    'loading',
    'preview',
    'upload',
    'select',
    'file',
    'node',
    'editor',
  ],

  setup(props: MediaFileProperties): any {
    const processing = ref<Partial<Asset> | null>(null)

    const doUpload = (files: File[]) => {
      processing.value = {
        filename: files[0].name,
        size: files[0].size,
      }
      props.upload(files)
    }

    const id: undefined | string = props.node.attrs.id
    const isCollaborating = (props.editor.storage?.collaborationCursor?.users?.length ?? 1) > 1
    const hasTempFile = id && id.startsWith('tmp-')
    if (hasTempFile) {
      const tmpFile = props.editor.storage['media-file'].tmp[id]
      if (!tmpFile && !isCollaborating) {
        nextTick(() => props.remove())
        console.log('tmp file not available, removing node')
        return
      }

      const user = useCurrentUser()
      if (tmpFile?.user == user.value.id) {
        doUpload([tmpFile.file])
      } else {
        console.log('Somebody is uploading something..')
      }
    }

    const showSearch = ref(!props.file?.src && !hasTempFile)
    const searchContainer = ref(null)
    const query = ref('')

    console.log('media-file', {
      res:
        (!showSearch.value || props.readOnly || props.loading.value) &&
        (processing.value ?? props.file),
      provider: props.provider,
      showSearch: showSearch.value,
      readOnly: props.readOnly,
      loading: props.loading.value,
      processing: processing.value,
      file: props.file,
    })

    watch(
      () => props.file,
      (v) => {
        showSearch.value = !v && !hasTempFile
        processing.value = null

        if (!!v && hasTempFile) {
          delete props.editor.storage['media-file'].tmp[id]
        }
      },
      { immediate: true }
    )

    return {
      isImage: computed(() => props.provider === 'image'),
      showSearch,
      query,
      searchContainer,
      processing,
      doUpload,
      onSelect: (file: Asset) => {
        props.select({
          id: file.id,
          filename: file.filename,
          src: file.src,
          filesize: '' + file.size,
          mime: file.mime_type,
        })
        showSearch.value = false
        processing.value = null
      },
      changeFile: () => {
        // noop atm
      },
      closeSearchOrRemove: () => {
        props.remove()
      },
      copyLink: async () => {
        const clipboard = useClipboard({
          source: props.file?.src,
        })

        await clipboard.copy()
      },
    }
  },
  methods: { useMediaOverlay },
}
</script>
