<template>
  <CommandDialog :show="open" @select="handleSelectItem" @close="$emit('close')">
    <template #header>
      <CommandInput
        placeholder="Type a command or search..."
        :display-value="() => query"
        @change="query = $event.target.value"
      />
    </template>

    <CommandList>
      <CommandEmpty
        v-if="!filteredCommands.length && !topics.length && !docs.length && !members.length"
      >
        No results found.
      </CommandEmpty>

      <CommandItem
        v-for="item in filteredCommands"
        :key="item.label"
        v-slot="{ active }"
        :value="item"
        class="group"
      >
        <div class="flex items-center space-x-4">
          <BdsIcon class="w-3.5 h-3.5" :name="item.icon" />
          <div>{{ item.label }}</div>
        </div>
        <div class="flex items-center space-x-1">
          <div
            v-if="(item?.shortcut?.length || 0) === 0 || active"
            class="p-0.5 bg-grey-100 dark:bg-black-600 rounded group-aria-selected:flex items-center"
            :class="active ? 'flex' : 'hidden group-hover:flex'"
          >
            <BdsIcon class="w-4 h-4" name="Enter" />
          </div>
          <template v-for="key in item.shortcut" v-else :key="key">
            <kbd
              v-if="key !== 'then'"
              class="min-w-[1.25rem] flex items-center justify-center h-5 bg-grey-100 dark:bg-black-600 rounded text-xs font-sans"
            >
              {{ key }}
            </kbd>
            <span v-else class="text-f10 text-grey-500">then</span>
          </template>
        </div>
      </CommandItem>

      <CommandGroup v-if="query.length > 0 && results.length" heading="Search results">
        <CommandItem v-for="result in results" :key="result.id" class="group" :value="result">
          <div class="flex items-center space-x-4">
            <div
              v-if="result.type === 'topic' && result.item.emoji"
              class="w-4 h-4 flex items-center justify-center text-f13"
            >
              {{ result.item.emoji }}
            </div>
            <BdsTopic v-else-if="result.type === 'topic'" icon-only :color="result.item.color" />
            <RenderlessUserAvatar
              v-else-if="result.type === 'member'"
              :user-id="result.item.id"
              sm
            />
            <BdsIcon v-else-if="result.type === 'document'" class="w-4 h-4" name="Doc" />
            <div class="max-w-[250px] sm:max-w-md truncate">
              {{ result.item.name || result.item.title }}
            </div>
          </div>
          <div
            class="hidden p-0.5 bg-grey-100 dark:bg-black-600 rounded group-hover:flex group-aria-selected:flex items-center"
          >
            <BdsIcon class="w-4 h-4" name="Enter" />
          </div>
        </CommandItem>
      </CommandGroup>
    </CommandList>
  </CommandDialog>
</template>

<script setup lang="ts">
import { BdsIcon, BdsTopic } from '@getbrainy/bds-components'
import { onKeyDown } from '@vueuse/core'
import { type ComputedRef, computed, inject, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import CommandDialog from '@/components/Command/CommandDialog.vue'
import CommandEmpty from '@/components/Command/CommandEmpty.vue'
import CommandGroup from '@/components/Command/CommandGroup.vue'
import CommandInput from '@/components/Command/CommandInput.vue'
import CommandItem from '@/components/Command/CommandItem.vue'
import CommandList from '@/components/Command/CommandList.vue'
import RenderlessUserAvatar from '@/components/RenderlessUserAvatar.vue'
import { makeSorter } from '@/helpers/sort'
import { useEditTopic, usePreferences, useRouteContext } from '@/hooks'
import checkContentMismatch from '@/hooks/checkContentMismatch'
import { useCopyTopicLink, useShowTopicFollowers } from '@/hooks/useEditTopic'
import useOS from '@/hooks/useOS'
import useStatsModal from '@/hooks/useStatsModal'
import Analytics from '@/services/Analytics'
import { useDocumentStore, useMemberStore, useSubscriptionStore, useTopicStore } from '@/stores'
import type { DocumentModel } from '@/stores/models/DocumentModel'
import { MemberModel } from '@/stores/models/MemberModel'
import type { TopicModel } from '@/stores/models/TopicModel'
import type { UserModel } from '@/stores/models/UserModel'
import type { WorkspaceModel } from '@/stores/models/WorkspaceModel'

interface Props {
  show: boolean
}

const currentUser = inject('user') as ComputedRef<UserModel>
const currentWorkspace = inject('workspace') as ComputedRef<WorkspaceModel>

const props = withDefaults(defineProps<Props>(), {})
const open = ref(props.show)
const query = ref('')

watch(
  () => props.show,
  (v) => (open.value = v)
)

watch(open, (v) => {
  if (v) {
    query.value = ''
    Analytics.track('Command Palette Opened')
  } else {
    Analytics.track('Command Palette Closed')
  }
})

const emit = defineEmits(['close', 'open'])

const { isMacOS } = useOS()

onKeyDown(['k'], (e: KeyboardEvent) => {
  if (e.metaKey || (e.ctrlKey && !isMacOS.value)) {
    e.preventDefault()
    open.value ? emit('close') : emit('open')
  }
})

const router = useRouter()

type Command = {
  icon: string
  label: string
  perform: Function
}

const defaultCommands = [
  {
    icon: 'ArrowRight',
    label: 'Go to inbox',
    perform: () => {
      router.push({ name: 'inbox' })
    },
    shortcut: ['G', 'then', 'I'],
    disabled: currentWorkspace.value?.isPersonal,
  },
  {
    icon: 'ArrowRight',
    label: 'Go to catch up',
    perform: () => {
      router.push({ name: 'catch-up' })
    },
    shortcut: ['G', 'then', 'C'],
    disabled: currentWorkspace.value?.isPersonal,
  },
  {
    icon: 'ArrowRight',
    label: 'Go to explore',
    perform: () => {
      router.push({ name: 'explore' })
    },
    shortcut: ['G', 'then', 'E'],
  },
  {
    icon: 'ArrowRight',
    label: 'Go to profile',
    perform: () => {
      router.push(currentUser.value.urls.show)
    },
    shortcut: ['G', 'then', 'I'],
    disabled: currentWorkspace.value?.isPersonal,
  },
  {
    icon: 'ArrowRight',
    label: 'Go to settings',
    perform: () => {
      router.push({ name: 'settings.workspace.general' })
    },
    shortcut: ['G', 'then', 'S'],
  },
  {
    icon: 'ArrowRight',
    label: 'Go to personal settings',
    perform: () => {
      router.push({ name: 'settings.account' })
    },
    disabled: currentWorkspace.value?.isPersonal,
  },
  {
    icon: 'ArrowRight',
    label: 'Go to applications',
    perform: () => {
      router.push({ name: 'settings.applications' })
    },
  },
  {
    icon: 'ArrowRight',
    label: 'Go to stats',
    perform: () => {
      useStatsModal(currentUser.value.id)
    },
    disabled: currentWorkspace.value?.isPersonal,
  },
  {
    icon: 'Refresh',
    label: 'Check for new content',
    perform: async () => await checkContentMismatch(true),
  },
]

const { inTopic } = useRouteContext()

const { isListView, setTopicView } = usePreferences()

const topicStore = useTopicStore()
const subscriptionStore = useSubscriptionStore()

const commands: ComputedRef<Command[]> = computed(() => {
  if (inTopic.value) {
    const topic = topicStore.bySlug(<string>router.currentRoute.value.params.topic)
    const subscription = subscriptionStore.byEntityId(topic.id, currentUser.value?.id as string)

    return [
      {
        icon: 'Play',
        label: `Catch up with ${topic.name}`,
        perform: () => router.push({ name: 'catch-up.topic', params: { topic: topic.slug } }),
        shortcut: ['⌥', 'C'],
        disabled: currentWorkspace.value?.isPersonal,
      },
      {
        icon: 'Link',
        label: `Copy URL to ${topic.name}`,
        perform: () => useCopyTopicLink(topic),
        shortcut: ['⌘', '⇧', 'C'],
      },
      {
        icon: 'UserSearch',
        label: `View all followers`,
        perform: () => useShowTopicFollowers(topic),
        shortcut: ['⌥', '⇧', 'E'],
        disabled: currentWorkspace.value?.isPersonal,
      },
      {
        icon: 'Pen',
        label: `Edit ${topic.name}`,
        perform: () => useEditTopic(topic),
        shortcut: ['E'],
      },
      subscription
        ? {
            icon: 'ArrowLeft',
            label: `Unfollow ${topic.name}`,
            perform: () => topicStore.unfollow(topic),
            shortcut: ['⌥', 'F'],
          }
        : {
            icon: 'ArrowRight',
            label: `Follow ${topic.name}`,
            perform: () => topicStore.follow(topic),
            shortcut: ['⌥', 'F'],
          },
      isListView.value
        ? {
            icon: 'Grid',
            label: `Change layout to grid`,
            perform: () => setTopicView('grid'),
            shortcut: ['V'],
          }
        : {
            icon: 'List',
            label: `Change layout to list`,
            perform: () => setTopicView('list'),
            shortcut: ['V'],
          },
    ]
  }

  return defaultCommands
})

const filteredCommands = computed(() => {
  const _commands =
    query.value.length > 0
      ? [
          ...commands.value.filter((c) =>
            c.label.toLowerCase().includes(query.value.toLowerCase())
          ),

          {
            icon: 'Search',
            label: `Advanced search: ${query.value}`,
            perform: () =>
              router.push({
                name: 'search',
                query: {
                  q: query.value,
                },
              }),
          },
        ]
      : commands.value

  return _commands.filter((c) => !c?.disabled)
})

function handleSelectItem(item: Command | TopicModel | UserModel | DocumentModel) {
  if (typeof item.perform === 'function') {
    Analytics.track('Command Palette Selected', {
      label: item.label,
      query: query.value,
    })
    ;(item as Command).perform()
    emit('close')
    return
  }

  item = item?.item || item

  if (typeof item.id === 'undefined') {
    return
  }

  Analytics.track('Command Palette Selected', {
    query: query.value,
    label: item.label || item.title,
    id: item.id,
  })

  if (item.id.startsWith('doc_')) {
    handleSelectDoc(item)
    return
  }

  if (item.id.startsWith('top_')) {
    handleSelectTopic(item)
    return
  }

  if (item.id.startsWith('usr_')) {
    handleSelectMember(item)
    return
  }
}

function handleSelectTopic(t: TopicModel) {
  router.push(t.urls.show)
  emit('close')
}

function handleSelectDoc(d: DocumentModel) {
  router.push(d.urls.read)
  emit('close')
}
function handleSelectMember(m: UserModel) {
  router.push({ name: 'profiles.show', params: { username: m.username } })
  emit('close')
}

const documentStore = useDocumentStore()
const docs = computed(() =>
  query.value.length
    ? documentStore.all.filter((d) => d.title?.toLowerCase()?.includes(query.value.toLowerCase()))
    : documentStore.all
)

const queryIsSpecial = computed(() => query.value.startsWith('#') || query.value.startsWith('@'))
const queryWithoutHash = computed(() => {
  if (queryIsSpecial.value) {
    return query.value.slice(1)
  }
  return query.value
})
const memberStore = useMemberStore()
const topics = computed(() => topicStore.search(queryWithoutHash.value, true))
const members = computed(() => memberStore.search(queryWithoutHash.value, true))
const documents = computed(() => documentStore.fuseSearch(query.value))

const results = computed(() => {
  const memberResults = members.value.map((m) => ({
    score: m.score,
    item: m.item,
    type: 'member',
  }))

  let results: {
    score: number | string
    item: TopicModel | MemberModel | DocumentModel
    type: string
  }[] = []

  if (query.value.startsWith('@')) {
    results = memberResults
  }

  const topicResults = topics.value.map((t) => ({
    score: t.score,
    item: t.item,
    type: 'topic',
  }))

  if (query.value.startsWith('#')) {
    results = topicResults
  } else if (!queryIsSpecial.value) {
    results = [
      ...memberResults,
      ...topicResults,
      ...documents.value.map((t) => ({
        score: t.score,
        item: t.item,
        type: 'document',
      })),
    ]
  }

  return results.filter((i) => typeof i?.item !== 'undefined').sort(makeSorter('score'))
})
</script>
