import { defineStore } from 'pinia'
import { computed } from 'vue'

import { useBrainyStore } from '@/hooks'
import { useMemberStore } from '@/stores/member.store'
import { CommentModel } from '@/stores/models'
import { useTopicStore } from '@/stores/topic.store'

export type Comment = CommentModel

export const useCommentStore = defineStore('comments', () => {
  const state = useBrainyStore<Comment>('comments', {
    model: 'CommentDTO',
    makeModel: (attrs) => new CommentModel(attrs),
    relations: ['user'],
    resolveRelation: (name, target) => {
      if (name == 'user') {
        const members = useMemberStore()
        return members.userById(target.user_id)
      }
    },
  })

  function byEntityId(entityId: Comment['entity_id']): Comment[]
  // @ts-ignore
  // eslint-disable-next-line no-redeclare
  function byEntityId(entityId: Comment['entity_id'], userId: Comment['user_id']): Comment | null
  // eslint-disable-next-line no-redeclare
  function byEntityId(
    entityId: Comment['entity_id'],
    userId?: Comment['user_id']
  ): Comment[] | Comment {
    const items = state.filter({
      filter: (item) => item.entity_id === entityId && (!userId || item.user_id === userId),
    })

    if (typeof userId === 'string') {
      return items.value[0] || null
    }

    return items.value
  }

  return {
    ...state,
    groupedByUser: state.$groupedBy('user_id', {
      multiple: true,
    }),
    groupedByEntity: state.$groupedBy('entity_id', {
      multiple: true,
    }),
    allByUser: computed(() => {
      return (userId: string) => {
        return state.filter({
          filter: (sub) => sub.user_id === userId,
        })
      }
    }),
    byEntityId: computed(() => byEntityId),
  }
})
