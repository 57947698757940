<script lang="ts">
import { defineComponent, h } from 'vue'

export default defineComponent({
  name: 'CommandEmpty',
  setup(props: any, { slots }) {
    return () => h('div', { class: 'command-empty' }, slots.default ? slots.default() : [])
  },
})
</script>
