<template>
  <div
    class="not-prose select-none relative bg-white dark:bg-black-800 dark:border-black-700 border border-grey-50 rounded-lg w-full max-w-[570px] dark:text-black-100"
  >
    <a :href="src" target="_blank" class="focus:outline-none">
      <img
        :src="`https://opengraph.githubassets.com/${hash}/${id}`"
        class="rounded-t-lg w-full"
        alt="item.name"
        loading="lazy"
      />
      <BdsStack direction-column class="p-4 border-t border-grey-50 dark:border-black-600" gap-xs>
        <BdsText size="sm" medium>GitHub – {{ id }}</BdsText>
        <BdsText size="xs" class="text-grey-500 dark:text-black-300">
          github.com • 3 min read
        </BdsText>
      </BdsStack>
    </a>
  </div>
</template>

<script setup lang="ts">
import { BdsStack, BdsText } from '@getbrainy/bds-components'

defineProps({
  id: {},
  src: {},
})

const date = new Date()
const hash = `${date.getFullYear()}${date.getMonth()}${date.getDay()}`
</script>
