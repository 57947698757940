import { defineStore } from 'pinia'
import { computed } from 'vue'

import { useBrainyStore } from '@/hooks'
import type { Author } from '@/stores/author.store'
import type { Document } from '@/stores/document.store'
import { useDocumentStore } from '@/stores/document.store'
import { useMemberStore } from '@/stores/member.store'
import { ClapModel } from '@/stores/models/ClapModel'

export type Clap = ClapModel

export const useClapStore = defineStore('claps', () => {
  const state = useBrainyStore<Clap>('claps', {
    model: 'ClapDTO',
    makeModel: (attrs) => new ClapModel(attrs),
    relations: ['document', 'user'],
    resolveRelation: (name, target) => {
      if (name === 'document') {
        const documentStore = useDocumentStore()
        return documentStore.byId(target.document_id)
      }
      if (name == 'user') {
        const members = useMemberStore()
        return members.userById(target.user_id)
      }
    },
    version: 1,
  })

  const groupedByDocument = state.$groupedBy('document_id', {
    multiple: true,
  })

  return {
    ...state,

    groupedByDocument,
    byDocumentId: computed(() => {
      return (id: Author['document_id']) => {
        return groupedByDocument.value[id]
      }
    }),
    documentClapCount: computed(() => {
      return (id: Document['id'], userId: Clap['user_id'] | null = null) => {
        return (groupedByDocument.value[id] ?? [])
          .filter((e) => (userId ? e.user_id === userId : true))
          .reduce((total, doc) => (total += doc.claps), 0)
      }
    }),
  }
})
