// @ts-ignore
import PokeDTO = Brainy.Poke.Contracts.DataTransferObjects.PokeDTO
import { BaseModel } from '@/stores/models/BaseModel'
import type { DocumentModel } from '@/stores/models/DocumentModel'
import type { UserModel } from '@/stores/models/UserModel'

export class PokeModel extends BaseModel<PokeDTO> implements PokeDTO {
  declare created_at: string
  declare document_id: string
  declare id: string
  declare poker_id: string
  declare read_at: string | null
  declare updated_at: string
  declare user_id: string

  declare document: DocumentModel
  declare poker: UserModel
  declare user: UserModel
}
