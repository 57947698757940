import axios from 'axios'
import { computed, ref } from 'vue'

import { makeError } from '@/helpers'
import { useAuthStore } from '@/stores'

axios.defaults.withCredentials = true
axios.defaults.baseURL = import.meta.env.VITE_API_URL || 'http://localhost:8000/'

axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'Content-Type': 'application/json',
  Accept: 'application/json',
}

window.axios = axios

const lastError = ref<null | { errors: Record<string, string[number]> }>(null)

export const useValidationError = (field: string) => {
  return computed(() => lastError.value?.errors?.[field]?.[0])
}

export default {
  async get<T>(url: string, config = {}) {
    return this.send<T>('get', url, {}, config)
  },

  async post<T>(url: string, data = {}, config = {}) {
    return this.send<T>('post', url, data, config)
  },

  async put<T>(url: string, data = {}, config = {}) {
    return this.send<T>('put', url, data, config)
  },

  async patch<T>(url: string, data = {}, config = {}) {
    return this.send<T>('patch', url, data, config)
  },

  async delete<T>(url: string, data = {}, config = {}) {
    return this.send<T>('delete', url, data, config)
  },

  async send<T>(
    method: string,
    url: string,
    data = {},
    config = {},
    isRetry = false
  ): Promise<any> {
    lastError.value = null
    try {
      const response = await axios({ method, url, data, ...config })
      return response.data as T
    } catch (e: any) {
      // @ts-ignore
      if (e?.response?.status === 422) {
        // @ts-ignore
        lastError.value = e.response.data
      }
      if (!isRetry && e?.response?.status === 419) {
        await this.get(`sanctum/csrf-cookie`)
        return this.send(method, url, data, config, true)
      }
      if (e?.response?.status === 401 && !['workspaces', 'logout'].includes(url)) {
        const auth = useAuthStore()
        if (auth.isLoggedIn) {
          await auth.logout()
        }
      }
      if (e?.response?.status === 418 && e?.response?.data?.message) {
        makeError(e?.response?.data?.message)
      }

      throw e
    }
  },
}
