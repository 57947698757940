import type { MediaFileAsset } from '@getbrainy/extension-media-file'
import { computed, ref } from 'vue'

import { useAssetUpload } from './useAssetUpload'

export const useMediaFileUpload = (
  onUpload: (asset: MediaFileAsset) => void,
  onError?: (error: unknown) => void
) => {
  const loading = ref(false)
  const preview = ref<string | null>(null)

  const revokePreview = () => {
    if (preview.value) {
      URL.revokeObjectURL(preview.value)
    }
    preview.value = null
  }

  const canMakePreview = (file: File) =>
    ['image/jpeg', 'image/png', 'image/gif'].includes(file.type)

  const uploadFile = async (fileList: Array<File>) => {
    const file = fileList[0]

    revokePreview()
    loading.value = true
    if (canMakePreview(file)) {
      preview.value = URL.createObjectURL(file)
    }

    try {
      const asset = await useAssetUpload(file)

      onUpload({
        id: asset.id,
        src: asset.src,
        filename: asset.filename,
        filesize: '' + asset.size,
        mime: asset.mime_type,
      })
    } catch (e) {
      console.log('error', e)
      // todo: show error and maybe retry?
      onError?.(e)
    } finally {
      console.log('finally')
      loading.value = false
      // todo: wait with this if we support retry?
      revokePreview()
    }
  }

  return {
    loading, //: computed(() => loading.value),
    preview, //: computed(() => preview.value),
    revokePreview,
    uploadFile,
  }
}

export default useMediaFileUpload
