import Http from '@/services/Http'
import type { Asset } from '@/stores/asset.store'

import { useFileChecksum } from './useFileChecksum'
import { useFileUpload } from './useFileUpload'

// todo: add options params to configure skipping checksum or changing asset upload url
export async function useAssetUpload(file: File): Promise<Asset> {
  const checksum = await useFileChecksum(file)

  const checksumResponse = await Http.post('checksum', {
    checksum,
  })

  if (checksumResponse.data?.id) {
    return checksumResponse.data
  }

  const { id, filename } = await useFileUpload(file)

  const assetResponse = await Http.post('assets', {
    id,
    filename,
    mime_type: file.type,
    size: file.size,
    hash: checksum,
  })

  return assetResponse.data
}
