<template>
  <div class="relative" tabindex="-1">
    <BdsInput
      v-model="model"
      v-bind="$attrs"
      @keydown.down.prevent="highlight(activeItem + 1)"
      @keydown.up.prevent="highlight(activeItem - 1)"
      @keydown.enter.prevent="selectActiveItem"
      @keydown.tab="onTab"
      @keydown.esc="onEscape"
      @focus="onFocus"
    />

    <ul
      v-if="open && items.length"
      ref="dropdown"
      class="absolute z-10 mt-1 min-w-[12rem] bg-white shadow-dropdown max-h-56 rounded p-1 overflow-auto focus:outline-none border border-grey-50 dark:bg-black-800 dark:border-black-700 dark:shadow-dark-dropdown"
      tabindex="-1"
    >
      <li
        v-for="(item, index) in items"
        :key="index"
        class="cursor-pointer select-none relative px-3 py-1.5 text-f13 flex items-center justify-between space-x-3 rounded text-grey-800 dark:text-black-100"
        :class="[
          activeItem === index
            ? 'bg-egg-white dark:bg-black-700'
            : 'hover:bg-egg-white dark:hover:bg-black-700',
        ]"
        @click="onSelect(item)"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { BdsInput } from '@getbrainy/bds-components'
import { onClickOutside, useVModel } from '@vueuse/core'
import { computed, defineComponent, nextTick, ref, watch } from 'vue'

export default defineComponent({
  components: { BdsInput },
  inheritAttrs: false,

  props: {
    modelValue: { type: String, default: '' },
    items: { type: Array, required: true },
    clearOnSelect: { type: Boolean, default: true },
    resetOnSelect: { type: Boolean, default: true },
    triggerOnFocus: { type: Boolean, default: false },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const model = useVModel(props, 'modelValue', emit)
    const items = computed(() =>
      props.items.filter((x) => x?.toLowerCase()?.includes(model.value.toLowerCase()))
    )
    const open = ref(false)
    const activeItem = ref(-1)
    const dropdown = ref(null)
    const triggerOnFocus = computed(() => props.triggerOnFocus)

    watch(model, (newValue) => {
      if (newValue.length && items.value.length && !open.value) {
        open.value = true
      } else {
        activeItem.value = -1
      }
    })

    onClickOutside(dropdown, () => (open.value = false))

    const onSelect = async (item) => {
      model.value = item

      await nextTick()

      if (props.clearOnSelect) {
        open.value = false
      }
      if (props.resetOnSelect) {
        activeItem.value = -1
      }
    }

    const selectActiveItem = () => {
      if (activeItem.value === -1) {
        return
      }

      const found = items.value[activeItem.value]
      onSelect(found)
    }

    return {
      model,
      open,
      items,
      activeItem,
      dropdown,
      onFocus: () => {
        if (triggerOnFocus.value) {
          open.value = true
        }
      },
      onSelect,
      selectActiveItem,
      highlight: (index) => {
        if (open.value === false) {
          open.value = true
          return
        }

        if (index < 0) {
          return
        }

        if (index >= items.value.length - 1) {
          index = items.value.length - 1
        }

        const suggestionList = dropdown.value.querySelectorAll('li')
        const highlightItem = suggestionList[index]
        highlightItem?.scrollIntoView({ block: 'nearest' })

        activeItem.value = index
      },
      onTab: () => {
        selectActiveItem()

        if (open.value) {
          open.value = false
        }
      },
      onEscape: (e: KeyboardEvent) => {
        if (open.value) {
          e.preventDefault()
          open.value = false
          activeItem.value = -1
        }
      },
    }
  },
})
</script>
