import { computed } from 'vue'

import { useCurrentWorkspace, useEditTopic, useInviteModal } from '@/hooks/index'
import { useAppStore, useDocumentStore, useMemberStore, useTopicStore } from '@/stores'

function useOnboardingProgress(emit: Function) {
  const appStore = useAppStore()
  const memberStore = useMemberStore()
  const docStore = useDocumentStore()
  const topicStore = useTopicStore()

  const workspace = useCurrentWorkspace()

  appStore.checkExtensionInstallmentStatus()

  const tasks = computed(() =>
    [
      {
        label: 'Invite teammates',
        perform: () => useInviteModal(),
        completed: () => memberStore.all.length > 1,
        disabled: workspace.value?.isPersonal,
      },
      {
        label: 'Create your first collection',
        perform: () => useEditTopic(),
        completed: () => topicStore.all.filter((t) => t.type === 2).length > 0,
      },
      {
        label: 'Install Chrome extension',
        perform: () => window.open('https://brainy.so/chrome-extension/install', '_blank'),
        completed: () => appStore.chromeExtensionInstalled,
        external: true,
        disabled: navigator.userAgent.toLowerCase().indexOf('chrome') > -1,
      },
      {
        label: 'Capture content',
        perform: () => emit('compose'),
        completed: () => docStore.allMine.length > 0,
        shortcut: '⌘V',
      },
    ].filter((t) => !t.disabled)
  )

  const completedTasks = computed(() => tasks.value.filter((t) => t.completed()))
  const progress = computed(() =>
    Math.ceil((completedTasks.value.length / tasks.value.length) * 100)
  )

  return {
    tasks,
    completedTasks,
    progress,
  }
}

export default useOnboardingProgress
