import type { Router, RouteRecordRaw } from 'vue-router'

import useIsBrainy from '@/hooks/useIsBrainy'
import Http from '@/services/Http'

const moduleRoute: RouteRecordRaw = {
  path: '/settings',
  component: () => import('./Module.vue'),
  redirect: { name: 'settings.workspace.general' },
  children: [
    {
      path: 'general',
      name: 'settings.workspace.general',
      component: () => import('./views/General.vue'),
      meta: {
        title: 'General',
      },
    },
    {
      path: 'members',
      name: 'settings.workspace.members',
      component: () => import('./views/Members.vue'),
      meta: {
        title: 'Members',
      },
    },
    {
      path: 'collections',
      name: 'settings.workspace.collections',
      component: () => import('./views/Topics.vue'),
      meta: {
        title: 'Collections',
      },
    },
    {
      path: 'domains',
      name: 'settings.workspace.domains',
      component: () => import('./views/Domains.vue'),
      meta: {
        title: 'Domains',
      },
      beforeEnter: async (to) => {
        if (to.query?.verify) {
          await Http.post(`domains/${to.query?.verify}/verify`)
          return { path: to.path }
        }
      },
    },
    {
      path: 'integrations',
      name: 'settings.workspace.integrations',
      component: () => import('./views/Integrations.vue'),
      meta: {
        title: 'Integrations',
      },
      // beforeEnter: async (to) => {
      //   if (!useIsBrainy()) {
      //     return { path: '/settings' }
      //   }
      // },
      // children: [
      //   {
      //     path: 'slack',
      //     name: 'settings.workspace.integrations.slack',
      //     component: () => import('./views/integrations/Slack.vue'),
      //     meta: {
      //       title: 'Slack',
      //     },
      //   },
      // ],
    },
    {
      path: 'integrations/slack',
      name: 'settings.workspace.integrations.slack',
      component: () => import('./views/integrations/Slack.vue'),
      meta: {
        title: 'Slack',
      },
      // beforeEnter: async (to) => {
      //   if (!useIsBrainy()) {
      //     return { path: '/settings' }
      //   }
      // },
    },

    {
      path: 'account',
      name: 'settings.account',
      component: () => import('./views/ProfileInformation.vue'),
      meta: {
        title: 'Profile information',
      },
    },
    {
      path: 'notifications',
      name: 'settings.notifications',
      component: () => import('./views/Notifications.vue'),
      meta: {
        title: 'Notifications',
      },
    },
    {
      path: 'password',
      name: 'settings.password',
      component: () => import('./views/Password.vue'),
      meta: {
        title: 'Password',
      },
    },
    {
      path: 'preferences',
      name: 'settings.preferences',
      component: () => import('./views/Preferences.vue'),
      meta: {
        title: 'Preferences',
      },
    },
    {
      path: 'security',
      name: 'settings.security',
      component: () => import('./views/Security.vue'),
      meta: {
        title: 'Security',
      },
    },
    {
      path: 'applications',
      name: 'settings.applications',
      component: () => import('./views/Applications.vue'),
      meta: {
        title: 'Applications',
      },
    },
  ],
}

export default (router: Router) => {
  router.addRoute(moduleRoute)
}
