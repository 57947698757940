import { useRouter } from 'vue-router'

import { useDocumentStore } from '@/stores'

function useCreateDocument() {
  const documents = useDocumentStore()
  const router = useRouter()

  return async (topics: string | string[] = []) => {
    const doc = await documents.create(Array.isArray(topics) ? topics : [topics])

    await router.push({
      name: 'document.edit',
      params: {
        id: doc.id,
      },
    })
  }
}

export default useCreateDocument
