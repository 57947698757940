<template>
  <template v-if="id">
    <div
      v-if="!tweets.length && !hasError"
      class="relative h-0 mb-1"
      style="padding-bottom: 56.25%; padding-top: 10px"
    >
      <div class="z-10 absolute inset-0 flex items-center justify-center">
        <BdsSpinner lg />
      </div>
    </div>
    <div
      v-else
      :data-id="id"
      class="not-prose flex items-center sm:w-full sm:py-10 bg-egg-white dark:bg-black-800 rounded preview:py-0"
    >
      <div
        class="relative bg-white dark:bg-black-700 dark:border-black-600 dark:divide-black-600 space-y-4 border border-grey-50 rounded-lg p-4 sm:p-6 divide-y divide-grey-50 sm:w-full sm:w-[540px] max-w-[540px] mx-auto dark:text-black-100 preview:max-w-max preview:sm:w-full"
      >
        <ErrorTweet v-if="hasError" v-bind="{ tweetUrl }" />

        <section
          v-for="(tweet, idx) in tweets"
          v-else
          :key="tweet.id"
          class="relative pt-4 first:pt-0"
        >
          <TweetHeader
            :without-bird="idx !== 0"
            :avatar="tweet.author.avatar"
            :name="tweet.author.name"
            :username="tweet.author.username"
            :tweet-url="tweetUrl"
          />

          <TweetContent v-bind="{ tweet }" class="mb-0" />

          <TweetMediaGroup v-if="tweet.media?.length" class="mt-4" :media="tweet.media" />

          <div v-if="tweet.tweets?.length" class="text-sm mt-6">
            <div
              v-for="ref in tweet.tweets"
              :key="ref.tweet.id"
              class="border rounded-lg overflow-hidden"
            >
              <!--                        https://twitter.com/hanspagel/status/1489717703157981186-->

              <div class="p-4">
                <TweetHeader
                  :avatar="ref.tweet.author.avatar"
                  :name="ref.tweet.author.name"
                  :username="ref.tweet.author.username"
                  without-bird
                  small
                />
                <TweetContent :tweet="ref.tweet" class="mb-0" />
              </div>

              <!-- TODO: This had `gap-2` instead of `gap-1`, and no borders.. also only seemed to support a single static photos -->
              <TweetMediaGroup v-if="ref.tweet.media?.length" :media="ref.tweet.media" />
            </div>
          </div>

          <!--            <div-->
          <!--                v-if="((!allTweets && isFinished) || awaitingMore) && idx === 0"-->
          <!--                class="mt-6 pt-1 border-t border-light-divider"-->
          <!--            >-->
          <!--                <p-->
          <!--                    v-if="awaitingMore"-->
          <!--                    class="bg-light-blurple text-blurple p-2 text-sm rounded-lg"-->
          <!--                >-->
          <!--                    <span-->
          <!--                        v-if="isFinished"-->
          <!--                        class="block cursor-pointer mt-2"-->
          <!--                        @click="loadTweet"-->
          <!--                    >-->
          <!--                        We should have everything now. Click here to load them.-->
          <!--                    </span>-->
          <!--                    <span v-else>-->
          <!--                        This is taking a while.. We're still working on getting-->
          <!--                        the tweets.-->
          <!--                    </span>-->
          <!--                </p>-->
          <!--                <p v-else class="bg-light-red text-red p-2 text-sm rounded-lg">-->
          <!--                    We're missing a few tweets here, sorry about that!<br />-->
          <!--                    Check back again later, and we might've been able to fetch-->
          <!--                    them by then.-->
          <!--                </p>-->
          <!--            </div>-->
        </section>

        <Loading
          v-if="showLoadingOrReload"
          v-bind="{ isLoading, isSlow, hasError, hasNewTweets }"
          @reload="loadThread"
        />
      </div>
    </div>
  </template>
</template>

<script lang="ts">
import { BdsSpinner } from '@getbrainy/bds-components'
import type { EmbedAttributes } from '@getbrainy/extension-embed/src/embed'
import { computed, ref, watch, watchEffect } from 'vue'

import { type Tweet, useTweetsStore } from '@/stores/tweet.store'

import ErrorTweet from './Twitter/ErrorTweet.vue'
import Loading from './Twitter/Loading.vue'
import TweetContent from './Twitter/TweetContent.vue'
import TweetHeader from './Twitter/TweetHeader.vue'
import TweetMediaGroup from './Twitter/TweetMediaGroup.vue'

export interface TwitterWrapperProps {
  id: string
  url: string
}

export default {
  components: {
    BdsSpinner,
    ErrorTweet,
    TweetContent,
    TweetMediaGroup,
    TweetHeader,
    Loading,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },

  setup(props: EmbedAttributes): any {
    const WAIT_THREAD_TIMEOUT = 5_000
    const FORCE_RELOAD_TIMEOUT = 750

    const store = useTweetsStore()

    const tweetId = computed(() => props.id as string)
    const tweet = ref<Tweet | null>(null)

    const tweets = ref<Tweet[]>([])
    const allTweets = computed(() => tweets.value.some((t: Tweet) => t.id == t.conversation_id))

    const hasError = ref(false)

    const isFinished = ref(false)
    const hasNewTweets = computed(
      () =>
        tweets.value.length > 0 &&
        tweets.value.length !=
          store.byConversationId(tweet.value.conversation_id, tweet.value.author.id).length
    )
    const showLoadingOrReload = computed(() => isLoading.value || hasNewTweets.value)
    const isSlow = ref(false)

    const isFetchingTweet = ref(false)
    const hasWaitedForThread = ref(false)

    const isLoading = computed(() => isFetchingTweet.value || !hasWaitedForThread.value)
    const started = Date.now()
    watch(
      tweetId,
      async (id) => {
        if (!id) {
          tweet.value = null
          tweets.value = []
        } else {
          hasWaitedForThread.value = false
          setTimeout(() => (hasWaitedForThread.value = true), WAIT_THREAD_TIMEOUT)

          isFetchingTweet.value = true
          try {
            tweet.value = await store.getOrFetchTweet(id)
            tweets.value = store.byConversationId(
              tweet.value.conversation_id,
              tweet.value.author.id
            )

            if (!hasNewTweets.value) {
              hasWaitedForThread.value = true
            }
          } catch (e) {
            hasError.value = true
          } finally {
            isFetchingTweet.value = false
          }
        }
      },
      {
        immediate: true,
      }
    )

    watchEffect(() => {
      if (!isLoading.value && hasNewTweets.value) {
        console.log('thread load time', Date.now() - started)

        if (Date.now() - started < FORCE_RELOAD_TIMEOUT) {
          tweets.value = store.byConversationId(tweet.value.conversation_id, tweet.value.author.id)
        }
      }
    })

    return {
      tweet,
      tweetUrl: computed(() => props.url),
      loadThread: () => {
        tweets.value = store.byConversationId(tweet.value.conversation_id, tweet.value.author.id)
      },
      allTweets,
      isLoading,
      hasError,
      isSlow,
      showLoadingOrReload,
      tweetId,
      tweets,
      hasNewTweets,
      isFetchingTweet,
      hasWaitedForThread,
    }
  },
}
</script>
