import { useDark, usePreferredDark, useToggle } from '@vueuse/core'
import { watch } from 'vue'

const isDark = useDark({
  storageKey: 'dark-mode',
})

const toggleDark = useToggle(isDark)

// https://web.dev/building-a-theme-switch-component/#synchronizing-with-the-system
const isDarkPreferred = usePreferredDark()
watch(isDarkPreferred, (v) => {
  isDark.value = v
})

export default function () {
  return {
    isDark,
    toggleDark,
    isDarkPreferred,
  }
}
