import { computed } from 'vue'

import { usePreferencesStore } from '@/stores'

function usePreferences() {
  const store = usePreferencesStore()

  return {
    isGridView: computed(() => store.topicView === 'grid'),
    isListView: computed(() => store.topicView === 'list'),
    isTimelineView: computed(() => store.topicView === 'timeline'),
    setTopicView: store.setTopicView,
  }
}

export default usePreferences
