import { Analytics as SegmentAnalytics, AnalyticsBrowser } from '@segment/analytics-next'

const segmentMock = {
  track: () => ({}),
  page: () => ({}),
  identify: () => ({}),
  group: () => ({}),
  reset: () => ({}),
}

export const Analytics =
  import.meta.env.VITE_SEGMENT_ENABLED == 'true'
    ? AnalyticsBrowser.load({
        writeKey: import.meta.env.VITE_SEGMENT_KEY,
      })
    : segmentMock

// Inertia.on('navigate', (event) => {
//     // without the timeout, Segment picks up the page title of the previous page
//     setTimeout(() => {
//         Analytics
//             .page
//             // event.detail.page.props.route.category,
//             // event.detail.page.props.route.name,
//             // event.detail.page.props.route.data
//
//             /*{ url: event.detail.page.url }*/
//             ()
//     }, 50)
// })

export default Analytics
