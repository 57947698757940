<template>
  <div
    v-if="workspaces.length > 1"
    class="hidden sm:flex h-full w-12 pt-5 pb-3 px-3 flex-col items-center gap-sm bg-white border-r border-grey-50 dark:bg-black dark:border-black-800"
  >
    <RenderlessWorkspaceAvatar
      v-for="workspace in workspaces"
      :key="workspace.id"
      v-tippy="{ content: workspace.name, placement: 'right' }"
      :workspace-id="workspace.id"
      class="!w-6 !h-6 cursor-pointer"
      :class="{
        'rounded outline outline-1 outline-offset-2 outline-blurple-900 dark:outline-black-300':
          workspace.id === currentWorkspace?.id,
      }"
      @click="changeWorkspace(workspace)"
    />
    <BdsButtonIcon
      v-tippy="{ content: 'New workspace', placement: 'right' }"
      xs
      transparent
      icon="Plus"
      @click="$router.push({ name: 'workspaces.new' })"
    />
    <div v-if="currentWorkspace?.isTeam" class="mt-auto flex items-center">
      <BdsButtonIcon
        v-tippy="'Invite teammates'"
        xs
        transparent
        icon="PaperPlane"
        @click="useInviteModal()"
      />
    </div>
  </div>

  <BdsSidebar v-if="mobileNavigationOpen" class="block sm:hidden min-w-[255px]">
    <template v-if="inSettings">
      <BdsSidebarHeader>
        <BdsButton sm transparent @click="$router.push({ name: 'inbox' })">
          <div class="-ml-2 flex items-center">
            <BdsIcon class="w-3.5 h-3.5 mr-2" name="ChevronLeft" />
          </div>
          Settings
        </BdsButton>
      </BdsSidebarHeader>

      <BdsSidebarBody>
        <BdsNavigation gap="1.5rem">
          <BdsNavigationGroup
            v-for="group in settingsLinks"
            :key="group.name"
            :name="group.name"
            no-collapse
          >
            <BdsNavigationItem
              v-for="(link, idx) in group.children"
              :key="`link-${idx}`"
              :active="currentRouteName === link?.to?.name"
              :as="link?.to ? 'router-link' : 'div'"
              :to="link?.to"
            >
              {{ link.name }}
            </BdsNavigationItem>
          </BdsNavigationGroup>
        </BdsNavigation>
      </BdsSidebarBody>
    </template>

    <template v-else>
      <BdsSidebarHeader>
        <BdsDropdown width="md">
          <template #trigger>
            <BdsButton sm transparent>
              <RenderlessWorkspaceAvatar
                v-if="currentWorkspace"
                class="-ml-1 mr-2"
                :workspace-id="currentWorkspace?.id"
              />
              {{ currentWorkspace?.name }}
            </BdsButton>
          </template>

          <BdsDropdownItem
            v-for="workspace in workspaces"
            :key="workspace.id"
            :selected="currentWorkspace?.id === workspace.id"
            @click="changeWorkspace(workspace)"
          >
            <RenderlessWorkspaceAvatar :workspace-id="workspace.id" />
            {{ workspace.name }}
          </BdsDropdownItem>
          <template v-if="currentWorkspace?.isCurrentUserAdmin && currentWorkspace?.isTeam">
            <BdsDropdownDivider />
            <BdsDropdownItem as="router-link" :to="{ name: 'settings.workspace.members' }">
              Invite teammates
            </BdsDropdownItem>
            <BdsDropdownItem as="router-link" :to="{ name: 'settings.workspace.members' }">
              Team directory
            </BdsDropdownItem>
            <BdsDropdownItem as="router-link" :to="{ name: 'settings.workspace.general' }">
              Workspace settings
            </BdsDropdownItem>
          </template>
        </BdsDropdown>

        <BdsStack items-center justify-center class="-mr-1">
          <BdsDropdown v-if="auth.currentUser" align="right">
            <template #trigger>
              <BdsButton xs transparent square>
                <RenderlessUserAvatar :user-id="auth.currentUser.id" sm no-indicator />
              </BdsButton>
            </template>

            <template #default="{ close }">
              <BdsDropdownItem
                v-if="currentWorkspace?.isTeam"
                as="router-link"
                :to="auth.currentUser.urls.show"
                @click="close"
              >
                Go to profile
              </BdsDropdownItem>
              <BdsDropdownItem as="router-link" :to="{ name: 'settings.account' }">
                Personal settings
              </BdsDropdownItem>
              <BdsDropdownDivider />
              <BdsDropdownItem @click="toggleDark()">
                {{ isDark ? 'Switch to Light Mode' : 'Switch to Dark mode' }}
              </BdsDropdownItem>
              <BdsDropdownDivider />
              <BdsDropdownItem @click="logout">Log out</BdsDropdownItem>
            </template>
          </BdsDropdown>
        </BdsStack>
      </BdsSidebarHeader>

      <BdsSidebarBody>
        <BdsNavigation gap="1.25rem">
          <BdsNavigationGroup>
            <BdsNavigationItem
              :active="currentRouteName === 'search'"
              as="router-link"
              :to="{ name: 'search' }"
            >
              <BdsIcon name="Search" class="w-3.5 h-3.5" />
              Search
              <template #right>⌘K</template>
            </BdsNavigationItem>
            <BdsNavigationItem
              v-if="currentWorkspace?.isTeam"
              as="router-link"
              :to="{ name: 'catch-up' }"
              :active="currentRouteName === 'catch-up'"
            >
              <BdsIcon name="PlayOutline" class="w-3.5 h-3.5" />
              Catch up
              <template v-if="totalCatchUp" #right>
                <BdsBadge sm>
                  <BdsIcon class="my-px w-2.5 h-2.5 mr-0.5" name="Play" />
                  {{ totalCatchUp }}
                </BdsBadge>
              </template>
            </BdsNavigationItem>
            <BdsNavigationItem
              :active="currentRouteName === 'explore'"
              as="router-link"
              :to="{ name: 'explore' }"
            >
              <BdsIcon name="Rocket" class="w-3.5 h-3.5" />
              Explore
            </BdsNavigationItem>
            <BdsNavigationItem
              v-if="currentWorkspace?.isTeam"
              as="router-link"
              :to="{ name: 'inbox' }"
              :active="currentRouteName === 'inbox'"
            >
              <BdsIcon name="Inbox" class="w-3.5 h-3.5" />
              Inbox

              <template v-if="tasks.length" #right>
                <BdsBadge sm>{{ tasks.length }}</BdsBadge>
              </template>
            </BdsNavigationItem>
          </BdsNavigationGroup>

          <SidebarTopics />
        </BdsNavigation>
      </BdsSidebarBody>
    </template>
  </BdsSidebar>

  <BdsSidebar :collapsed="!navigationOpen" class="hidden sm:block">
    <div v-if="!navigationOpen" class="px-2 py-4">
      <BdsButtonIcon
        v-tippy="{ content: 'Toggle sidebar <kbd>⌘</kbd><kbd>.</kbd>', allowHTML: true }"
        xs
        transparent
        icon="SidebarLeftClosed"
        icon-class="w-3.5 h-3.5"
        @click="toggleNavigation()"
      />
    </div>

    <template v-if="navigationOpen && !inSettings">
      <BdsSidebarHeader>
        <BdsDropdown width="md">
          <template #trigger>
            <BdsButton v-if="currentWorkspace?.isTeam" sm transparent>
              <RenderlessWorkspaceAvatar
                v-if="currentWorkspace"
                class="-ml-1 mr-2"
                :workspace-id="currentWorkspace?.id"
                no-indicator
              />
              {{ currentWorkspace?.name }}
            </BdsButton>
            <BdsButton v-else-if="auth.currentUser" sm transparent>
              <RenderlessUserAvatar
                :user-id="auth.currentUser.id"
                class="-ml-1 mr-2"
                no-indicator
                sm
              />
              {{ auth.currentUser.name }}
            </BdsButton>
          </template>

          <template v-if="currentWorkspace?.isCurrentUserAdmin && currentWorkspace?.isTeam">
            <BdsDropdownItem as="router-link" :to="{ name: 'settings.workspace.members' }">
              Invite teammates
            </BdsDropdownItem>
            <BdsDropdownItem as="router-link" :to="{ name: 'settings.workspace.members' }">
              Team directory
            </BdsDropdownItem>
            <BdsDropdownItem as="router-link" :to="{ name: 'settings.workspace.general' }">
              Workspace settings
            </BdsDropdownItem>
          </template>
          <template v-if="currentWorkspace?.isPersonal">
            <BdsDropdownItem as="router-link" :to="{ name: 'settings.account' }">
              Personal settings
            </BdsDropdownItem>
            <BdsDropdownItem as="router-link" :to="{ name: 'topics.index' }">
              Collections
            </BdsDropdownItem>
            <BdsDropdownItem @click="toggleDark()">
              {{ isDark ? 'Switch to Light Mode' : 'Switch to Dark mode' }}
            </BdsDropdownItem>
          </template>

          <BdsDropdownDivider />
          <BdsDropdownItem as="router-link" :to="{ name: 'workspaces.new' }">
            + Create a Workspace
          </BdsDropdownItem>
          <template v-if="currentWorkspace?.isPersonal">
            <BdsDropdownDivider />
            <BdsDropdownItem @click="logout">Log out</BdsDropdownItem>
          </template>
        </BdsDropdown>

        <BdsStack items-center justify-center class="-mr-1">
          <BdsDropdown v-if="auth.currentUser && currentWorkspace?.isTeam" align="right">
            <template #trigger>
              <BdsButton xs transparent square>
                <RenderlessUserAvatar :user-id="auth.currentUser.id" sm no-indicator />
              </BdsButton>
            </template>

            <template #default="{ close }">
              <BdsDropdownItem as="router-link" :to="auth.currentUser.urls.show" @click="close">
                Go to profile
              </BdsDropdownItem>
              <BdsDropdownItem as="router-link" :to="{ name: 'settings.account' }">
                Personal settings
              </BdsDropdownItem>
              <BdsDropdownItem @click="useStatsModal(auth.currentUser.id)">Stats</BdsDropdownItem>
              <BdsDropdownDivider />
              <BdsDropdownItem @click="toggleDark()">
                {{ isDark ? 'Switch to Light Mode' : 'Switch to Dark mode' }}
              </BdsDropdownItem>
              <BdsDropdownDivider />
              <BdsDropdownItem @click="logout">Log out</BdsDropdownItem>
            </template>
          </BdsDropdown>

          <BdsButtonIcon
            v-if="inEditor || inTopic || inExplore"
            v-tippy="{ content: 'Toggle sidebar <kbd>⌘</kbd><kbd>.</kbd>', allowHTML: true }"
            xs
            transparent
            icon="SidebarLeftOpen"
            icon-class="w-3.5 h-3.5"
            @click="toggleNavigation()"
          />
        </BdsStack>
      </BdsSidebarHeader>

      <BdsSidebarBody>
        <div class="mt-2 mb-5">
          <BdsButton
            v-tippy="{ content: 'Paste from clipboard <kbd>⌘</kbd><kbd>V</kbd>', allowHTML: true }"
            full
            @click="openCompose"
          >
            <BdsIcon name="PlusBold" class="w-2 h-2 mr-1.5" />
            {{ currentWorkspace?.isTeam ? 'Share' : 'Capture' }}
          </BdsButton>
        </div>
        <BdsNavigation gap="1.25rem">
          <BdsNavigationGroup>
            <BdsNavigationItem
              v-if="false"
              :active="currentRouteName === 'search'"
              as="router-link"
              :to="{ name: 'search' }"
            >
              <BdsIcon name="Search" class="w-3.5 h-3.5" />
              Search
              <template #right>⌘K</template>
            </BdsNavigationItem>
            <BdsNavigationItem @click="$emit('search')">
              <BdsIcon name="Search" class="w-3.5 h-3.5" />
              Search
              <template #right>⌘K</template>
            </BdsNavigationItem>
            <BdsNavigationItem
              v-if="currentWorkspace?.isTeam"
              as="router-link"
              :to="{ name: 'catch-up' }"
              :active="currentRouteName === 'catch-up'"
              data-tour="catch-up"
            >
              <BdsIcon name="PlayOutline" class="w-3.5 h-3.5" />
              Catch up
              <template v-if="totalCatchUp" #right>
                <BdsBadge sm>
                  <BdsIcon class="my-px w-2.5 h-2.5 mr-0.5" name="Play" />
                  {{ totalCatchUp }}
                </BdsBadge>
              </template>
            </BdsNavigationItem>
            <BdsNavigationItem
              :active="currentRouteName === 'explore'"
              as="router-link"
              :to="{ name: 'explore' }"
              data-tour="explore"
            >
              <BdsIcon name="Rocket" class="w-3.5 h-3.5" />
              Explore
            </BdsNavigationItem>
            <BdsNavigationItem
              v-if="currentWorkspace?.isTeam"
              as="router-link"
              :to="{ name: 'inbox' }"
              :active="currentRouteName === 'inbox'"
              data-tour="inbox"
            >
              <BdsIcon name="Inbox" class="w-3.5 h-3.5" />
              Inbox

              <template v-if="tasks.length" #right>
                <BdsBadge sm>{{ tasks.length }}</BdsBadge>
              </template>
            </BdsNavigationItem>
          </BdsNavigationGroup>

          <SidebarTopics />

          <BdsNavigationGroup name="Apps">
            <BdsNavigationItem
              v-if="auth.currentUser.hasProvider('twitter')"
              as="router-link"
              :active="currentRouteName === 'twitter.bookmarks'"
              :to="{ name: 'twitter.bookmarks' }"
            >
              <BdsIcon name="Twitter" class="w-3.5 h-3.5 text-[#1D9BF0]" />
              Twitter
              <template #right>
                <BdsBadge
                  sm
                  class="bg-grey-100 text-grey-800 dark:bg-black-800 dark:text-black-100"
                >
                  {{ totalTweets }}
                </BdsBadge>
              </template>
            </BdsNavigationItem>
            <BdsNavigationItem
              v-if="auth.currentUser.hasProvider('github')"
              as="router-link"
              :active="currentRouteName === 'github.stars'"
              :to="{ name: 'github.stars' }"
            >
              <BdsIcon name="Github" class="w-3.5 h-3.5 text-grey-800 dark:text-black-100" />
              Github
              <template #right>
                <BdsBadge
                  sm
                  class="bg-grey-100 text-grey-800 dark:bg-black-800 dark:text-black-100"
                >
                  {{ totalStars }}
                </BdsBadge>
              </template>
            </BdsNavigationItem>
            <BdsNavigationItem as="router-link" :to="{ name: 'settings.applications' }">
              <div
                class="-mx-px w-4 h-4 bg-grey-50 rounded flex items-center justify-center dark:bg-black-600"
              >
                <BdsIcon name="ArrowRightBold" class="w-2 h-2" />
              </div>
              View all apps
            </BdsNavigationItem>
          </BdsNavigationGroup>
        </BdsNavigation>
      </BdsSidebarBody>

      <BdsSidebarFooter v-if="showFooter" class="!border-t-0 px-4">
        <OnboardingProgress @compose="openCompose" @status="onOnboardingStatus" />
      </BdsSidebarFooter>
    </template>

    <template v-else-if="navigationOpen && inSettings">
      <BdsSidebarHeader>
        <BdsButton sm transparent @click="$router.push({ name: 'explore' })">
          <div class="-ml-2 flex items-center">
            <BdsIcon class="w-3.5 h-3.5 mr-2" name="ChevronLeft" />
          </div>
          Settings
        </BdsButton>
      </BdsSidebarHeader>

      <BdsSidebarBody>
        <BdsNavigation gap="1.5rem">
          <BdsNavigationGroup
            v-for="group in settingsLinks"
            :key="group.name"
            :name="group.name"
            no-collapse
          >
            <BdsNavigationItem
              v-for="(link, idx) in group.children"
              :key="`link-${idx}`"
              :active="currentRouteName === link?.to?.name"
              :as="link?.to ? 'router-link' : 'div'"
              :to="link?.to"
            >
              {{ link.name }}
            </BdsNavigationItem>
          </BdsNavigationGroup>
        </BdsNavigation>
      </BdsSidebarBody>
    </template>
  </BdsSidebar>
</template>
<script setup lang="ts">
import {
  BdsBadge,
  BdsButton,
  BdsButtonIcon,
  BdsDropdown,
  BdsDropdownDivider,
  BdsDropdownItem,
  BdsIcon,
  BdsNavigation,
  BdsNavigationGroup,
  BdsNavigationItem,
  BdsSidebar,
  BdsSidebarBody,
  BdsSidebarFooter,
  BdsSidebarHeader,
  BdsStack,
} from '@getbrainy/bds-components'
import { onKeyDown, onKeyStroke } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { type ComputedRef, computed, inject, ref } from 'vue'
import { useRoute } from 'vue-router'

import OnboardingProgress from '@/components/OnboardingProgress.vue'
import RenderlessUserAvatar from '@/components/RenderlessUserAvatar.vue'
import RenderlessWorkspaceAvatar from '@/components/RenderlessWorkspaceAvatar.vue'
import SidebarTopics from '@/components/SidebarTopics.vue'
import { makeError, notUsingInput } from '@/helpers'
import { makeSorter } from '@/helpers/sort'
import {
  useCatchUp,
  useInviteModal,
  useMenuControl,
  useRouteContext,
  useThemeControl,
} from '@/hooks'
import useIsBrainy from '@/hooks/useIsBrainy'
import useStatsModal from '@/hooks/useStatsModal'
import useTodayTasks from '@/hooks/useTodayTasks'
import Analytics from '@/services/Analytics'
import Http from '@/services/Http'
import {
  type User,
  useAppStore,
  useAuthStore,
  useGithubStore,
  useTwitterBookmarkStore,
  useWorkspaceStore,
  Workspace,
} from '@/stores'

const emit = defineEmits(['compose', 'search'])

const openCompose = () => {
  Analytics.track('Sidebar Composer Clicked')

  emit('compose')
}

const auth = useAuthStore()
const workspaceStore = useWorkspaceStore()

const workspaces = computed(() => workspaceStore.all.sort(makeSorter('name')))
const currentWorkspace = computed(() => workspaceStore.current)

const currentRoute = computed(() => useRoute())
const currentRouteName = computed(() => currentRoute.value?.name)

const { isDark, toggleDark } = useThemeControl()
onKeyStroke('t', (ev: KeyboardEvent) => {
  if (!ev.ctrlKey) {
    return
  }

  ev.preventDefault()
  toggleDark()
})

const { mobileNavigationOpen, navigationOpen, toggleNavigation } = useMenuControl()

const { inEditor, inSettings, inTopic, inExplore } = useRouteContext()

onKeyDown(['.'], (e: KeyboardEvent) => {
  if (!e.metaKey) return

  if (inEditor.value || inTopic.value || inExplore.value) {
    toggleNavigation()
  }
})

const totalCatchUp = computed(() => useCatchUp().value.length)

const user = inject('user') as ComputedRef<User>

const settingsLinks = computed(() =>
  [
    currentWorkspace.value?.isCurrentUserAdmin && currentWorkspace.value?.isTeam
      ? {
          name: 'Workspace',
          children: [
            { name: 'General', to: { name: 'settings.workspace.general' } },
            { name: 'Members', to: { name: 'settings.workspace.members' } },
            { name: 'Collections', to: { name: 'settings.workspace.collections' } },
            { name: 'Domains', to: { name: 'settings.workspace.domains' } },
            { name: 'Integrations', to: { name: 'settings.workspace.integrations' } },
          ].filter((x) => x !== null),
        }
      : null,
    {
      name: 'Account',
      children: [
        { name: 'Personal information', to: { name: 'settings.account' } },
        { name: 'Password', to: { name: 'settings.password' } },
        { name: 'Preferences', to: { name: 'settings.preferences' } },
        { name: 'Security', to: { name: 'settings.security' } },
        { name: 'Applications', to: { name: 'settings.applications' } },
      ],
    },
  ].filter((x) => x !== null)
)

const logout = () => {
  auth.logout()
}

onKeyStroke((e: KeyboardEvent) => {
  if (notUsingInput.value && e.altKey && e.shiftKey && e.code === 'KeyQ') {
    e.preventDefault()
    auth.logout()
  }
})

const tasks = computed(() => useTodayTasks().value.filter((t) => !t.read_at))

const appStore = useAppStore()
const changeWorkspace = async (workspace: Workspace) => {
  if (workspace.id === currentWorkspace.value?.id) return

  try {
    await Http.post(`workspace`, { workspace_id: workspace.id })

    Analytics.reset()

    // todo: restructure the way we load the application using stores
    // in order to display a loading screen here while we fetch new content

    auth.changingWorkspace = true
    await auth.loadMe()
    await appStore.clearWorkspaceData()

    location.href = '/'
  } catch (e) {
    console.log('Failed to switch workspace', e)
    makeError('Failed to switch workspace')
  }
}

const twitterBookmarkStore = useTwitterBookmarkStore()
const { count: totalTweets } = storeToRefs(twitterBookmarkStore)

const githubStore = useGithubStore()
const { count: totalStars } = storeToRefs(githubStore)

const showFooter = ref(true)
function onOnboardingStatus(show: boolean) {
  showFooter.value = show
}
</script>
