import { until, watchOnce } from '@vueuse/core'
import { collect } from 'collect.js'
import { defineStore } from 'pinia'
import { computed, nextTick, ref, watch } from 'vue'

import { useUserAvatar } from '@/hooks'
import checkContentMismatch from '@/hooks/checkContentMismatch'
import useActiveUsers from '@/hooks/useActiveUsers'
import Analytics from '@/services/Analytics'
import Http from '@/services/Http'
import { useAuthStore, useBrainyStores } from '@/stores/index'
import OAuthProviderDTO = Brainy.Auth.Contracts.DataTransferObjects.OAuthProviderDTO
import useTodayTasks from '@/hooks/useTodayTasks'

export const useAppStore = defineStore(
  'app',
  () => {
    const config = ref({
      ws: {},
    })
    const booted = ref(false)
    const chromeExtensionInstalled = ref(false)
    const didCheckForExtension = ref(false)

    if ('setAppBadge' in navigator && 'clearAppBadge' in navigator) {
      const inbox = computed(() => useTodayTasks().value.filter((t) => !t.read_at))
      let lastSize = -1
      watch(inbox, (ib) => {
        if (ib.length != lastSize) {
          lastSize = ib.length
          if (lastSize > 0) {
            navigator.setAppBadge(lastSize)
          } else {
            navigator.clearAppBadge()
          }
        }
      })
    }

    function checkExtensionInstallmentStatus() {
      didCheckForExtension.value = true
      const elem = document.getElementById('brainy-capture-root')

      chromeExtensionInstalled.value = !!elem
    }

    return {
      isBooted: computed(() => booted.value),
      chromeExtensionInstalled,
      checkExtensionInstallmentStatus,
      websocketConfig: computed(() => config.value.ws),
      clearWorkspaceData: async () => {
        const keysToKeep = [
          'auth',
          '_workspaces',
          'showIntroTour',
          'showCatchUp',
          'showInboxTip',
          'dark-mode',
        ]
        /// todo: might need to reimplement this somehow, if the current idb stuff doesnt quite work
        // const currentKeys = Object.keys(localStorage)
        // currentKeys.forEach((key) => {
        //   if (!keysToKeep.includes(key)) {
        //     console.log('Removing storage for key:' + key)
        //     localStorage.removeItem(key)
        //   }
        // })
        booted.value = false
        await nextTick()
      },

      bootstrap: async () => {
        config.value = await Http.get(`bootstrap`)

        const auth = useAuthStore()

        try {
          useActiveUsers()

          const store = useBrainyStores()
          const requiredFinishedLoading: (keyof typeof store)[] = [
            // 'workspaces',
            'topics',
            'members',
            'subscriptions',
            'documents',
            'notifications',
            'claps',
            'reminders',
            'documentTopics',
            'documentMetadata',
          ]

          const syncs = collect(store)
            .filter((x) => x !== null)
            .map((s, k) => ({
              store: k,
              sync: s._syncId,
            }))
            .values()
          console.log('Sync status', syncs.max('sync'), syncs.where('sync', syncs.max('sync')))

          // Object.keys(store).forEach(async (key) => {
          //   if (requiredFinishedLoading.includes(key)) {
          //     await until(store[key].$loading).toBe(false)
          //   }
          // })
          // todo: doesnt really seem to work as expected
          console.log('App boot start', Date.now())
          console.log(
            'App boot promises',
            requiredFinishedLoading.map(async (k) => {
              if (!store[k].$isReady) {
                return until(store[k].$isReady).toBe(true)
              }
              return null
            })
          )
          await Promise.race([
            Promise.all(
              requiredFinishedLoading.map(async (k) =>
                store[k].$isReady ? null : until(store[k].$isReady).toBe(false)
              )
            ).then((r) => {
              console.log('$isReady finished', r)
            }),
            new Promise((r) =>
              setTimeout(() => {
                r()
              }, 3_000)
            ).then(() => {
              console.log('App boot timeout finished', Date.now())
            }),
          ])
          console.log('App boot finished', Date.now())

          if (auth.isLoggedIn && auth.user) {
            if (store.workspaces.current) {
              Analytics.group(store.workspaces.current.id, store.workspaces.current.toArray())
            } else {
              watchOnce(
                () => store.workspaces.current?.id,
                (v: any) => {
                  Analytics.group(v, store.workspaces.current!.toArray())
                }
              )
            }

            until(didCheckForExtension)
              .toBe(true)
              .then(() => {
                const avatar = useUserAvatar(auth.user.id)
                Analytics.identify(
                  auth.user.id,
                  {
                    avatar_asset_id: auth.user.avatar_asset_id,
                    created_at: auth.user.created_at,
                    current_workspace_id: auth.user.current_workspace_id,
                    email: auth.user.email,
                    id: auth.user.id,
                    initials: auth.user.initials,
                    username: auth.user.username,
                    name: auth.user.name,
                    updated_at: auth.user.updated_at,
                    verified: auth.user.verified,
                    $avatar: avatar.value.src,
                    oauth_providers: ((auth.user.oauth_providers?.data as OAuthProviderDTO[]) ?? [])
                      .filter((provider) => provider.is_active)
                      .map((provider) => provider.provider),

                    workspace_count: auth.user.workspaces?.length ?? 0,
                    extension_installed: chromeExtensionInstalled.value,
                  }
                  //     {
                  //   workspace_id: user.value.workspace_id,
                  //   email: user.value.p_email,
                  //   name: user.value.p_name,
                  //   work_title: user.value.work_title,
                  //   role: user.value.role,
                  //   profile_photo_url: user.value.profile_photo_url,
                  //   $avatar: user.value.profile_photo_url, // make mixpanel happy
                  //   ...Object.keys(user.value.preferences).reduce(
                  //       (carry, k) =>
                  //           Object.assign(carry, {
                  //             [`preferences_${k}`]: user.value.preferences[k],
                  //           }),
                  //       {}
                  //   ),
                  // }
                )
              })
          }

          booted.value = true
        } catch (e) {
          booted.value = false
          throw e
        }
        setTimeout(() => checkContentMismatch(), 5_000)

        return booted.value
      },
    }
  },
  {
    persist: {
      paths: ['user'],
    },
  }
)
