<template>
  <div class="relative inline">
    <svg
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="w-16 h-16 animate-pulse text-black dark:text-white"
    >
      <path
        d="M8.87207 29.0518C6.964 28.7272 5.01217 29.6132 4.24437 31.3898C4.14523 31.6192 4.0477 31.8495 3.95179 32.0806C2.69133 35.1175 5.62291 38.013 8.86445 37.4612C24.6333 34.7773 41.228 38.3136 54.8207 48.0704C68.7499 58.0686 86.4237 60.3077 102.03 54.7876C103.212 54.3695 104 53.2539 104 52C104 36.573 97.2821 22.7151 86.6121 13.1921C83.9696 10.8336 80.3735 9.90146 76.8777 10.4716C69.2581 11.7142 60.8332 14.1256 54.8206 18.4413C41.2302 28.1965 24.6387 31.7333 8.87207 29.0518Z"
        fill="currentColor"
      />
      <path
        d="M0 52C0 50.7462 0.787728 49.6306 1.9698 49.2125C17.5762 43.6924 35.2501 45.9314 49.1794 55.9297C62.772 65.6864 79.3667 69.2228 95.1355 66.5388C98.3771 65.9871 101.309 68.8826 100.048 71.9195C99.9523 72.1506 99.8547 72.3809 99.7556 72.6102C98.9878 74.3869 97.036 75.2728 95.1279 74.9483C79.3613 72.2668 62.7698 75.8036 49.1793 85.5587C43.1667 89.8745 34.7419 92.2858 27.1223 93.5285C23.6266 94.0986 20.0305 93.1665 17.3879 90.808C6.71792 81.2849 0 67.427 0 52Z"
        fill="currentColor"
      />
      <path
        d="M50.4815 96.2634C46.8817 98.412 47.3717 103.964 51.5638 103.998C51.7091 103.999 51.8545 104 52 104C65.8641 104 78.4611 98.5743 87.7832 89.7303C90.1411 87.4933 88.6944 83.7961 85.4443 83.7589C74.7188 83.6359 63.9631 86.8557 54.8206 93.4181C53.4054 94.434 51.9575 95.3824 50.4815 96.2634Z"
        fill="currentColor"
      />
      <path
        d="M53.5185 7.73661C57.1183 5.58802 56.6283 0.0362352 52.4362 0.00179138C52.2909 0.000597901 52.1455 0 52 0C38.1358 0 25.5389 5.42576 16.2168 14.2697C13.8588 16.5068 15.3056 20.204 18.5556 20.2412C29.2811 20.3641 40.0368 17.1444 49.1793 10.582C50.5946 9.56609 52.0424 8.61763 53.5185 7.73661Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>
