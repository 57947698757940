<template>
  <div class="relative h-0" :style="styleVar">
    <div v-if="loading" class="z-10 absolute inset-0 flex items-center justify-center">
      <BdsSpinner lg />
    </div>

    <iframe
      class="h-full w-full top-0 z-0 lozad"
      :class="{
        'absolute right-0': !loading,
        'fixed right-[300rem]': loading,
      }"
      frameborder="0"
      allowfullscreen
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      v-bind="lazy ? { 'data-src': url } : { src: url }"
      @load="onLoad"
    ></iframe>
  </div>
</template>

<script setup lang="ts">
import { BdsSpinner } from '@getbrainy/bds-components'
import { computed, ref, watch } from 'vue'

const props = defineProps({
  url: { type: String, required: true },
  provider: { type: String, required: true },
  loading: { type: Boolean, default: false },
  lazy: { type: Boolean, default: false },
  card: { type: Boolean, default: false },
})

const emit = defineEmits(['load'])

const loading = ref(true)

const styleVar = computed(() => {
  if (props.provider === 'spotify') {
    return props.card ? `padding-bottom: 80px` : `padding-bottom: 152px`
  }

  return props.provider === 'vimeo' ? 'padding-bottom: 65%' : 'padding-bottom: 56.25%'
})

watch(
  () => props.loading,
  (v) => (loading.value = v)
)

function onLoad() {
  loading.value = false
  emit('load')
}
</script>
