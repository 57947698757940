// @ts-ignore
import NotificationDTO = Brainy.Notification.Contracts.DataTransferObjects.NotificationDTO
import type { User } from '@/stores'
import { BaseModel } from '@/stores/models/BaseModel'

export class NotificationModel extends BaseModel<NotificationDTO> implements NotificationDTO {
  declare created_at: string
  declare data: any[] &
    Record<string, any> & {
      actor_id: string
    }
  declare id: string
  declare notifiable_id: string
  declare read_at: string | null
  declare remind_at: string | null
  declare type: string
  declare updated_at: string
  declare workspace_id: string

  declare actor: User
}
