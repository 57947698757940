import { markRaw } from 'vue'
import type { Router, RouteRecordRaw } from 'vue-router'

import CatchUp from './components/CatchUp.vue'
import Command from './components/Command.vue'
import Extension from './components/Extension.vue'
import Play from './components/Play.vue'
import Tweet from './components/Tweet.vue'

const moduleRoute: RouteRecordRaw = {
  path: '/auth',
  redirect: (to) => {
    console.log('redirecting', {
      to,
    })
    return 'login'
  },
  component: () => import('./Module.vue'),
  children: [
    {
      path: '/login',
      name: 'login',
      meta: {
        title: 'Login',
        public: true,
        imageComponent: markRaw(Command),
        text: `<span class="font-medium">Tip:</span> Use ⌘K to navigate and search at the speed of light.`,
      },
      component: () => import('./views/LoginForm.vue'),
    },
    {
      path: '/register',
      name: 'register',
      meta: {
        title: 'Register',
        public: true,
        register: true,
        imageComponent: markRaw(CatchUp),
        text: `<span class="font-medium">Tip:</span> Use <span class="font-medium">Catch up</span> to get up-to-speed with your team’s latest work and discoveries, in your own pace.`,
      },
      component: () => import('./views/Register.vue'),
    },
    {
      path: '/workspaces/new',
      name: 'workspaces.new',
      meta: {
        title: 'Create workspace',
        register: true,
        imageComponent: markRaw(Extension),
        text: `<span class="font-medium">Tip:</span> Use the <span class="font-medium">Brainy Chrome extension</span> to capture any content from the internet.`,
      },
      component: () => import('./views/CreateWorkspace.vue'),
    },
    {
      path: '/workspaces/about',
      name: 'workspaces.about',
      meta: {
        title: 'Workspace details',
        register: true,
        imageComponent: markRaw(Play),
        text: `<span class="font-medium">Tip:</span> Hit <span class="font-medium">Play</span> on any topic to catch up with the latest or get random inspiration.`,
      },
      component: () => import('./views/WorkspaceInformation.vue'),
    },
    {
      path: '/forgot-password',
      name: 'password.forgot',
      meta: {
        title: 'Forgot password',
        public: true,
        imageComponent: markRaw(CatchUp),
        text: `<span class="font-medium">Tip:</span> Use <span class="font-medium">Catch up</span> to get up-to-speed with your team’s latest work and discoveries, in your own pace.`,
      },
      component: () => import('./views/ForgotPassword.vue'),
    },
    {
      path: '/reset-password',
      name: 'password.reset',
      meta: {
        title: 'Reset password',
        public: true,
        imageComponent: markRaw(Tweet),
        text: `<span class="font-medium">Tip:</span> Select which bookmarks to share with your team by arrowing left or right.`,
      },
      component: () => import('./views/ResetPassword.vue'),
    },
    {
      path: '/invites/:token/accept',
      name: 'invites.accept',
      meta: {
        title: 'Accept workspace invitation',
        public: true,
        imageComponent: markRaw(CatchUp),
        text: `<span class="font-medium">Tip:</span> Use <span class="font-medium">Catch up</span> to get up-to-speed with your team’s latest work and discoveries, in your own pace.`,
      },
      component: () => import('./views/AcceptInvite.vue'),
    },
  ],
}

export default (router: Router) => {
  router.addRoute(moduleRoute)
}
