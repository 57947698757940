<template>
  <BdsNavigationGroup data-tour="topics">
    <template v-for="sub in remaining" :key="sub.id">
      <BdsNavigationItem
        v-if="sub.topic"
        as="router-link"
        :to="sub.topic.urls.show"
        :active="$route.name === 'topics.show' && $route.params.topic === sub.topic.slug"
        class="group"
      >
        <div
          v-if="sub.topic.emoji"
          class="w-4 h-4 flex items-center justify-center text-f13 -mx-px"
        >
          {{ sub.topic.emoji }}
        </div>
        <BdsTopic v-else icon-only :color="sub.topic.color" />
        <span class="truncate max-w-[180px] group-hover:max-w-[150px]">{{ sub.topic.name }}</span>

        <template #right>
          <div
            class="hidden items-center gap-xs group-hover:flex text-grey-800 dark:text-black-200"
          >
            <BdsIcon
              v-if="false"
              v-tippy="'New doc'"
              name="Plus"
              class="w-3.5 h-3.5"
              @click.prevent.stop="createDocument(sub.topic.id)"
            />
            <TopicActions :topic-id="sub.topic.id" fixed>
              <BdsIcon v-tippy="'More'" name="More" class="w-3.5 h-3.5 focus:outline-none" />
            </TopicActions>
            <BdsIcon
              v-if="!catchUpByTopic[sub.topic.id].length"
              v-tippy="workspace?.isTeam ? 'Catch up' : 'Resurface'"
              name="Play"
              class="w-3.5 h-3.5 p-0.5 focus:outline-none"
              @click.prevent.stop="
                $router.push({ name: 'catch-up.topic', params: { topic: sub.topic.slug } })
              "
            />
            <BdsBadge
              v-else
              sm
              class="bg-grey-100 text-grey-800 dark:bg-black-800 dark:text-black-100"
              @click.prevent.stop="
                $router.push({ name: 'catch-up.topic', params: { topic: sub.topic.slug } })
              "
            >
              <BdsIcon name="Play" class="w-2.5 h-2.5 mr-0.5 focus:outline-none" />
              {{ catchUpByTopic[sub.topic.id].length }}
            </BdsBadge>
          </div>

          <BdsBadge
            v-if="catchUpByTopic[sub.topic.id].length"
            class="group-hover:hidden bg-grey-100 text-grey-800 dark:bg-black-800 dark:text-black-100"
            sm
            @click.prevent.stop="
              $router.push({ name: 'catch-up.topic', params: { topic: sub.topic.slug } })
            "
          >
            <BdsIcon name="Play" class="w-2.5 h-2.5 mr-0.5" />
            {{ catchUpByTopic[sub.topic.id].length }}
          </BdsBadge>
        </template>
      </BdsNavigationItem>
    </template>
    <BdsNavigationItem @click="useEditTopic()">
      <div
        class="-mx-px w-4 h-4 bg-grey-50 rounded flex items-center justify-center dark:bg-black-600"
      >
        <BdsIcon name="PlusBold" class="w-2 h-2" />
      </div>
      Create collection
    </BdsNavigationItem>
    <BdsNavigationItem v-if="false" as="router-link" :to="{ name: 'topics.index' }">
      <div
        class="-mx-px w-4 h-4 bg-grey-50 rounded flex items-center justify-center dark:bg-black-600"
      >
        <BdsIcon name="ArrowRightBold" class="w-2 h-2" />
      </div>
      View all collections
    </BdsNavigationItem>
  </BdsNavigationGroup>

  <BdsNavigationGroup v-for="(topics, group) in grouped" :key="group" :name="group">
    <BdsNavigationItem
      v-for="topic in topics"
      :key="topic.id"
      as="router-link"
      :to="topic.urls.show"
      :active="$route.name === 'topics.show' && $route.params.topic === topic.slug"
      class="group"
    >
      <div v-if="topic.emoji" class="w-4 h-4 flex items-center justify-center text-f13 -mx-px">
        {{ topic.emoji }}
      </div>
      <BdsTopic v-else icon-only :color="topic.color" />
      <span class="truncate max-w-[180px] group-hover:max-w-[150px]">{{ topic.label }}</span>
      <template #right>
        <div class="hidden items-center gap-xs group-hover:flex text-grey-800 dark:text-black-200">
          <BdsIcon
            v-if="false"
            v-tippy="'New doc'"
            name="Plus"
            class="w-3.5 h-3.5"
            @click.prevent.stop="createDocument(topic.id)"
          />
          <TopicActions :topic-id="topic.id" fixed>
            <BdsIcon v-tippy="'More'" name="More" class="w-3.5 h-3.5 focus:outline-none" />
          </TopicActions>
          <BdsIcon
            v-if="!catchUpByTopic[topic.id].length"
            v-tippy="workspace?.isTeam ? 'Catch up' : 'Resurface'"
            name="Play"
            class="w-3.5 h-3.5 p-0.5 focus:outline-none"
            @click.prevent.stop="
              $router.push({ name: 'catch-up.topic', params: { topic: topic.slug } })
            "
          />
          <BdsBadge
            v-else
            sm
            class="bg-grey-100 text-grey-800 dark:bg-black-800 dark:text-black-100"
            @click.prevent.stop="
              $router.push({ name: 'catch-up.topic', params: { topic: topic.slug } })
            "
          >
            <BdsIcon name="Play" class="w-2.5 h-2.5 mr-0.5 focus:outline-none" />
            {{ catchUpByTopic[topic.id].length }}
          </BdsBadge>
        </div>

        <BdsBadge
          v-if="catchUpByTopic[topic.id].length"
          class="group-hover:hidden bg-grey-100 text-grey-800 dark:bg-black-800 dark:text-black-100"
          sm
          @click.prevent.stop="
            $router.push({ name: 'catch-up.topic', params: { topic: topic.slug } })
          "
        >
          <BdsIcon name="Play" class="w-2.5 h-2.5 mr-0.5" />
          {{ catchUpByTopic[topic.id].length }}
        </BdsBadge>
      </template>
    </BdsNavigationItem>
  </BdsNavigationGroup>
</template>

<script setup lang="ts">
import {
  BdsBadge,
  BdsIcon,
  BdsNavigationGroup,
  BdsNavigationItem,
  BdsTopic,
} from '@getbrainy/bds-components'
import { collect } from 'collect.js'
import { computed, ComputedRef, inject } from 'vue'

import { useCatchUp, useCreateDocument, useEditTopic } from '@/hooks'
import TopicActions from '@/modules/topics/partials/TopicActions.vue'
import { useSubscriptionStore } from '@/stores'
import { WorkspaceModel } from '@/stores/models/WorkspaceModel'

const subscriptions = useSubscriptionStore()
const allMySubscriptions = computed(() => subscriptions.allMine.filter((s) => s.topic))
const grouped = computed(() => {
  return collect(allMySubscriptions.value.map((s) => s.topic).filter((t) => t.name.includes('/')))
    .groupBy((t) => t.group)
    .all()
})
const remaining = computed(() =>
  allMySubscriptions.value.filter((s) => !s.topic?.name?.includes('/'))
)

const catchUpByTopic = computed(() => {
  const catchUp = useCatchUp()

  return allMySubscriptions.value
    .map((s) => s.topic)
    .reduce((carry, topic) => {
      return Object.assign(carry, {
        [topic.id]: catchUp.value.filter((d) => d.topics.some((t) => t.topic_id === topic.id)),
      })
    }, {})
})

const workspace = inject('workspace') as ComputedRef<WorkspaceModel>

const createDocument = useCreateDocument()
</script>
