<template>
  <svg
    width="138"
    height="133"
    viewBox="0 0 138 133"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.21348 76.6694C0.543292 74.1682 2.02759 71.5973 4.52875 70.9271L26.8781 64.9387C28.0792 64.6168 29.359 64.7853 30.4358 65.407L39.6916 70.7508L48.9473 76.0946C50.0242 76.7164 50.8099 77.7404 51.1318 78.9415L60.9726 115.668C61.6428 118.169 60.1585 120.74 57.6573 121.41L20.7084 131.311C18.2073 131.981 15.6364 130.496 14.9662 127.995L1.21348 76.6694Z"
      fill="#ABB3FF"
    />
    <path
      d="M46.2861 97.5344C43.4647 95.8331 37.683 98.3815 36.2828 98.9402C34.2658 97.7677 32.4141 96.3367 30.7664 94.6777C30.9468 92.1925 30.7782 89.6987 30.2379 87.2638C29.6695 85.1423 28.2084 83.0764 25.8301 83.7137C25.0005 83.9665 24.3498 84.6201 24.1128 85.4484C23.5741 87.5096 24.9486 90.965 27.5422 94.1753C27.3334 97.8102 26.8042 101.429 25.9704 104.977C23.7791 106.655 19.2316 110.422 19.4962 113.084C19.5435 113.908 20.0144 114.638 20.7401 115.025C21.465 115.371 22.2975 115.433 23.0655 115.217C26.2049 114.376 28.1084 109.227 29.251 105.005C31.4482 103.539 33.7458 102.22 36.1204 101.074C40.206 103.079 43.2103 102.723 44.7419 102.312C46.7778 101.767 47.3215 100.714 47.3624 99.8767C47.5332 98.9541 47.0994 98.0201 46.2861 97.5344ZM44.5521 99.5489C44.6245 100.162 44.0241 100.965 42.5719 100.987C40.8805 100.992 39.1924 100.628 37.6518 99.9397C38.7095 99.4728 41.1047 98.5557 42.985 98.419C43.6968 98.4118 44.4939 98.6468 44.5521 99.5489ZM25.6772 85.8449C25.7536 85.5593 25.9697 85.3383 26.2379 85.2358C26.999 85.0319 27.4201 85.9183 27.6265 86.6889C28.0227 88.5099 28.1544 90.3712 28.0208 92.2321C25.836 89.1774 25.4556 86.7302 25.6772 85.8449ZM29.9236 102.378C30.2406 100.631 30.4713 98.866 30.5993 97.0983C31.7228 98.133 32.9401 99.061 34.2531 99.8512C34.276 99.9368 31.7759 101.147 29.9236 102.378ZM25.82 107.117C24.7339 110.865 23.2366 113.458 22.133 113.754C21.9472 113.783 21.7606 113.772 21.5734 113.72C21.2732 113.628 21.0819 113.332 21.1126 113.028C21.0578 111.758 22.8316 109.549 25.82 107.117Z"
      fill="white"
    />
    <path
      d="M90.4525 63.8373C91.1227 61.3362 93.6936 59.8519 96.1948 60.5221L118.544 66.5106C119.745 66.8324 120.769 67.6182 121.391 68.6951L126.735 77.9508L132.079 87.2065C132.7 88.2834 132.869 89.5631 132.547 90.7642L122.706 127.491C122.036 129.992 119.465 131.476 116.964 130.806L80.0151 120.905C77.5139 120.235 76.0296 117.664 76.6998 115.163L90.4525 63.8373Z"
      fill="#D7DBFF"
    />
    <path
      d="M96.9202 105.774C99.2256 106.391 100.76 105.838 101.92 104.883L101.445 106.654L105.409 107.717L107.978 98.1297C109.048 94.1374 106.881 91.99 103.283 91.0257C99.6839 90.0614 96.5143 90.8695 95.3272 94.2879L99.1788 95.3199C99.6865 94.1 100.636 93.4805 102.238 93.9099C104.094 94.4071 104.372 95.5061 103.905 97.2492L103.747 97.8396L102.088 97.3952C97.5898 96.1898 94.0944 96.6394 93.1754 100.069C92.3467 103.162 94.2493 105.058 96.9202 105.774ZM98.89 103.258C97.3999 102.859 96.9416 101.982 97.2279 100.914C97.6497 99.3397 99.163 99.2028 101.525 99.8356L103.099 100.257L102.768 101.494C102.331 103.125 100.689 103.74 98.89 103.258Z"
      fill="white"
    />
    <path
      d="M109.021 108.684L113.069 109.769L117.009 95.0653L112.961 93.9805L109.021 108.684ZM115.492 92.5193C116.813 92.8733 118.081 92.1886 118.42 90.9235C118.759 89.6583 118.003 88.4312 116.682 88.0772C115.389 87.7307 114.12 88.4153 113.781 89.6805C113.442 90.9456 114.198 92.1727 115.492 92.5193Z"
      fill="white"
    />
    <g opacity="0.75">
      <path
        d="M29.2879 14.3299C29.1289 13.0021 30.0763 11.7969 31.4041 11.6379L43.2682 10.2174C43.9058 10.141 44.5476 10.3211 45.0524 10.7179L49.3915 14.129L53.7305 17.5401C54.2353 17.937 54.5618 18.5181 54.6382 19.1557L56.9726 38.6518C57.1316 39.9795 56.1841 41.1848 54.8564 41.3437L35.2422 43.6923C33.9144 43.8513 32.7092 42.9038 32.5502 41.5761L29.2879 14.3299Z"
        fill="#D7DBFF"
      />
      <path
        d="M35.602 31.2937L36.8245 31.1474L37.5786 29.5924L38.654 30.9283L40.0241 30.7643L38.2414 28.6643L39.3708 26.4902L38.1402 26.6375L37.5069 28.0199L36.5486 26.8281L35.1867 26.9912L36.8083 28.9274L35.602 31.2937Z"
        fill="white"
      />
      <path
        d="M40.7314 30.6796L41.9128 30.5381L41.1662 24.303L39.9849 24.4444L40.7314 30.6796Z"
        fill="white"
      />
      <path
        d="M44.5676 30.3118C45.6998 30.1762 46.3146 29.6116 46.2036 28.6846C46.0935 27.7657 45.4704 27.5657 44.377 27.5385C43.7427 27.5229 43.5248 27.4408 43.4924 27.1701C43.461 26.9075 43.6609 26.7005 44.0465 26.6543C44.4567 26.6052 44.6732 26.7457 44.7811 27.0907L45.8722 26.96C45.6477 25.9883 44.8927 25.7292 43.941 25.8431C43.0632 25.9482 42.2958 26.4895 42.4029 27.3837C42.5031 28.2206 42.9854 28.4957 44.1464 28.5314C44.7725 28.548 45.0416 28.6406 45.0769 28.936C45.1123 29.2313 44.9196 29.4291 44.4602 29.4841C43.9433 29.546 43.7281 29.3471 43.6327 28.9674L42.5252 29.1C42.6772 30.0222 43.4108 30.4503 44.5676 30.3118Z"
        fill="white"
      />
      <path
        d="M46.6504 29.9709L47.8728 29.8245L48.627 28.2696L49.7023 29.6054L51.0724 29.4414L49.2898 27.3414L50.4192 25.1673L49.1886 25.3147L48.5552 26.697L47.597 25.5052L46.2351 25.6683L47.8567 27.6045L46.6504 29.9709Z"
        fill="white"
      />
    </g>
    <path
      d="M83.0365 1.63201C83.3068 0.730675 84.2566 0.219087 85.1579 0.489344L93.2119 2.90425C93.6447 3.03403 94.0082 3.33044 94.2225 3.72827L96.0644 7.14762L97.9062 10.567C98.1205 10.9648 98.168 11.4315 98.0382 11.8643L94.0698 25.0992C93.7995 26.0006 92.8498 26.5122 91.9484 26.2419L78.6333 22.2495C77.732 21.9792 77.2204 21.0295 77.4906 20.1281L83.0365 1.63201Z"
      fill="#F0F2FF"
    />
    <path
      d="M82.8118 14.7186L83.6181 14.9603L84.0482 13.5257C84.1133 13.8543 84.3747 14.1751 84.8251 14.3101C85.548 14.5269 86.2546 14.1509 86.543 13.1889L86.5564 13.1444C86.8448 12.1824 86.4471 11.4875 85.7354 11.2741C85.3072 11.1457 84.8947 11.2887 84.6501 11.5183L84.7868 11.0624L83.9805 10.8206L82.8118 14.7186ZM84.7744 13.6101C84.3351 13.4784 84.1421 13.1114 84.3172 12.5276L84.3305 12.4831C84.5055 11.8992 84.8831 11.6912 85.3057 11.8179C85.7116 11.9396 85.914 12.3155 85.7373 12.9049L85.724 12.9494C85.5539 13.5166 85.2192 13.7435 84.7744 13.6101Z"
      fill="white"
    />
    <path
      d="M86.649 14.7903L87.4553 15.0321L87.9588 13.3528C88.0872 12.9246 88.4119 12.7917 88.7566 12.895C89.1125 13.0017 89.2115 13.2375 89.0932 13.6323L88.573 15.3672L89.3793 15.6089L89.9344 13.7573C90.1678 12.9788 89.8653 12.5123 89.2926 12.3406C88.8088 12.1956 88.4202 12.3396 88.1889 12.5854L88.3273 12.1239L87.521 11.8822L86.649 14.7903Z"
      fill="white"
    />
    <path
      d="M91.0384 17.276C91.9948 17.5628 92.6992 17.2952 92.9632 16.435L93.7668 13.7548L92.9661 13.5147L92.836 13.9484C92.7576 13.6037 92.5357 13.313 92.063 13.1712C91.3401 12.9545 90.609 13.3716 90.359 14.2057L90.3473 14.2447C90.0889 15.1065 90.4921 15.8032 91.1983 16.0149C91.6265 16.1433 92.0479 15.9909 92.2942 15.7556L92.1625 16.1949C92.0258 16.6509 91.7044 16.8333 91.2151 16.6866C90.8036 16.5632 90.6701 16.3414 90.6968 16.0706L89.896 15.8305C89.7938 16.4544 90.0986 16.9942 91.0384 17.276ZM91.6191 15.4805C91.2188 15.3605 91.0252 14.9752 91.1702 14.4914L91.1836 14.4469C91.327 13.9687 91.6739 13.7212 92.1243 13.8563C92.5636 13.988 92.7349 14.3666 92.5865 14.8615L92.5748 14.9004C92.4265 15.3953 92.0417 15.6072 91.6191 15.4805Z"
      fill="white"
    />
    <path
      d="M45.3613 54.9885C45.3613 52.2334 47.5948 50 50.3498 50H74.9681C76.2911 50 77.56 50.5256 78.4955 51.4611L86.5363 59.5019L94.5771 67.5427C95.5127 68.4783 96.0382 69.7471 96.0382 71.0702V111.525C96.0382 114.28 93.8048 116.513 91.0497 116.513H50.3498C47.5948 116.513 45.3613 114.28 45.3613 111.525V54.9885Z"
      fill="#7D8AFF"
    />
    <path
      d="M55.4639 50H61.1979V52.4907C61.1979 52.6986 61.0295 52.867 60.8216 52.867H55.8402C55.6323 52.867 55.4639 52.6986 55.4639 52.4907V50Z"
      fill="white"
    />
    <rect x="61.1982" y="52.8672" width="5.73407" height="2.86704" rx="0.376298" fill="white" />
    <rect x="55.4639" y="55.7305" width="5.73407" height="2.86704" rx="0.376298" fill="white" />
    <rect x="61.1982" y="58.6016" width="5.73407" height="2.86704" rx="0.376298" fill="white" />
    <rect x="55.4639" y="61.4688" width="5.73407" height="2.86704" rx="0.376298" fill="white" />
    <rect x="61.1982" y="64.3359" width="5.73407" height="2.86704" rx="0.376298" fill="white" />
    <rect x="61.1982" y="70.0664" width="5.73407" height="2.86703" rx="0.376298" fill="white" />
    <rect x="55.4639" y="67.2031" width="5.73407" height="2.86704" rx="0.376298" fill="white" />
    <rect x="55.4639" y="72.9375" width="5.73407" height="2.86703" rx="0.376298" fill="white" />
    <rect x="55.4639" y="78.668" width="11.4681" height="14.3352" rx="1.50519" fill="white" />
    <rect x="58.3311" y="81.5352" width="2.86704" height="5.73407" rx="0.752597" fill="#7D8AFF" />
  </svg>
</template>

<script lang="ts">
export default {
  name: 'DropImage',
}
</script>
