import { type ComputedRef, computed, ref } from 'vue'

import {
  type BrainyStoreItems,
  type QueuedStoreGetter,
  type QueuedStoreGroupedGetter,
  type StoreGroupedGetterResult,
  useCurrentUser,
} from '@/hooks'
import type { Model } from '@/stores/plugins/sync-plugin'

import type { BrainyStoreState } from './index'

export const useMakeStoreGetters = <T extends Model>(
  models: ComputedRef<BrainyStoreItems<T>>,
  pending: BrainyStoreItems<T>,
  flushPending: (ids: T['id'][]) => T[],
  onMissing: (id: T['id']) => void
) => {
  const currentUser = useCurrentUser()
  const currentWorkspace = computed(() => currentUser.value?.current_workspace_id)

  function filterableItems<P extends boolean>(options: {
    filter?: (x: T) => boolean
    sort?: (obj1: any, obj2: any) => number
  }): ComputedRef<T[]>
  function filterableItems<P extends boolean>(options: {
    filter?: (x: T) => boolean
    sort?: (obj1: any, obj2: any) => number
    holdChanges?: P
  }): P extends true ? QueuedStoreGetter<T> : ComputedRef<T[]>
  function filterableItems(
    options: {
      filter?: (x: T) => boolean
      sort?: (obj1: any, obj2: any) => number
      holdChanges?: boolean
    } = {}
  ): QueuedStoreGetter<T> | ComputedRef<T[]> {
    const { filter = (x: T) => true, holdChanges = false, sort = undefined } = options

    const workspaceFiltered = computed(() =>
      (Object.values(models.value) as T[]).filter(
        (x: T) => typeof x.workspace_id === 'undefined' || x.workspace_id === currentWorkspace.value
      )
    )
    const filtered = computed(() => workspaceFiltered.value.filter(filter).sort(sort) as T[])
    if (!holdChanges) {
      return filtered
    }

    const result = ref(filtered.value)

    return {
      result: computed(() => result.value.sort(sort)),
      hasChanges: computed(
        () =>
          result.value.filter((r: T) => typeof pending?.[r.id as T['id']] !== 'undefined').length >
          0
      ),
      refresh: () => {
        flushPending(result.value.map((r) => r.id))
        // @ts-ignore
        result.value = filtered.value
      },
    }
  }

  function all(holdChanges: boolean): ReturnType<typeof filterableItems<typeof holdChanges>>
  function all(holdChanges: true): ReturnType<typeof filterableItems<true>>
  function all(holdChanges: false): ReturnType<typeof filterableItems<false>>
  function all(): ReturnType<typeof filterableItems<false>>
  function all(holdChanges = false) {
    return filterableItems({ holdChanges })
  }

  function allMine(holdChanges: boolean): ReturnType<typeof filterableItems<typeof holdChanges>>
  function allMine(holdChanges: true): ReturnType<typeof filterableItems<true>>
  function allMine(holdChanges: false): ReturnType<typeof filterableItems<false>>
  function allMine(): ReturnType<typeof filterableItems<false>>
  function allMine(holdChanges = false) {
    return filterableItems({
      filter: (x: T) => currentUser.value !== null && x.user_id === currentUser.value.id,
      holdChanges,
    })
  }

  function groupedBy(key: keyof T): QueuedStoreGroupedGetter<T>
  function groupedBy<P extends boolean>(
    key: keyof T,
    options: {
      holdChanges?: P
      filter?: (x: T) => boolean
      sort?: (obj1: any, obj2: any) => number
    }
  ): P extends true ? QueuedStoreGroupedGetter<T, false> : StoreGroupedGetterResult<T, false>
  function groupedBy<P extends boolean>(
    key: keyof T,
    options: { multiple?: P; filter?: (x: T) => boolean; sort?: (obj1: any, obj2: any) => number }
  ): StoreGroupedGetterResult<T, P>
  function groupedBy<P extends boolean, M extends boolean>(
    key: keyof T,
    options?: {
      holdChanges?: P
      multiple?: M
      filter?: (x: T) => boolean
      sort?: (obj1: any, obj2: any) => number
    }
  ): P extends true ? QueuedStoreGroupedGetter<T, M> : StoreGroupedGetterResult<T, M>
  function groupedBy(
    key: keyof T,
    options?: {
      holdChanges?: boolean
      multiple?: boolean
      filter?: (x: T) => boolean
      sort?: (obj1: any, obj2: any) => number
    }
  ) {
    const { holdChanges = false, multiple = false, filter = undefined, sort = undefined } = options

    const result = getters.filter({ holdChanges, filter, sort })
    const grouped = computed(() => {
      const items = holdChanges
        ? (result as ReturnType<typeof filterableItems<true>>).result.value
        : (result as ReturnType<typeof filterableItems<false>>).value

      return items.reduce(
        (carry, sub) =>
          Object.assign(carry, {
            [sub[key]]: multiple ? [sub, ...(carry[sub[key]] ?? [])] : sub,
          }),
        {} as Record<T['id'], T[]>
      )
    })

    if (!holdChanges) {
      return grouped
    }

    return {
      result: grouped.value,
      hasChanges: (result as ReturnType<typeof filterableItems<true>>).hasChanges,
      refresh: (result as ReturnType<typeof filterableItems<true>>).refresh,
    }
  }

  const getters = {
    byId: (id: T['id']): T | null => {
      const result = models.value?.[id] || null
      if (!result) {
        onMissing(id)
      }
      return result
    },
    byIds: (id: T['id'][]): T[] => {
      return id.map((i) => models.value?.[id] || null).filter((x) => x !== null) as T[]
    },
    filter: filterableItems,
    $all: all,
    $allMine: allMine,
    $groupedBy: groupedBy,
    all: all(),
    allMine: allMine(),
  }

  return getters
}

export default useMakeStoreGetters
