import type { MaybeRef } from '@vueuse/core'
import { collect } from 'collect.js'
import { type ComputedRef, computed, unref } from 'vue'

import { type Member, type Topic, type User, useDocumentStore, useMemberStore } from '@/stores'

export interface Contributor {
  user_id: string
  user: Member['user']
  count: number
}

interface UseContributorsOptions {
  topic?: MaybeRef<Topic | null>
}

function useContributors({ topic }: UseContributorsOptions = {}): ComputedRef<Contributor[]> {
  const documentStore = useDocumentStore()
  const memberStore = useMemberStore()

  return computed(() => {
    const authorCount = documentStore
      .byTopicId(unref(topic)?.id)
      .flatMap((d) =>
        collect(d.authors.map((a) => a.user_id))
          .push(d.user_id)
          .unique()
          .all()
      )
      .reduce(
        (carry, userId) =>
          Object.assign(carry, {
            [userId]: (carry[userId] || 0) + 1,
          }),
        {} as Record<string, number>
      )

    return Object.keys(authorCount).map((userId) => ({
      user_id: userId,
      user: memberStore.userById(userId) as User,
      count: authorCount[userId],
    }))
  })
}

export default useContributors
