import type { ComputedRef } from 'vue'
import { computed } from 'vue'

import { useAuthStore } from '@/stores'
import type { UserModel } from '@/stores/models/UserModel'

function useCurrentUser(): ComputedRef<UserModel | null> {
  const auth = useAuthStore()

  return computed(() => auth.currentUser)
}

export default useCurrentUser
