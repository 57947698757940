<template>
  <div class="mt-1 relative rounded-md">
    <input
      ref="searchContainer"
      v-model="query"
      class="w-full pr-10 dark:border-none dark:bg-dark-bright rounded-sm border border-button-border placeholder-placeholder focus:placeholder-opacity-75 focus:border-black focus:ring-2 focus:ring-focus-blur dark:bg-transparent dark:placeholder-white dark:placeholder-opacity-40 dark:focus:placeholder-opacity-20 dark:border-dark-button-border dark:focus:border-dark-button-border dark:ring-dark-blurple"
      type="text"
      placeholder="Search for a file..."
      @keydown.escape.prevent.stop="$emit('close')"
    />

    <div
      class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer bg-transparent"
      @click="$emit('close')"
    >
      <BdsIcon name="times" class="h-4 w-4" />
    </div>
  </div>

  <ul
    v-if="results.length > 0"
    ref="resultsContainer"
    class="flex flex-col space-y-2 list-none max-h-[18rem] overflow-auto no-scrollbar -mx-4 -mb-4 mt-0 p-4"
    tabindex="-1"
  >
    <FileItem
      v-for="(item, index) in results"
      :key="item.id"
      :ref="
        (el) => {
          if (results.length > 3 && index === results.length - 3) target = el
        }
      "
      as="li"
      :file="item"
      class="cursor-pointer"
      @click="$emit('select', item)"
    />
  </ul>
</template>

<script lang="ts">
import { BdsIcon } from '@getbrainy/bds-components'
import { useIntersectionObserver } from '@vueuse/core'
import { onMounted, ref, watch } from 'vue'

import { type Asset, useAssetStore } from '@/stores/asset.store'

import FileItem from './FileItem.vue'

export default {
  components: {
    BdsIcon,
    FileItem,
  },

  props: {},

  emits: ['close', 'select'],

  setup(props) {
    const searchContainer = ref(null)
    const resultsContainer = ref(null)
    const query = ref('')
    const results = ref<Asset[]>([])
    const page = ref(1)

    watch(query, () => (page.value = 1))

    const store = useAssetStore()

    const hasMoreResults = ref(false)
    const isFetching = ref(false)

    watch(
      [page, query],
      async (p) => {
        results.value = (
          await store.search({
            q: query.value,
            page: p[0],
            per_page: 6,
          })
        ).hits

        // hasMoreResults.value = r.hits.length > 0
        // results.value = r.page === 1 ? [...r.hits] : [...results.value, ...r.hits]
      },
      { immediate: true }
    )

    const target = ref(null)
    useIntersectionObserver(target, ([{ isIntersecting }], observerElement) => {
      if (isIntersecting && hasMoreResults.value) {
        page.value++
      }
    })

    onMounted(() => {
      searchContainer.value?.focus()
    })

    return {
      query,
      results,
      searchContainer,
      resultsContainer,
      isFetching,
      target,
    }
  },
}
</script>
