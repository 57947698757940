<template>
  <Suspense>
    <div
      v-if="auth.changingWorkspace"
      class="dark:bg-black w-full h-full flex items-center justify-center"
    >
      <div class="space-y-6 flex flex-col items-center">
        <PageLoader />
      </div>
    </div>

    <Main v-else />

    <template #fallback>
      <div class="dark:bg-black w-full h-full flex items-center justify-center">
        <div class="space-y-6 flex flex-col items-center">
          <PageLoader />
        </div>
      </div>
    </template>
  </Suspense>
</template>

<script setup lang="ts">
import PageLoader from '@/components/PageLoader.vue'
import Main from '@/Main.vue'
import { useAuthStore } from '@/stores'

const auth = useAuthStore()
</script>
