import { defineStore } from 'pinia'

import { makeError, makeToast } from '@/helpers'
import { useBrainyStore } from '@/hooks'
import { useDocumentStore } from '@/stores/document.store'
import { useMemberStore } from '@/stores/member.store'
import { PokeModel } from '@/stores/models'

export type Poke = PokeModel

export const usePokeStore = defineStore('pokes', () => {
  const state = useBrainyStore<Poke>('pokes', {
    model: 'PokeDTO',
    makeModel: (attrs) => new PokeModel(attrs),
    relations: ['document', 'user', 'poker'],
    resolveRelation: (name, target) => {
      if (name === 'document') {
        const documentStore = useDocumentStore()
        return documentStore.byId(target.document_id)
      }
      if (name == 'user') {
        const members = useMemberStore()
        return members.userById(target.user_id)
      }
      if (name == 'poker') {
        const members = useMemberStore()
        return members.userById(target.poker_id)
      }
    },
  })

  return {
    ...state,

    create(documentId: string, userId: string) {
      const members = useMemberStore()
      const user = members.userById(userId)

      return state.create(
        {
          document_id: documentId,
          user_id: userId,
        },
        {
          onError: () => makeError(`Could not poke ${user.name}`),
          onCommit: () => makeToast(`Poked ${user.name}`),
        }
      )
    },
  }
})
