// @ts-ignore
import TopicDTO = Brainy.Topic.Contracts.DataTransferObjects.TopicDTO

import router from '@/router'
import { BaseModel } from '@/stores/models/BaseModel'
import { TopicType } from '@/types/enums'

export class TopicModel extends BaseModel<TopicDTO> implements TopicDTO {
  declare color: string | null
  declare emoji: string | null
  declare description: string | null
  declare id: string
  declare name: string
  declare slug: string
  declare type: any
  declare workspace_id: string

  get isAI() {
    return this.get('type') === TopicType.AI
  }

  get urls() {
    return {
      show: router.resolve({ name: 'topics.show', params: { topic: this.get('slug') } }),
    }
  }

  get group() {
    return this.get('name').includes('/') ? this.get('name').split(' / ')[0] : ''
  }

  get label() {
    return this.get('name').split(' / ').slice(-1)[0]
  }
}
