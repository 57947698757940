import { defineStore } from 'pinia'
import { computed, reactive, toRefs } from 'vue'

import { makeSorter } from '@/helpers/sort'

interface SearchHistoryState {
  entries: SearchHistoryEntry[]
}

export interface SearchHistoryEntry {
  term: string
  result: string
  timestamp: number
}

export const useSearchHistoryStore = defineStore(
  'search-history',
  () => {
    const state = reactive<SearchHistoryState>({
      entries: [],
    })

    return {
      ...toRefs(state),

      latest: computed(() => state.entries.sort(makeSorter('-timestamp'))),
      add: (term: string, resultId: string) => {
        const idx = state.entries.findIndex((s) => s.term === term)
        if (idx >= 0) {
          state.entries.splice(idx, 1)
        }

        state.entries.push({
          term,
          result: resultId,
          timestamp: Date.now(),
        })
      },
    }
  },
  { persist: true }
)
