<template>
  <component
    :is="as"
    :class="['ghost', width ? width : '', height ? height : '']"
    :style="styles"
    class="dark:bg-black-600"
  />
</template>

<script lang="ts">
export default {
  name: 'GhostBox',
  props: {
    as: {
      default: 'span',
      type: String,
    },
    maxWidth: {
      // The default maxiumum width is 100%.
      default: 100,
      type: Number,
    },
    minWidth: {
      // Lines have a minimum width of 80%.
      default: 60,
      type: Number,
    },
    height: {
      // Make lines the same height as text.
      default: null,
      type: String,
    },
    width: {
      // Make it possible to define a fixed
      // width instead of using a random one.
      default: null,
      type: String,
    },
    skipSize: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    styles() {
      return this.skipSize || this.width
        ? null
        : {
            // height: this.height,
            width: this.computedWidth,
          }
    },
    computedWidth() {
      // Either use the given fixed width or
      // a random width between the given min
      // and max values.
      return (
        this.width ||
        `${Math.floor(Math.random() * (this.maxWidth - this.minWidth) + this.minWidth)}%`
      )
    },
  },
}
</script>

<style>
.ghost {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  background-color: #eff2f5;
  margin-bottom: 8px;
}

.ghost:not(.rounded-full) {
  border-radius: 4px;
}

.ghost::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.1) 20%,
    rgba(255, 255, 255, 0.2) 60%,
    rgba(255, 255, 255, 0)
  );
  opacity: 0.25;
  animation: shimmer 3s infinite;
  content: '';
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
</style>
