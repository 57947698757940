// @ts-ignore
import AuthorDTO = Brainy.Document.Contracts.DataTransferObjects.AuthorDTO
import { BaseModel } from '@/stores/models/BaseModel'
import type { UserModel } from '@/stores/models/UserModel'

export class AuthorModel extends BaseModel<AuthorDTO> implements AuthorDTO {
  declare document_id: string
  declare first_active_at: string
  declare id: string
  declare last_active_at: string
  declare user_id: string

  declare user: UserModel
}
