// @ts-ignore
import ReaderDTO = Brainy.Document.Contracts.DataTransferObjects.ReaderDTO
import { BaseModel } from '@/stores/models/BaseModel'
import type { DocumentModel } from '@/stores/models/DocumentModel'
import type { UserModel } from '@/stores/models/UserModel'

export class ReaderModel extends BaseModel<ReaderDTO> implements ReaderDTO {
  declare created_at: string
  declare document_id: string
  declare id: string
  declare user_id: string
  declare progress: number
  declare updated_at: string

  declare document: DocumentModel
  declare user: UserModel
}
