import { createConfirmDialog } from 'vuejs-confirm-dialog'

import StatsModal from '@/components/Modals/StatsModal.vue'

interface UseStatsModalOptions {
  onSuccess?: Function
}
function useStatsModal(userId: string, options: UseStatsModalOptions = {}) {
  const { onSuccess = () => {} } = options

  const { reveal, onConfirm } = createConfirmDialog(StatsModal, {
    userId,
  })

  onConfirm(() => onSuccess())

  reveal()
}

export default useStatsModal
