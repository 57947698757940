import type { ComputedRef } from 'vue'
import { computed } from 'vue'

import { useAuthStore } from '@/stores'
import type { UserModel } from '@/stores/models/UserModel'

function useIsBrainy(): boolean {
  const auth = useAuthStore()

  return auth.currentUser?.email?.endsWith('@getbrainy.io') ?? false
}

export default useIsBrainy
