import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { useBrainyStore } from '@/hooks'
import type { Editor } from '@/stores/editor.store'
import { DocumentMetadataModel } from '@/stores/models/DocumentMetadataModel'

export type DocumentMetadata = DocumentMetadataModel

export const useDocumentMetadataStore = defineStore('document-metadata', () => {
  const state = useBrainyStore<DocumentMetadata>('document-metadata', {
    model: 'DocumentMetadataDTO',
    makeModel: (attrs) => new DocumentMetadataModel(attrs),
  })

  const groupedByDocument = state.$groupedBy('document_id', {
    holdChanges: false,
  })

  const fetchHistory = ref([])

  return {
    ...state,
    groupedByDocument,
    byDocumentId: computed(() => {
      return (id: Editor['document_id']) => {
        const doc = groupedByDocument.value?.[id]
        if (!doc && !fetchHistory.value.includes(id)) {
          fetchHistory.value.push(id)
          state.$fetch({
            document_id: id,
          })
        }

        return doc
      }
    }),
  }
})
