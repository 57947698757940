import { defineStore } from 'pinia'

// @ts-ignore
import DocumentDTO = Brainy.Document.Contracts.DataTransferObjects.DocumentDTO
// @ts-ignore
import CreateDocumentData = Brainy.Document.Domain.CreateDocumentData
import Fuse from 'fuse.js'
import { computed, ref, watchEffect } from 'vue'

import { useBrainyStore } from '@/hooks'
import { useAssetStore } from '@/stores/asset.store'
import { useAuthorStore } from '@/stores/author.store'
import { useClapStore } from '@/stores/clap.store'
import { useDocumentMetadataStore } from '@/stores/documentMetadata.store'
import { type DocumentTopic, useDocumentTopicStore } from '@/stores/documentTopic.store'
import { useEditorStore } from '@/stores/editor.store'
import { useMemberStore } from '@/stores/member.store'
import { DocumentModel } from '@/stores/models/DocumentModel'
import { MemberModel } from '@/stores/models/MemberModel'
import { DocumentType } from '@/types/enums'
import type { SearchFilter, SearchResult } from '@/types/shared'

export type Document = DocumentModel

export type DocumentSearchParams = {
  q?: string
  per_page?: number
  page?: number
  filter_by: SearchFilter[]
}

export const useDocumentStore = defineStore('documents', () => {
  const state = useBrainyStore<Document>('documents', {
    version: 1,
    model: 'DocumentDTO',
    makeModel: (attrs) => new DocumentModel(attrs),
    relations: ['editors', 'authors', 'claps', 'creator', 'metadata', 'topics'],
    resolveRelation: (name, target) => {
      if (name === 'metadata' && target.type !== DocumentType.ARTICLE) {
        const metadata = useDocumentMetadataStore()
        return metadata.byDocumentId(target.id) || []
      }
      if (name === 'editors') {
        const editors = useEditorStore()
        return editors.byDocumentId(target.id) || []
      }
      if (name === 'authors') {
        const authors = useAuthorStore()
        return authors.byDocumentId(target.id) || []
      }
      if (name === 'creator') {
        const members = useMemberStore()
        return members.userById(target.user_id)
      }
      if (name === 'claps') {
        const claps = useClapStore()
        return claps.byDocumentId(target.id) || []
      }
      if (name === 'topics') {
        const topics = useDocumentTopicStore()
        return topics.byDocumentId(target.id) || []
      }
      if (name === 'assets') {
        if (!target.get('assets')) {
          return []
        }

        const assets = useAssetStore()
        return computed(
          () =>
            assets.filter({
              filter: (a) => target.get('assets').includes(a.id),
            }).value
        ).value
      }
    },
  })

  const preview = ref(null)

  const groupedByTopic = computed(() => {
    return state.all.value.reduce((carry, document) => {
      Object.values(document.topics).map((dt) =>
        Object.assign(carry, {
          [dt.topic_id]: [document, ...(carry[dt.topic_id] || [])],
        })
      )
      return carry
    }, {} as Record<DocumentTopic['topic_id'], Document[]>)
  })

  const fuse = new Fuse([], {
    keys: [
      { name: 'preview', weight: 0.7 },
      { name: 'title', weight: 1.8 },
    ],
    includeScore: true,
    ignoreLocation: true,
    threshold: 0.6,
  })

  // @ts-ignore
  watchEffect(() => fuse.setCollection(state.all.value))

  return {
    ...state,

    fuseSearch: computed(() => {
      return (query: string) => {
        const res = fuse.search(query)
        return res
          .map((r) => ({
            score: r.score,
            item: r.item as DocumentModel,
          }))
          .filter((r) => r.score <= 0.6)
      }
    }),

    previewDoc: computed(() => (preview.value ? state.byId(preview.value) : null)),
    myDocuments: computed(() => {
      const editors = useEditorStore()
      return editors.allMine.map((e) => state.byId(e.document_id))
    }),
    bySlug: computed(() => {
      return (slug: Document['slug']) => {
        if (typeof slug === 'undefined' || slug == null) {
          return null
        }

        return (
          state.filter({
            filter: (item) => item.slug === slug,
          }).value?.[0] || null
        )
      }
    }),
    groupedByTopic,
    byTopicId: computed(() => {
      return (topicId: Document['topics'][number]) => {
        if (typeof topicId === 'undefined' || topicId == null) {
          return []
        }

        return groupedByTopic.value[topicId] ?? ([] as Document[])
      }
    }),

    // actions
    groupedByDateAndFilter: (filter: (x: DocumentModel) => boolean) =>
      state.$groupedBy('creationDate', {
        multiple: true,
        filter,
      }).value,
    groupedByDate: (topicId?: string, reject = false) =>
      state.$groupedBy('creationDate', {
        multiple: true,
        filter: (d) => {
          if (!topicId) {
            return true
          }

          const hasTopic = d.topics.find((dt) => dt.topic_id === topicId)
          return reject ? !hasTopic : hasTopic
        },
      }).value,
    preview: (docId: string | null) => (preview.value = docId),
    async search(params: DocumentSearchParams): Promise<SearchResult<Document>> {
      const results = state.filter({
        filter: (doc) => {
          return params.q
            ? doc.title?.toLocaleLowerCase()?.includes(params.q?.toLowerCase()) == true
            : true
        },
      })

      return {
        hits: results.value,
        total: results.value.length,
      }
    },
    async create(topics: string[] = []) {
      const payload: CreateDocumentData = {
        title: '',
        type: DocumentType.ARTICLE,
        topics,
      }

      const created = await state.create(payload)

      if (created) {
        this.upsert(created)
      }

      return created
    },
  }
})
