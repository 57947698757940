<template>
  <div
    v-if="loading"
    class="flex items-center justify-center p-6 rounded border border-grey-50 shadow-light bg-white dark:bg-black-800 dark:border-black-700 h-[130px]"
  >
    <BdsSpinner sm />
  </div>

  <a
    v-else-if="title && !isLinkedInProfile && !isPdf"
    :href="link"
    class="flex no-underline rounded border border-grey-50 bg-white dark:bg-black-800 dark:border-black-700 shadow-light overflow-hidden"
    :class="sm ? 'flex-col' : 'px-5 py-4 gap-x-8 items-center justify-between'"
    target="_blank"
  >
    <div
      v-if="sm && image"
      class="flex-shrink-0 h-40 bg-blurple-50 dark:bg-blurple-900 rounded-tl rounded-tr"
    >
      <img
        v-if="showImage"
        class="w-full h-full rounded-tl rounded-tr object-center object-cover"
        :src="fullImage"
        :alt="title"
        loading="lazy"
        @error="showImage = false"
      />
      <div v-else class="h-full flex items-center justify-center">
        <BdsIcon class="h-5 w-5 text-blurple" :name="icon" />
      </div>
    </div>

    <div class="flex flex-col" :class="sm ? 'p-4' : maxWidthClass">
      <BdsText size="f15" medium class="line-clamp-1">{{ title }}</BdsText>
      <BdsText size="f13" class="line-clamp-2">{{ description }}</BdsText>
      <div class="flex items-center space-x-2 mt-4">
        <img
          v-if="openGraphData?.favicon"
          class="w-4 h-4"
          :src="openGraphData.favicon"
          loading="lazy"
        />
        <BdsText size="f13" class="text-grey-500 dark:text-black-300" truncate>
          {{ link }}
        </BdsText>
      </div>
    </div>

    <div
      v-if="!sm"
      class="sm:relative rounded flex-shrink-0 items-end sm:items-center overflow-hidden"
    >
      <img
        v-if="showImage && image"
        class="w-[120px] h-[72px] sm:w-[140px] sm:h-[96px] rounded object-center object-cover"
        :src="fullImage"
        :alt="title"
        loading="lazy"
        @error="showImage = false"
      />
      <div
        v-else
        class="w-[120px] h-[72px] sm:w-[140px] sm:h-[96px] rounded bg-blurple-50 dark:bg-blurple-900 text-blurple flex items-center justify-center"
      >
        <BdsIcon :name="icon" class="w-3.5 h-3.5" />
      </div>
    </div>
  </a>

  <a
    v-else
    :href="url"
    target="_blank"
    class="flex items-center justify-between no-underline px-4 py-3 rounded border border-grey-50 bg-white shadow-light dark:bg-black-800 dark:border-black-700 text-grey-800 dark:text-black-100 overflow-hidden"
  >
    <div class="flex items-center">
      <div
        v-if="isLinkedInProfile"
        class="w-12 h-12 bg-[#0072B1] rounded flex items-center justify-center mr-3"
      >
        <BdsIcon name="In" class="w-4 h-4 text-white" />
      </div>
      <div
        v-else-if="isTwitterLink"
        class="w-12 h-12 bg-[#1D9BF0] rounded flex items-center justify-center mr-3"
      >
        <BdsIcon name="Twitter" class="w-4 h-4 text-white" />
      </div>
      <div
        v-else-if="isPdf"
        class="w-12 h-12 bg-[#FF6388] rounded flex items-center justify-center mr-3"
      >
        <div class="text-f10 text-white font-medium">PDF</div>
      </div>

      <div
        v-else
        class="w-12 h-12 bg-blurple-50 rounded flex items-center justify-center mr-3 dark:bg-blurple-900"
      >
        <BdsIcon :name="icon" class="w-4 h-4 text-blurple-500" />
      </div>

      <div class="flex max-w-[250px] sm:max-w-full">
        <div class="flex flex-col space-y-0.5">
          <BdsText
            size="f13"
            class="text-grey dark:text-black-100 pr-4 mt-px"
            :class="{ 'max-w-[250px]': sm }"
            truncate
          >
            {{ isLinkedInProfile ? linkedInUsername : url }}
          </BdsText>
          <BdsText size="xs" class="text-grey-500 dark:text-black-300" leading-none>
            {{ isLinkedInProfile ? 'View profile' : 'View source ' }}
          </BdsText>
        </div>
      </div>
    </div>

    <div v-if="!sm">
      <BdsIcon name="External" class="w-3.5 h-3.5" />
    </div>
  </a>
</template>

<script setup lang="ts">
import { BdsIcon, BdsSpinner, BdsText } from '@getbrainy/bds-components'
import { computed, onMounted, ref } from 'vue'

import useOpenGraph from '@/hooks/useOpenGraph'

const props = defineProps({
  url: { type: String, required: true },
  document: { type: Object, default: () => {} },
  sm: { type: Boolean, default: false },
  icon: { type: String, default: 'Link' },
  maxWidthClass: { type: String, default: 'max-w-md' },
})

const isLinkedInProfile = computed(
  () =>
    props.url?.startsWith('https://www.linkedin.com/in/') ||
    props.url?.startsWith('https://www.linkedin.com/company/')
)
const isTwitterLink = computed(() => props.url?.startsWith('https://twitter.com/'))
const linkedInUsername = computed(() => {
  return isLinkedInProfile.value
    ? new RegExp(
        '(?:https?:)?\/\/(?:[\\w]+\.)?linkedin\.com\/(company|in)\/([\\w\-\_À-ÿ%]+)\/?'
      ).exec(props.url)?.[2]
    : ''
})

const title = computed(() => openGraphData.value?.title || props.document?.title)
const description = computed(() => openGraphData.value?.description || props.document?.preview)
const link = computed(() => props.url.replace(/\/+$/, ''))
const image = computed(() => openGraphData.value?.image || props.document?.assets?.[0]?.src)
const fullImage = computed(() => {
  if (image.value.startsWith('http')) {
    return image.value
  }

  return `${link.value}${image.value}`
})
const showImage = ref(true)

const { data: openGraphData, loading, fetchData } = useOpenGraph()

const isPdf = computed(() => link.value.endsWith('.pdf'))

onMounted(() => {
  if (isLinkedInProfile.value || isPdf.value) return

  fetchData(props.url)
})
</script>
