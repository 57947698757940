<template>
  <BdsModal :show="open" md :z-index="1000" @close="reset" @keydown.enter.meta.prevent="save">
    <template v-if="form.url || imageUploads.length">
      <DocumentEmbed
        v-if="embed !== null && embedUrl"
        :url="embedUrl"
        :provider="embed"
        class="mb-5"
      />
      <DocumentLink
        v-else-if="imageUploads.length === 0"
        class="mb-5"
        max-width-class="max-w-[250px]"
        :url="form.url"
        :sm="form.url.endsWith('.pdf')"
      />
      <BdsStack v-else class="flex items-center gap-sm mb-5">
        <ImageThumb
          v-for="(image, idx) in imageUploads"
          :key="`image-${idx}`"
          :file="image"
          @upload="onImageUpload($event, image)"
          @remove="onImageRemove($event, image)"
        />
      </BdsStack>

      <BdsInput
        id="composer-modal-input"
        v-model="form.reason"
        type="textarea"
        transparent
        placeholder="Why are you saving this..."
        rows="4"
        autofocus
      />

      <BdsStack class="mt-3" items-center wrap gap-xs>
        <template v-for="selectedTopic in selectedTopics" :key="selectedTopic.id">
          <BdsCollection
            v-if="selectedTopic.emoji"
            :topic="selectedTopic"
            @click="onRemove(selectedTopic)"
          />
          <BdsTopic v-else @remove="onRemove(selectedTopic)">
            {{ selectedTopic.label }}
          </BdsTopic>
        </template>

        <TopicSelector
          v-model="form.topics"
          position="top"
          :class="form.topics.length ? '' : '!-ml-2'"
          @created="(t) => form.topics.push(t.id)"
        >
          <template #trigger="{ open }">
            <BdsButton transparent xs class="!h-[1.75rem]" @click="open">
              <BdsText size="f13" class="text-grey-400">+ Add to collections</BdsText>
            </BdsButton>
          </template>
        </TopicSelector>
      </BdsStack>
    </template>

    <EmptyState v-else class="py-12" icon="Plus">
      Paste your link or image(s) to start capturing.

      <div class="my-8 text-grey-500 dark:text-black-300 text-f13">or</div>

      <BdsButton full @click="createNewDocument()">
        <svg
          class="w-3.5 h-3.5 mr-1.5"
          viewBox="0 0 186 300"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M57.5154 151.57C56.1103 155.683 60.0302 159.648 64.0135 157.908C88.4869 147.215 104.759 131.863 115.355 115.858C127.025 98.2306 131.965 79.5297 133.144 64.8941C133.795 56.8093 131.739 48.7263 127.355 41.9025C113.422 20.2186 90.49 4.76718 70.164 0.0870902C67.8751 -0.439937 65.8621 1.50217 66.178 3.82965C66.6304 7.16341 67.1477 10.6308 67.6868 14.2438C72.4301 46.0353 78.855 89.0982 57.5154 151.57Z"
            fill="currentColor"
          />
          <path
            d="M152.566 52.654C149.26 56.2 147.725 60.9086 147.35 65.7424C146.039 82.6705 140.388 103.81 127.214 123.71C113.528 144.382 91.8889 163.46 59.1616 175.109C51.4007 177.871 44.8179 183.405 41.5249 190.956L0.308527 285.47C0.0155206 286.142 -0.0713547 286.886 0.0589129 287.607L2.022 298.477C2.31026 300.073 4.36672 300.552 5.3309 299.248L29.7745 266.18C37.6829 255.482 48.9637 247.758 61.7982 244.255L95.9452 234.935C150.453 217.819 174.584 189.633 185.828 154.642C186.741 151.802 183.823 149.228 180.973 150.109C171.754 152.96 162.232 153.884 153.847 154.697C146.802 155.38 140.559 155.985 135.974 157.591C166.873 140.018 190.313 106.694 182.655 61.1074C180.604 48.8948 176.575 42.3498 171.784 34.7976C170.209 32.3146 166.377 32.6406 165.064 35.2714C160.788 43.8365 156.442 48.4981 152.566 52.654Z"
            fill="currentColor"
          />
        </svg>

        Jot down ideas
      </BdsButton>
    </EmptyState>

    <template v-if="form.url || form.images.length" #footer>
      <BdsStack items-center gap-xs>
        <BdsProgressCircle
          :percent="reasonProgress > 100 ? 100 : reasonProgress"
          :stroke="1.5"
          :radius="12"
          base-color-class="text-grey-100 dark:text-black-600"
          :color-class="reasonProgress > 100 ? 'text-red' : 'text-blurple'"
        />
        <BdsText
          size="f13"
          :class="reasonProgress > 100 ? 'text-red' : 'text-grey-300 dark:text-black-300'"
        >
          {{ 250 - form.reason.length }}
        </BdsText>
      </BdsStack>

      <BdsSelectSearch
        v-if="false"
        v-model="form.pokes"
        position="top"
        :items="memberStore.userSearch"
        placeholder="Search..."
        align="left"
        multiple
        clear-on-select
        clearable
      >
        <template #trigger="{ open }">
          <BdsButton xs transparent grey class="!-ml-2" @click="open">
            <BdsIcon name="Poke" class="w-4 h-4 mr-2" />
            Poke
          </BdsButton>
        </template>
      </BdsSelectSearch>

      <BdsButton
        sm
        primary
        :disabled="
          !form.url.length && !form.images.length && form.images.length === imageUploads.length
        "
        :loading="processing"
        @click="save"
      >
        Save
      </BdsButton>
    </template>
  </BdsModal>
</template>

<script setup lang="ts">
import {
  BdsButton,
  BdsIcon,
  BdsInput,
  BdsModal,
  BdsProgressCircle,
  BdsSelectSearch,
  BdsStack,
  BdsText,
  BdsTopic,
} from '@getbrainy/bds-components'
import { figma, vimeo, youtube } from '@getbrainy/extension-embed'
import { useEventListener } from '@vueuse/core'
import { computed, reactive, ref, watch, watchEffect } from 'vue'
import { useRoute } from 'vue-router'

import BdsCollection from '@/components/BdsCollection.vue'
import EmptyState from '@/components/EmptyState.vue'
import ImageThumb from '@/components/ImageThumb.vue'
import TopicSelector from '@/components/TopicSelector.vue'
import { makeError, makeToast, notUsingInput } from '@/helpers'
import { useCreateDocument } from '@/hooks'
import useOpenGraph from '@/hooks/useOpenGraph'
import DocumentEmbed from '@/modules/documents/components/DocumentEmbed.vue'
import DocumentLink from '@/modules/documents/components/DocumentLink.vue'
import {
  github,
  loom,
  spotify,
  twitter,
} from '@/modules/documents/components/Editor/TipTap/Embed/Extension'
import Analytics from '@/services/Analytics'
import Http from '@/services/Http'
import { Asset, Topic, useMemberStore, useTopicStore } from '@/stores'
import { useSourceMetadataStore } from '@/stores/sourceMetadata.store'

const props = defineProps({
  show: { type: Boolean, default: false },
})

const emit = defineEmits(['close'])

const open = ref(false)

watchEffect(() => {
  open.value = props.show
})

const form = reactive({
  title: '',
  url: '',
  reason: '',
  content_type: 'bookmark',
  topics: [],
  pokes: [],
  images: [],
})

const memberStore = useMemberStore()

const topicStore = useTopicStore()
const selectedTopics = computed(() => form.topics.map((topicId) => topicStore.byId(topicId)))

const route = useRoute()
const topic = computed(() => {
  return route.params?.topic ? topicStore.bySlug(route.params.topic) : null
})

watch(
  topic,
  (v) => {
    if (v) {
      form.topics = [topic.value.id]
    } else {
      form.topics = []
    }
  },
  { immediate: true }
)

const onRemove = (topic: Topic) => {
  const idx = form.topics.findIndex((t) => t === topic.id)
  if (idx !== -1) {
    form.topics.splice(idx, 1)
  }
}

const sources = useSourceMetadataStore()
const metadata = computed(() => (form.url ? sources.byUrl(form.url) : null))

watchEffect(() => {
  if (!metadata.value?.type) return

  if (metadata.value?.title) {
    form.title = metadata.value.title
  }

  if (['object', 'website'].includes(metadata.value.type)) {
    form.content_type = 'bookmark'
  } else if (['article'].includes(metadata.value.type)) {
    form.content_type = 'article'
  } else if (
    ['video', 'video.movie', 'video.episode', 'video.tv_show', 'video.other'].includes(
      metadata.value.type
    )
  ) {
    form.content_type = 'video'
  }
})

useEventListener(document, 'paste', onPaste)

// embeds
const embed = ref(null)
const embedUrl = ref('')
const embedTypes = [loom, figma, youtube, vimeo, github, twitter, spotify]

const imageUploads = ref([])

const { fetchData } = useOpenGraph()

function onPaste(e: ClipboardEvent) {
  if (!notUsingInput.value && document.activeElement.id !== 'composer-modal-input') return

  if (form.url.length === 0 && form.images.length === 0) {
    e.preventDefault()
  }

  if (!open.value) {
    open.value = true
  }

  const text = (e.clipboardData || e.originalEvent.clipboardData)?.getData('text') || ''
  const isUrl = text.match(`^https?://(?!localhost).*`)

  if (isUrl) {
    handleEmbedsForUrl(text)

    Analytics.track('Composer Modal Paste', {
      url: text,
      already_open: !open.value,
      is_url: true,
      is_embed: !!embed.value,
      embed_name: embed.value,
      has_files: false,
    })

    form.url = text
    return
  }

  const items: DataTransferItemList = (e.clipboardData || e.originalEvent.clipboardData)
    .items as DataTransferItemList

  new Array(...items).forEach((item: DataTransferItem) => {
    const file = item.getAsFile()

    if (!file || !['image/jpeg', 'image/png', 'image/gif', 'image/webp'].includes(file.type)) {
      return
    }

    imageUploads.value.push(file)
  })

  Analytics.track('Composer Modal Paste', {
    already_open: !open.value,
    is_url: false,
    is_embed: !!embed.value,
    embed_name: embed.value,
    has_files: items.length > 0,
  })
}

function handleEmbedsForUrl(url: string) {
  embedTypes.forEach((embedType) => {
    if (new RegExp(embedType.regExp).test(url)) {
      if (!['twitter', 'github'].includes(embedType.name)) {
        embed.value = embedType.name
        embedUrl.value = embedType.formatUrl(
          embedType.getId(new RegExp(embedType.regExp).exec(url))
        )
      }

      if (['figma', 'twitter', 'github', 'spotify'].includes(embedType.name)) {
        form.content_type = 'article'
      } else if (['vimeo', 'youtube', 'loom'].includes(embedType.name)) {
        form.content_type = 'video'
      }

      if (['vimeo', 'youtube', 'spotify'].includes(embedType.name)) {
        fetchData(url)
      }
    }
  })
}

function onImageUpload(asset: Asset, file: File) {
  form.images.push(asset.src)
}

function onImageRemove(asset: Asset, file: File) {
  const idx = form.images.indexOf(asset.src)
  idx !== -1 ? form.images.splice(idx, 1) : null

  const imageIdx = imageUploads.value.findIndex((im) => im === file)
  if (imageIdx !== -1) {
    imageUploads.value.splice(imageIdx, 1)

    Analytics.track('Composer Modal Remove Image')
  }
}

function reset() {
  open.value = false
  emit('close')
  setTimeout(() => {
    form.title = ''
    form.url = ''
    form.reason = ''
    form.content_type = 'bookmark'
    form.topics = []
    form.pokes = []
    form.images = []

    imageUploads.value = []

    embed.value = null
    embedUrl.value = ''
  }, 250)
}

const processing = ref(false)
async function save() {
  if (processing.value) return

  try {
    processing.value = true

    await Http.post(`webclip`, {
      ...form,
      content_type: form.images.length ? 'image' : form.content_type,
      url: form.url.length ? form.url : null,
    })

    Analytics.track('Composer Modal Submitted', {
      url: form.url,
      has_reason: form.reason.length > 0,
      has_title: form.title.length > 0,
      is_embed: !!embed.value,
      embed_name: embed.value,
      has_images: form.images.length > 0,
      topic_count: form.topics.length,
      content_type: form.content_type,
    })

    makeToast('Captured!')
    reset()
  } catch (e) {
    makeError('Something went wrong. Try again')
  } finally {
    processing.value = false
  }
}

const reasonProgress = computed(() => (form.reason.length / 250) * 100)

const createDocument = useCreateDocument()
const createNewDocument = () => {
  createDocument()
  emit('close')
}
</script>
