// @ts-ignore
import UserDTO = Brainy.Auth.Contracts.DataTransferObjects.UserDTO
import { BaseModel } from '@/stores/models/BaseModel'
import OAuthProviderDTO = Brainy.Auth.Contracts.DataTransferObjects.OAuthProviderDTO

import useActiveUsers from '@/hooks/useActiveUsers'
import router from '@/router'

export class UserModel extends BaseModel<UserDTO> implements UserDTO {
  declare avatar_asset_id: string | null
  declare avatar_url: string | null
  declare created_at: string
  declare current_workspace_id: string | null
  declare email: string
  declare id: string
  declare initials: string
  declare username: string
  declare name: string
  declare updated_at: string
  declare verified: boolean
  declare oauth_providers: OAuthProviderDTO[]

  hasActiveProvider(provider: string): boolean {
    return this.oauth_providers?.data?.some((p) => p.provider === provider && p.is_active) || false
  }

  hasProvider(provider: string | string[]): boolean {
    return (
      this.oauth_providers?.data?.some((p) =>
        Array.isArray(provider) ? provider.includes(p.provider) : p.provider === provider
      ) || false
    )
  }

  get active(): boolean {
    return typeof useActiveUsers().users[this.get('id')] !== 'undefined'
  }

  get urls() {
    return {
      show: router.resolve({
        name: 'profiles.show',
        params: { username: this.get('username') || this.get('id') },
      }),
    }
  }
}
