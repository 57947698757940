// @ts-ignore
import MemberDTO = Brainy.Workspace.Contracts.DataTransferObjects.MemberDTO
import { BaseModel } from '@/stores/models/BaseModel'
import type { UserModel } from '@/stores/models/UserModel'
import { Role } from '@/types/enums'

export class MemberModel extends BaseModel<MemberDTO> implements MemberDTO {
  declare created_at: string
  declare id: string
  declare personal_site: string | null
  declare role: any
  declare suspended_at: string | null
  declare updated_at: string
  declare user_id: string
  declare work_title: string | null
  declare workspace_id: string

  declare user: UserModel

  get isWorkspaceOwner() {
    return this.get('role') === Role.WORKSPACE_OWNER
  }

  get isAdmin() {
    return this.get('role') === Role.ADMIN
  }
}
