import { createConfirmDialog } from 'vuejs-confirm-dialog'

import { copyTextToClipboard } from '@/helpers'
import ContributorsDialog from '@/modules/topics/components/Modals/ContributorsDialog.vue'
import EditTopicDialog from '@/modules/topics/components/Modals/EditTopicDialog.vue'
import FollowersDialog from '@/modules/topics/components/Modals/FollowersDialog.vue'
import type { Topic } from '@/stores'

export interface UseEditTopicOptions {
  onSuccess?: Function
  noRedirect?: boolean
}

function useEditTopic(topic?: Topic, options: UseEditTopicOptions = {}): void {
  const { onSuccess = () => {}, noRedirect = false } = options

  const { reveal, onConfirm } = createConfirmDialog(EditTopicDialog, {
    topic: topic,
    noRedirect,
  })

  onConfirm(() => onSuccess())

  reveal()
}

export function useCopyTopicLink(topic: Topic): void {
  const route = topic.urls.show
  if (!route) return

  copyTextToClipboard(new URL(route.href, window.location.origin).href)
}

export function useShowTopicFollowers(topic: Topic): void {
  const { reveal, onConfirm } = createConfirmDialog(FollowersDialog, {
    topic: topic,
  })

  onConfirm(() => {})

  reveal()
}

export function useShowTopicContributors(topic: Topic): void {
  const { reveal, onConfirm } = createConfirmDialog(ContributorsDialog, {
    topic: topic,
  })

  onConfirm(() => {})

  reveal()
}

export default useEditTopic
