<template>
  <div v-if="currentUser" class="h-screen w-full flex overflow-hidden">
    <Sidebar @compose="modals.compose = true" @search="modals.search = true" />

    <router-view />

    <SearchModal
      :show="modals.search"
      @close="modals.search = false"
      @open="modals.search = true"
    />
    <ComposerModal :show="modals.compose" @close="modals.compose = false" />
  </div>
</template>
<script setup lang="ts">
import { type ComputedRef, inject, reactive } from 'vue'
import { useRouter } from 'vue-router'

import ComposerModal from '@/components/Modals/ComposerModal.vue'
import SearchModal from '@/components/Modals/SearchModal.vue'
import Sidebar from '@/components/Sidebar.vue'
import { notUsingInput } from '@/helpers'
import { useCreateDocument } from '@/hooks'
import useMagicSequence from '@/hooks/useMagicSequence'
import type { UserModel } from '@/stores/models/UserModel'
import { WorkspaceModel } from '@/stores/models/WorkspaceModel'

const modals = reactive({
  search: false,
  compose: false,
})

const currentUser = inject('user') as ComputedRef<UserModel>
const currentWorkspace = inject('workspace') as ComputedRef<WorkspaceModel>

const createDocument = useCreateDocument()

const router = useRouter()
const shortcutMap = [
  {
    keys: ['g', 'i'],
    perform: () => router.push({ name: 'inbox' }),
    disabled: currentWorkspace.value?.isPersonal,
  },
  {
    keys: ['g', 's'],
    perform: () => router.push({ name: 'settings.workspace.general' }),
  },
  {
    keys: ['g', 'c'],
    perform: () => router.push({ name: 'catch-up' }),
    disabled: currentWorkspace.value?.isPersonal,
  },
  {
    keys: ['g', 'e'],
    perform: () => router.push({ name: 'explore' }),
  },
  {
    keys: ['g', 'p'],
    perform: () => router.push(currentUser.value.urls.show),
    disabled: currentWorkspace.value?.isPersonal,
  },
  {
    keys: ['g', 't'],
    perform: () => router.push({ name: 'topics.index' }),
  },
  {
    keys: ['c'],
    perform: (e: KeyboardEvent) => {
      e.preventDefault()
      modals.compose = !modals.compose
    },
  },
  {
    keys: ['d'],
    perform: () => createDocument(),
    disabled: currentWorkspace.value?.isPersonal,
  },
]

shortcutMap
  .filter((s) => !s?.disabled)
  .forEach((shortcut) => {
    useMagicSequence(shortcut.keys, (e: KeyboardEvent) => {
      const allowsInput = shortcut?.allowsInput || false
      if (!allowsInput && !notUsingInput.value) return
      shortcut.perform(e)
    })
  })
</script>
