<template>
  <BdsModal show title="All contributors" height="27.5rem" md @close="$emit('cancel')">
    <BdsStack v-if="topic && contributors.length" direction-column gap-custom=".75rem">
      <BdsStack
        v-for="contributor in contributors"
        :key="contributor.user_id"
        items-center
        justify-between
      >
        <BdsStack items-center gap-sm>
          <RenderlessUserAvatar :user-id="contributor.user_id" sm no-indicator />
          <BdsText size="f13">{{ contributor.user.name }}</BdsText>
        </BdsStack>
        <BdsBadge primary>{{ contributor.count }}</BdsBadge>
      </BdsStack>
    </BdsStack>

    <EmptyState v-else icon="Hashtag" class="h-full">
      No one has contributed to this topic yet.
    </EmptyState>
  </BdsModal>
</template>

<script setup lang="ts">
import { BdsBadge, BdsModal, BdsStack, BdsText } from '@getbrainy/bds-components'

import EmptyState from '@/components/EmptyState.vue'
import RenderlessUserAvatar from '@/components/RenderlessUserAvatar.vue'
import useContributors from '@/hooks/useContributors'
import { type Topic } from '@/stores'

interface Props {
  topic: Topic
}

const props = defineProps<Props>()

defineEmits(['cancel'])

const contributors = useContributors({ topic: props.topic })
</script>
