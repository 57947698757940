import { useSessionStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { type Ref, ref } from 'vue'

export const useSourceMetadataStore = defineStore(
  'source-metadata',
  () => {
    const items = useSessionStorage('source-metadata', {})

    function addMetadata(url: string, data: any) {
      items.value[url] = data
    }

    return {
      items,
      byUrl: (url: string) => {
        return items.value?.[url] || null
      },
      addMetadata,
      clear: () => (items.value = {}),
    }
  },
  {
    persist: {
      paths: ['items'],
    },
  }
)
