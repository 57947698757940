import type { Model } from '@/stores/plugins/sync-plugin'

type AttributeMap<T> = {
  [K in keyof T]: T[K]
}

export class BaseModel<T extends Model = Model> {
  private attributes: AttributeMap<T> = {} as AttributeMap<T>

  constructor(attrs: AttributeMap<T>) {
    this.attributes = attrs

    return new Proxy(this, {
      get(target, name, receiver) {
        if (Reflect.has(target, name)) {
          return Reflect.get(target, name)
        }

        return target.get(name.toString())
      },
    })
  }

  toArray() {
    return this.attributes
  }

  setAttributes(obj: Partial<AttributeMap<T>>) {
    Object.keys(obj).forEach((key: keyof T) => this.set(key, obj[key] as any))
  }

  set<K extends keyof T>(key: K, value: T[K]): void {
    this.attributes[key] = value
  }

  get<K extends keyof T>(key: K): T[K] {
    return this.attributes[key]
  }
}
