import { defineStore } from 'pinia'

import { useAuthStore } from '@/stores/auth.store'
// @ts-ignore
import WorkspaceDTO = Brainy.Workspace.Contracts.DataTransferObjects.WorkspaceDTO
import { computed, ref } from 'vue'

import { useBrainyStore } from '@/hooks'
import { WorkspaceModel } from '@/stores/models/WorkspaceModel'

export type Workspace = WorkspaceModel & {}

export const useWorkspaceStore = defineStore(
  'workspaces',
  () => {
    const state = useBrainyStore<Workspace>('workspaces', {
      model: 'WorkspaceDTO',
      makeModel: (attrs) => new WorkspaceModel(attrs),
    })
    const lastWorkspace = ref<WorkspaceDTO | null>(null)

    const auth = useAuthStore()
    const currentWorkspace = computed(() => auth.currentWorkspace)
    const current = computed(() => {
      return state.byId(currentWorkspace.value)
    })

    return {
      ...state,
      lastWorkspace,
      currentWorkspace,
      current,

      async updateWorkspaceInformation(industry: string, size: string, role: string) {
        let workspaceId = lastWorkspace.value?.id
        if (!workspaceId) {
          const auth = useAuthStore()
          workspaceId = auth.currentWorkspace
        }

        if (!workspaceId) {
          return
        }

        return await state.update(workspaceId, {
          industry,
          size,
          role,
        })
      },
      async create(type: number, name: string, slug?: string) {
        lastWorkspace.value = (await state.create({
          type,
          name,
          slug,
        })) as WorkspaceDTO

        return lastWorkspace.value
      },
    }
  },
  {
    persist: {
      paths: ['lastWorkspace'],
    },
  }
)
