<template>
  <p class="whitespace-pre-wrap text-sm" v-html="parsedTweet"></p>
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface TweetUrl {
  url: string
  expanded_url: string
  display_url: string
}
interface Tweet {
  text: string
  urls: TweetUrl[]
}

const props = defineProps<{
  tweet: Tweet
}>()

const parsedTweet = computed(() => parseTweet(props.tweet))

const parseTweet = (tweet: Tweet) => {
  if (!tweet.urls.length) {
    return tweet.text
  }

  let text = tweet.text
  let replacer = (u: TweetUrl) =>
    `<a href="${u.expanded_url}"
            class="whitespace-normal"
            target="_blank"
            rel="noreferrer noopener nofollow"
        >
            ${u.display_url}
        </a>`

  tweet.urls.forEach((u) => (text = text.replace(u.url, replacer(u))))

  return text
}
</script>
