// @ts-ignore
import CommentDTO = Brainy.Comment.Contracts.DataTransferObjects.CommentDTO
import { BaseModel } from '@/stores/models/BaseModel'
import { UserModel } from '@/stores/models/UserModel'

export class CommentModel extends BaseModel<CommentDTO> implements CommentDTO {
  declare id: string
  declare entity_id: string
  declare user_id: string
  declare workspace_id: string
  declare parent_id: string
  declare text: string

  declare created_at: string
  declare updated_at: string

  declare user: UserModel
}
