import { BaseModel } from '@/stores/models/BaseModel'
// @ts-ignore
import DocumentMetadataDTO = Brainy.Document.Contracts.DataTransferObjects.DocumentMetadataDTO

export class DocumentMetadataModel
  extends BaseModel<DocumentMetadataDTO>
  implements DocumentMetadataDTO
{
  declare created_at: string
  declare description: string | null
  declare document_id: string
  declare id: string
  declare link: string
  declare status: any
  declare updated_at: string
}
