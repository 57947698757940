<template>
  <div
    class="relative group"
    :class="{
      '-mx-20': size === 'large',
      '-mx-48': size === 'full',
    }"
  >
    <div
      v-show="show"
      class="h-full w-full items-center justify-center absolute group-hover:flex hidden z-10"
    >
      <div class="h-full w-full bg-white dark:bg-black opacity-80 dark:opacity-60"></div>
      <div class="bg-black-800 border border-black-700 absolute flex rounded">
        <BubbleMenuButton
          v-if="!hideOriginalBtn"
          v-tippy="{ content: originalBtnText, placement: 'top' }"
          class="hover:bg-black-700 hover:rounded text-white"
          icon="SizeOriginal"
          @click="$emit('size', 'original')"
        />
        <BubbleMenuButton
          v-if="!hideDefaultBtn"
          v-tippy="{ content: defaultBtnText, placement: 'top' }"
          class="hover:bg-black-700 hover:rounded text-white"
          icon="SizeDefault"
          @click="$emit('size', 'standard')"
        />
        <BubbleMenuButton
          v-if="!hideLargeBtn"
          v-tippy="{ content: largeBtnText, placement: 'top' }"
          class="hover:bg-black-700 hover:rounded text-white"
          icon="SizeMedium"
          @click="$emit('size', 'large')"
        />
        <BubbleMenuButton
          v-if="!hideFullBtn"
          v-tippy="{ content: fullBtnText, placement: 'top' }"
          class="hover:bg-black-700 hover:rounded text-white"
          icon="SizeWide"
          @click="$emit('size', 'full')"
        />
        <BubbleMenuButton
          v-if="!hideChangeBtn"
          v-tippy="{ content: changeBtnText, placement: 'top' }"
          class="hover:bg-black-700 hover:rounded text-white"
          icon="Refresh"
          @click="$emit('change')"
        />
        <BubbleMenuButton
          v-if="!hideRemoveBtn"
          v-tippy="{ content: removeBtnText, placement: 'top' }"
          class="hover:bg-black-700 hover:rounded text-white"
          icon="Trash"
          @click="$emit('remove')"
        />
        <div
          v-if="showArrow"
          class="bg-black-800 h-5 w-5 rotate-45 absolute bottom-[-4px] rounded-br left-0 right-0 mx-auto z-0 dark:bg-[#2E2E3A]"
        ></div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import BubbleMenuButton from '@/modules/documents/components/Editor/BubbleMenuButton.vue'

export default {
  components: {
    BubbleMenuButton,
  },

  props: {
    show: Boolean,
    size: String,
    hideOriginalBtn: { type: Boolean, default: false },
    hideDefaultBtn: { type: Boolean, default: false },
    hideLargeBtn: { type: Boolean, default: false },
    hideFullBtn: { type: Boolean, default: false },
    hideChangeBtn: { type: Boolean, default: false },
    hideRemoveBtn: { type: Boolean, default: false },
    originalBtnText: { type: String, default: 'Original size' },
    defaultBtnText: { type: String, default: 'Standard' },
    largeBtnText: { type: String, default: 'Medium' },
    fullBtnText: { type: String, default: 'Wide' },
    changeBtnText: { type: String, default: 'Change' },
    removeBtnText: { type: String, default: 'Remove' },
    showArrow: { type: Boolean, default: false },
  },

  emits: ['change', 'remove', 'size'],
}
</script>
