<template>
  <BdsAvatar
    :initials="workspace?.initials"
    :src="avatar?.src || workspace?.logo_url"
    :square="square"
    no-indicator
  />
</template>

<script setup lang="ts">
import { BdsAvatar } from '@getbrainy/bds-components'
import { computed } from 'vue'

import { useWorkspaceStore } from '@/stores'
import { useAssetStore } from '@/stores/asset.store'

const props = defineProps({
  workspaceId: { type: String, required: true },
  square: { type: Boolean, default: true },
})

const workspaceStore = useWorkspaceStore()
const workspace = computed(() => workspaceStore.byId(props.workspaceId))

const assets = useAssetStore()
const avatar = computed(() =>
  workspace.value?.logo_asset_id ? assets.byId(<string>workspace.value.logo_asset_id) : null
)
</script>
