// @ts-ignore
import DocumentDTO = Brainy.Document.Contracts.DataTransferObjects.DocumentDTO

import * as Sentry from '@sentry/vue'
import { type ComputedRef, computed } from 'vue'

import { useCurrentUser } from '@/hooks'
import router from '@/router'
import { useAssetStore } from '@/stores'
import { useAuthorStore } from '@/stores/author.store'
import { useClapStore } from '@/stores/clap.store'
import { useEditorStore } from '@/stores/editor.store'
import { AssetModel } from '@/stores/models/AssetModel'
import type { AuthorModel } from '@/stores/models/AuthorModel'
import { BaseModel } from '@/stores/models/BaseModel'
import type { ClapModel } from '@/stores/models/ClapModel'
import type { DocumentMetadataModel } from '@/stores/models/DocumentMetadataModel'
import { DocumentTopicModel } from '@/stores/models/DocumentTopicModel'
import type { EditorModel } from '@/stores/models/EditorModel'
import type { UserModel } from '@/stores/models/UserModel'
import { useReaderStore } from '@/stores/reader.store'
import { DocumentType } from '@/types/enums'

export class DocumentModel extends BaseModel<DocumentDTO> implements DocumentDTO {
  declare cover: string | null
  declare id: string
  declare slug: string | null
  declare title: string | null
  declare type: DocumentType
  declare created_at: string
  declare updated_at: string
  declare user_id: string
  declare preview: string | null
  declare summary: string | null
  declare word_count: number

  declare creator: UserModel
  declare editors: EditorModel[]
  declare authors: AuthorModel[]
  declare claps: ClapModel[]
  declare metadata: DocumentMetadataModel
  declare topics: DocumentTopicModel[]
  // declare assets: AssetModel[]

  get creationDate() {
    return this.get('created_at').split('T')[0]
  }

  get assets() {
    const assetIds = this.get('assets')
    if (!assetIds) {
      return []
    }

    const assets = useAssetStore()
    return computed(() => assets.byIds(assetIds)).value
  }

  hasAuthor(userId: string) {
    if (!this.authors) {
      const authors = useAuthorStore()
      return !!authors.byDocumentId(this.get('id'))
    }

    if (this.get('user_id') === userId) {
      return true
    }

    return this.authors.some((a) => a.user_id === userId)
  }

  hasEditor(userId: string) {
    if (this.get('user_id') == userId) {
      return true
    }

    const editors = useEditorStore()
    return computed(() => {
      const docEditors = editors.byDocumentId(this.get('id'))

      return !!docEditors?.some((e) => e.user_id === userId)
    }).value
  }

  addEditor(userId: string) {
    const editorStore = useEditorStore()

    editorStore.create({
      document_id: this.id,
      user_id: userId,
    })
  }

  get clapCount(): number {
    const claps = useClapStore()
    return claps.documentClapCount(this.get('id'))
  }

  get readCount(): number {
    const readerStore = useReaderStore()
    return computed(
      () => readerStore.byDocumentId(this.get('id'))?.filter((r) => r.progress === 100)?.length || 0
    ).value
  }

  get read(): ComputedRef<boolean> {
    const readerStore = useReaderStore()
    const user = useCurrentUser()

    return computed(() => {
      return user.value
        ? readerStore.byDocumentAndUserId(user.value!.id, this.get('id'))?.progress === 100
        : false
    })
  }

  get readTime(): null | string {
    if (!this.get('word_count')) {
      return null
    }

    const wordsPerMin = 200
    const time = Math.ceil(this.get('word_count') / wordsPerMin)

    return `${time}m read`
  }

  get urls() {
    return {
      edit: router.resolve({ name: 'document.edit', params: { id: this.get('id') } }),
      read: router.resolve({ name: 'document.read', params: { id: this.get('slug') } }),
    }
  }

  get canEdit() {
    const type = this.get('type')
    const isArticle = [DocumentType.ARTICLE].includes(type)
    if (!isArticle) {
      return false
    }

    return this.hasEditPermission
  }

  get hasEditPermission() {
    return this.hasEditor(useCurrentUser().value!.id)
  }

  get canDelete() {
    return this.hasEditPermission
  }
}
