<template>
  <BdsModal
    :show="show"
    :title="topic ? 'Edit collection' : 'Create collection'"
    md
    :z-index="1000"
    @close="$emit('close')"
  >
    <BdsStack direction-column gap-xs>
      <BdsInput
        v-model="form.name"
        medium
        transparent
        class="!text-base"
        placeholder="Name your collection"
      />
      <BdsAutocomplete
        v-model="form.group"
        transparent
        placeholder="Add to group..."
        :items="groups"
      />
      <BdsEmojiPicker v-model="form.emoji" class="mt-4" capture />
    </BdsStack>

    <template #footer>
      <div></div>

      <BdsStack items-center gap-sm>
        <BdsButton sm @click="$emit('close')">Cancel</BdsButton>
        <BdsButton sm primary :loading="loading" @click="onSave">Save</BdsButton>
      </BdsStack>
    </template>
  </BdsModal>
</template>

<script setup lang="ts">
import { BdsButton, BdsInput, BdsModal, BdsStack } from '@getbrainy/bds-components'
import { onKeyDown } from '@vueuse/core'
import { collect } from 'collect.js'
import { computed, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import BdsAutocomplete from '@/components/BdsAutocomplete.vue'
import BdsEmojiPicker from '@/components/BdsEmojiPicker.vue'
import { makeToast } from '@/helpers'
import type { Topic } from '@/stores'
import { useSubscriptionStore, useTopicStore } from '@/stores'
import { TopicModel } from '@/stores/models/TopicModel'

interface Props {
  show: boolean
  topic?: Topic
  noRedirect?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  topic: undefined,
  noRedirect: false,
})
const emit = defineEmits(['close'])

const _topic = ref(props.topic ? new TopicModel(props.topic) : null)

const form = reactive({
  group: _topic.value?.group || '',
  name: _topic.value?.label,
  description: props.topic?.description || '',
  color: props.topic?.color || 'purple',
  emoji: props.topic?.emoji || '😀',
})

watch(
  () => props.topic,
  (t) => {
    _topic.value = new TopicModel(t)
    form.group = _topic.value?.group
    form.name = _topic.value?.label
    form.description = t?.description
    form.color = t?.color
    form.emoji = t?.emoji
  }
)

const store = useTopicStore()
const subscriptionStore = useSubscriptionStore()
const loading = ref(false)

const router = useRouter()

async function onSave(): Promise<any> {
  if (loading.value) return

  try {
    loading.value = true
    if (props.topic) {
      const data = await store.update(props.topic.id, {
        name: [form.group, form.name].filter((n) => n.length > 0).join(' / '),
        description: form.description,
        color: form.color,
        emoji: form.emoji,
      })
      makeToast('Changes saved')
      emit('close')
      if (!props.noRedirect && data?.slug && data.slug !== props.topic.slug) {
        await router.push({ name: 'topics.show', params: { topic: data.slug } })
      }
    } else {
      const data = await store.create({
        name: [form.group, form.name].filter((n) => n.length > 0).join(' / '),
        description: form.description,
        color: form.color,
        emoji: form.emoji,
      })
      subscriptionStore.subscribe(data.id, null, { onCommit: () => {} })
      makeToast('Collection created!')
      emit('close')
    }
  } finally {
    loading.value = false
  }
}

onKeyDown(['Enter'], (e: KeyboardEvent) => {
  if (!e.metaKey || !props.show) return

  e.preventDefault()
  onSave()
})

const groups = computed(() =>
  collect(store.all)
    .map((t) => t.group)
    .filter((x) => x)
    .unique()
    .all()
)
</script>
