<template>
  <div class="relative group w-16 h-16 rounded overflow-hidden cursor-pointer" @click="removeImage">
    <div
      class="absolute inset-0 bg-black/50 group-hover:flex items-center justify-center"
      :class="loading ? 'flex' : 'hidden'"
    >
      <BdsSpinner v-if="loading" sm />
      <BdsIcon v-else name="Trash" class="w-3.5 h-3.5 text-white" />
    </div>

    <img
      :src="_asset?.src || uploader.preview.value"
      alt="Image"
      class="w-full h-full object-center object-cover"
    />
  </div>
</template>

<script setup lang="ts">
import { BdsIcon, BdsSpinner } from '@getbrainy/bds-components'
import { computed, onMounted, PropType, ref } from 'vue'

import { makeError } from '@/helpers'
import { image } from '@/modules/documents/components/Editor/TipTap/MediaFile/Extension'

const props = defineProps({
  src: { type: String, default: '' },
  file: { type: Object as PropType<File>, required: true },
})

const emit = defineEmits(['upload', 'remove'])

const _asset = ref()

const uploader = image.uploadHandler(
  (asset) => {
    _asset.value = asset
    emit('upload', asset)
  },
  (e) => {
    makeError('Failed to upload image')
  }
)

const loading = computed(() => uploader.loading.value)

const removeImage = () => emit('remove', _asset.value)

onMounted(() => uploader.uploadFile([props.file]))
</script>
