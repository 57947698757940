<template>
  <div
    class="grid grid-cols-2 grid-rows-2 gap-1"
    :class="{ 'aspect-[16/9]': medias.length > 1, 'max-h-[320px]': sm }"
  >
    <template v-for="(media, idx) in medias" :key="media.media_key">
      <div class="h-full w-full overflow-hidden" :class="getImageClass(media, idx, medias.length)">
        <video
          v-if="
            ['animated_gif', 'video'].includes(media.type) &&
            (media.url ?? media?.variants?.[0]?.url)
          "
          preload="auto"
          playsinline
          autoplay
          loop
          controls
          muted
          disablepictureinpicture
          v-bind="
            lazy
              ? {
                  'data-poster': media.preview_image_url,
                  'data-src': media.url ?? media.variants[0].url,
                }
              : { poster: media.preview_image_url, src: media.url ?? media.variants[0].url }
          "
          class="!my-0 h-full w-full bg-black lozad"
          type="video/mp4"
          style="transform: rotate(0deg) scale(1.005)"
        ></video>

        <img
          v-else-if="media.type === 'photo' || (media.type === 'video' && media.preview_image_url)"
          class="!my-0 rounded object-center w-full h-full lozad"
          :class="{
            'object-fit': medias.length === 1 && !sm,
            'object-cover': medias.length > 1 || sm,
          }"
          alt=""
          v-bind="
            lazy
              ? { 'data-src': media.url || media.preview_image_url }
              : { src: media.url || media.preview_image_url }
          "
          @click.prevent="useMediaOverlay(media.url || media.preview_image_url, photos)"
        />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useMediaOverlay } from '@/hooks'

interface TwitterMedia {
  url?: string
  preview_image_url: string
  type: string
  media_key: string
  variants: { url: string }[]
}

const props = withDefaults(
  defineProps<{
    media: TwitterMedia[]
    sm?: boolean
    lazy?: boolean
  }>(),
  {
    sm: false,
    lazy: false,
  }
)

const medias = computed(() => props.media.slice(0, 4))
const photos = computed(() => medias.value.filter((m) => m.type === 'photo').map((m) => m.url))

const getImageClass = (media: TwitterMedia, index: number, count: number) => {
  if (count == 1) {
    return 'col-span-2 row-span-2'
  }

  if (count == 2 || (count == 3 && index == 0)) {
    return {
      'aspect-[7/8]': true,
      'row-span-2': count === 2 || index === 0,
    }
  }
  return 'aspect-[4/7]'
}
</script>
