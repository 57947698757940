import { defineStore } from 'pinia'

// @ts-ignore
import InviteDTO = Brainy.Workspace.Contracts.DataTransferObjects.InviteDTO
import { useBrainyStore } from '@/hooks'
import { InviteModel } from '@/stores/models/InviteModel'
export type Invite = InviteDTO

export const useInviteStore = defineStore('invites', () => {
  const state = useBrainyStore<Invite>('invites', {
    model: 'InviteDTO',
    makeModel: (attrs) => new InviteModel(attrs),
  })

  return { ...state }
})
