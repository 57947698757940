<template>
  <TransitionRoot as="template" show>
    <Dialog as="div" class="relative z-[999]" @close="$emit('cancel')">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-grey bg-opacity-10 dark:bg-black dark:bg-opacity-50 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-[999] overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel class="relative transform overflow-hidden shadow-xl transition-all">
              <img :src="currentImage" class="max-h-[80vh] object-contain rounded" />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { onKeyDown } from '@vueuse/core'
import { computed, ref, watchEffect } from 'vue'

const props = defineProps({
  image: { type: String, required: true },
  images: { type: Array, default: () => [] },
})

defineEmits(['close'])

const activeIndex = ref(-1)

watchEffect(() => {
  if (!props.images.length) return

  activeIndex.value = props.images?.findIndex((x) => x === props.image) ?? -1
})

const currentImage = computed(() => {
  if (activeIndex.value !== -1) {
    return props.images[activeIndex.value]
  }

  return props.image
})

function prev(): void {
  const prevIndex = activeIndex.value - 1

  if (activeIndex.value <= 0) {
    return
  }

  activeIndex.value = prevIndex
}

function next(): void {
  const nextIndex = activeIndex.value + 1
  const maxIndex = props.images.length - 1

  if (nextIndex > maxIndex) {
    return
  }

  activeIndex.value = nextIndex
}

onKeyDown(['ArrowLeft'], (e: KeyboardEvent) => {
  e.preventDefault()
  prev()
})
onKeyDown(['ArrowRight'], (e: KeyboardEvent) => {
  e.preventDefault()
  next()
})
</script>
