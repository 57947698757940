export type Model = Record<string, any>

export enum DocumentType {
  ARTICLE = 1,
  LINK = 2,
  FILE = 3,
  WEB_ARTICLE = 4,
  IMAGE = 5,
  VIDEO = 6,
  TWEET = 7,
  GITHUB = 8,
  SPOTIFY = 9,
  LINKEDIN = 10,
}

export enum TopicType {
  SYSTEM = 1,
  USER = 2,
  AI = 3,
}

export enum DocumentStatus {
  PENDING = 1,
  PROCESSED = 2,
  ERROR = 3,
}

export enum WorkspaceType {
  PERSONAL = 1,
  TEAM = 2,
}

export enum Role {
  WORKSPACE_OWNER = 'workspace_owner',
  ADMIN = 'admin',
  MEMBER = 'member',
}
