import { defineStore } from 'pinia'
import { computed } from 'vue'

import { useBrainyStore } from '@/hooks'
import type { Editor } from '@/stores/editor.store'
import { DocumentTopicModel } from '@/stores/models/DocumentTopicModel'

export type DocumentTopic = DocumentTopicModel

export const useDocumentTopicStore = defineStore('document-topics', () => {
  const state = useBrainyStore<DocumentTopic>('document-topics', {
    model: 'DocumentTopicDTO',
    makeModel: (attrs) => new DocumentTopicModel(attrs),
  })

  const groupedByDocument = state.$groupedBy('document_id', {
    multiple: true,
  })

  return {
    ...state,

    groupedByDocument,
    byDocumentId: computed(() => {
      return (id: Editor['document_id']) => {
        return groupedByDocument.value[id]
      }
    }),
  }
})
