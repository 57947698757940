import { onKeyStroke } from '@vueuse/core'
import { computed, ref } from 'vue'

let hash = ''

function logMessage(message: string, keys: string[], data = {}) {
  return
  console.log(`[Sequence] [${hash}] (${keys.join('_')}) ${message}`, data)
}

function useMagicSequence(keys: string[], onSuccess: (e: KeyboardEvent) => void) {
  let i = ref(0)
  let prefix = false
  const progress = computed(() => keys.slice(0, i.value))
  const prefixKeys = ['g']

  onKeyStroke((e: KeyboardEvent) => {
    if (!keys.includes('Meta') && e.metaKey) return
    if (!keys.includes('Shift') && e.shiftKey) return
    if (!keys.includes('Alt') && e.altKey) return

    // hash = (Math.random() + 1).toString(36).substring(7)

    logMessage('Init call', keys, {
      code: e.code,
      key: e.key,
    })

    if (prefixKeys.includes(e.key)) {
      logMessage('Setting prefix', keys)
      prefix = true

      setTimeout(() => {
        logMessage('Resetting prefix', keys)
        prefix = false
        i.value = 0
      }, 500)
    }

    if (keys.length === 1 && prefix) {
      logMessage('Abort due to prefix set', keys)
      return
    }

    if (e.key === keys[i.value]) {
      logMessage('Key matched', keys)
      i.value += 1
    } else {
      logMessage('Reset', keys)
      i.value = 0
    }

    if (i.value >= keys.length) {
      logMessage('Success', keys)
      i.value = 0
      prefix = false
      onSuccess(e)
    }
  })

  return {
    index: i,
    progress,
  }
}

export default useMagicSequence
