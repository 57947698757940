<template>
  <slot v-bind="{ timeago, human }">
    <span :title="human">
      {{ timeagoText }}
    </span>
  </slot>
</template>

<script lang="ts">
import { tryOnScopeDispose } from '@vueuse/core'
import TimeAgo from 'javascript-time-ago'
// English.
import en from 'javascript-time-ago/locale/en'
import { computed, onUnmounted, ref, watch } from 'vue'

import { getSafeTimeoutInterval } from '@/helpers'

TimeAgo.addLocale(en)

export default {
  name: 'TimeAgo',
  props: {
    timestamp: {},
    short: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    TimeAgo.setDefaultLocale('en')
    const timeAgo = new TimeAgo('en-US')

    const time = computed(() =>
      typeof props.timestamp === 'Date' ? props.timestamp : new Date(Date.parse(props.timestamp))
    )

    const isFuture = computed(() => time.value.getTime() - new Date().getTime() > 0)
    const timeago = ref('')
    const human = computed(() => time.value.toLocaleString())

    let updateTimer
    const updateTimeAgo = () => {
      const [formattedDate, timeToNextUpdate] = timeAgo.format(
        time.value,
        props.short ? 'mini-minute-now' : 'minute-now',
        {
          getTimeToNextUpdate: true,
        }
      )
      timeago.value = formattedDate

      updateTimer = setTimeout(updateTimeAgo, getSafeTimeoutInterval(timeToNextUpdate || 60 * 1000))
    }
    updateTimeAgo()
    watch(time, updateTimeAgo)

    tryOnScopeDispose(() => {
      if (updateTimer) {
        clearTimeout(updateTimer)
      }
    })

    const timeagoText = computed(() =>
      timeago.value === 'now' ? 'now' : isFuture.value ? timeago.value : `${timeago.value} ago`
    )

    return {
      human,
      timeago,
      timeagoText,
    }
  },
}
</script>
