import { nextTick } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import { useCurrentWorkspace, useMenuControl } from '@/hooks'
import Analytics from '@/services/Analytics'
import { useAuthStore } from '@/stores'

const router = createRouter({
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
  },
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes: [
    {
      path: '/',
      redirect: { name: 'explore' },
      children: [
        {
          path: '/inbox',
          name: 'inbox',
          component: () => import('../views/NotificationsView.vue'),
          meta: {
            title: 'Inbox',
          },
        },
        {
          path: '/explore',
          name: 'explore',
          component: () => import('../views/ExploreView.vue'),
          meta: {
            title: 'Explore',
          },
        },
        {
          path: '/catch-up',
          name: 'catch-up',
          component: () => import('../views/CatchUpView.vue'),
          meta: {
            title: 'Catch up',
          },
        },
        {
          path: '/catch-up/:topic',
          name: 'catch-up.topic',
          component: () => import('../views/CatchUpView.vue'),
        },
        {
          path: '/search',
          name: 'search',
          component: () => import('../views/SearchView.vue'),
          meta: {
            title: 'Search',
          },
        },
        {
          path: '/twitter/bookmarks',
          name: 'twitter.bookmarks',
          component: () => import('../views/TwitterView.vue'),
          meta: {
            title: 'Twitter bookmark review',
          },
        },
        {
          path: '/github/stars',
          name: 'github.stars',
          component: () => import('../views/GithubView.vue'),
          meta: {
            title: 'Github stars review',
          },
        },
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: () => import('../views/NotFound.vue'),
    },
  ],
})

router.afterEach(async (to) => {
  await nextTick()
  Analytics.page(to.name, document.title, to.params)
})
router.beforeResolve(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/register']
  const authRequired =
    !publicPages.includes(to.path) && !to.matched.some((record) => record.meta?.public)
  const auth = useAuthStore()

  if (to.name === 'invites.accept') {
    return
  }

  if (!authRequired && auth.isLoggedIn) {
    return { name: 'explore' }
  }

  if (authRequired && to.query?.auth === 'success') {
    await auth.loadMe()
  }

  if (authRequired && !auth.isLoggedIn) {
    auth.returnUrl = to.fullPath
    return '/login'
  }

  if (!auth.isLoggedIn) {
    if (to.meta?.title) {
      document.title = `${to.meta.title} | Brainy`
    }

    return
  } else {
    const menuControl = useMenuControl()
    if (menuControl.mobileNavigationOpen.value) {
      menuControl.toggleMobileNavigation()
    }
  }

  if (!auth.triedLoadingWorkspaces) {
    await auth.loadWorkspaces()
  }

  if (!auth.hasWorkspace && to.name != 'workspaces.new') {
    return { name: 'workspaces.new' }
  }

  if (to.name.startsWith('settings.workspace')) {
    const workspace = useCurrentWorkspace()

    if (workspace.value && (!workspace.value?.isTeam || !workspace.value?.isCurrentUserAdmin)) {
      return { name: 'settings.account' }
    }
  }

  if (to.meta?.title) {
    const prefix = to.name.startsWith('settings.') ? 'Settings / ' : ''
    document.title = `${prefix}${to.meta.title}`
  }
})

export default router
