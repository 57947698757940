<template>
  <div class="relative" @keydown.esc.stop="open = false">
    <slot v-bind="{ toggle }" name="trigger">
      <BdsButton
        xs
        square
        class="!shadow-none dark:!bg-transparent dark:!border-black-700 !leading-none !text-base"
        @click="toggle"
      >
        {{ model }}
      </BdsButton>
    </slot>

    <div
      v-if="open"
      ref="dropdown"
      :class="[
        fixed ? 'fixed' : 'absolute top-full',
        top ? '-translate-y-full -mt-8 -ml-3' : ' mt-1',
      ]"
    >
      <EmojiPicker native @select="onSelect" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { BdsButton } from '@getbrainy/bds-components'
import { onClickOutside, onStartTyping, useVModel } from '@vueuse/core'
import { nextTick, ref, watch } from 'vue'
import EmojiPicker from 'vue3-emoji-picker'

const props = defineProps({
  modelValue: { type: String, default: '' },
  closeOnSelect: { type: Boolean, default: true },
  fixed: { type: Boolean, default: false },
  top: { type: Boolean, default: false },
  capture: { type: Boolean, default: false },
})

const emit = defineEmits(['update:modelValue'])

const model = useVModel(props, 'modelValue', emit)
const open = ref(false)
const dropdown = ref(null)

onClickOutside(
  dropdown,
  () => {
    open.value = false
  },
  { capture: props.capture }
)

function focusInputElement() {
  document.querySelectorAll('.v3-search input')?.[0]?.focus()
}

watch(open, (v) => {
  if (v) {
    nextTick(() => focusInputElement())
  }
})

onStartTyping(() => {
  if (open.value) {
    focusInputElement()
  }
})

function toggle(): void {
  open.value = !open.value
}

function onSelect(emoji: any) {
  model.value = emoji.i

  if (props.closeOnSelect) {
    open.value = false
  }
}
</script>
