import { defineStore } from 'pinia'

// @ts-ignore
import AssetDTO = Brainy.Asset.Contracts.DataTransferObjects.AssetDTO
import { useBrainyStore } from '@/hooks'
import { useMemberStore } from '@/stores/member.store'
import { AssetModel } from '@/stores/models/AssetModel'
import type { SearchFilter, SearchResult } from '@/types/shared'

export type Asset = AssetDTO & {}

export type AssetSearchParams = {
  q?: string
  per_page?: number
  page?: number
  filter_by?: SearchFilter[]
}

export const useAssetStore = defineStore('assets', () => {
  const state = useBrainyStore<Asset>('assets', {
    model: 'AssetDTO',
    makeModel: (attrs) => new AssetModel(attrs),
    resolveRelation: (name, target) => {
      if (name === 'creator') {
        const members = useMemberStore()
        return members.userById(target.uploaded_by)
      }
    },
  })

  return {
    ...state,

    async search(params: AssetSearchParams): Promise<SearchResult<Asset>> {
      const results = state.filter({
        filter: (doc: Asset) => {
          return params.q
            ? doc.filename?.toLocaleLowerCase().includes(params.q?.toLowerCase())
            : true
        },
      })

      return {
        hits: results.value,
        total: results.value.length,
      }
    },
  }
})
