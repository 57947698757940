// @ts-ignore
import ClapDTO = Brainy.Document.Contracts.DataTransferObjects.ClapDTO
import { BaseModel } from '@/stores/models/BaseModel'
import type { DocumentModel } from '@/stores/models/DocumentModel'
import type { UserModel } from '@/stores/models/UserModel'

export class ClapModel extends BaseModel<ClapDTO> implements ClapDTO {
  declare claps: number
  declare document_id: string
  declare id: string
  declare user_id: string
  declare created_at: string
  declare updated_at: string

  declare document: DocumentModel
  declare user: UserModel
}
