import { BdsToastMessage, BdsToastNotification } from '@getbrainy/bds-components'
import type { StorageLike, UseStorageOptions } from '@vueuse/core'
import { isFunction, useActiveElement, useClipboard, useStorage } from '@vueuse/core'
import type { ComputedRef } from 'vue'
import { computed, ref, watch } from 'vue'
import { POSITION, useToast } from 'vue-toastification'

const toast = useToast()

export function filterByProperties<T>(
  array: T[],
  filterOrFilterFn: Partial<T> | ((item: T) => boolean)
): T[] {
  if (isFunction(filterOrFilterFn)) {
    return array.filter((item: T) => filterOrFilterFn(item))
  }

  return array.filter((item: T) => {
    for (const key in filterOrFilterFn) {
      if (Array.isArray(filterOrFilterFn[key])) {
        if (!(filterOrFilterFn[key] as any).includes(item[key])) {
          return false
        }
      } else if (item[key] !== filterOrFilterFn[key]) {
        return false
      }
    }
    return true
  })
}

export const makeNotification = (description: string, avatar: object, title?: string) => {
  toast(
    {
      component: BdsToastNotification,
      props: {
        avatar,
        description,
        title,
      },
    },
    {
      position: POSITION.TOP_RIGHT,
    }
  )
}

export const makeToast = (message: string, icon: object | null = null, danger = false) => {
  toast({
    component: BdsToastMessage,
    props: {
      message,
      icon,
      danger,
    },
  })
}

export const makeError = (message: string, icon: object | null = null) => {
  return makeToast(message, icon, true)
}

export const copyCurrentURL = (): void => {
  copyTextToClipboard(window.location.href)
}

export const copyTextToClipboard = (text: string): void => {
  const { copy } = useClipboard({ source: text, legacy: true })
  copy().then(() => makeToast('Copied link!'))
}

export const debounce = function <F extends (...params: any[]) => ReturnType<F>>(
  fn: F,
  delay: number
): F {
  let timeoutID: ReturnType<typeof setTimeout>
  return function (...args: unknown[]) {
    clearTimeout(timeoutID)
    timeoutID = setTimeout(() => fn.apply(this, args), delay)
  } as F
}

// `setTimeout()` has a bug where it fires immediately
// when the interval is longer than about `24.85` days.
// https://stackoverflow.com/questions/3468607/why-does-settimeout-break-for-large-millisecond-delay-values
const SET_TIMEOUT_MAX_SAFE_INTERVAL = 2147483647
export const getSafeTimeoutInterval = (interval: number) => {
  return Math.min(interval, SET_TIMEOUT_MAX_SAFE_INTERVAL)
}

export const objectMap = (obj: any, fn: (v: any, k: any, i: number) => any) => {
  return Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]))
}

export function isPromise(p: any) {
  return (
    p && (Object.prototype.toString.call(p) === '[object Promise]' || typeof p.then === 'function')
  )
}

export function useExpiringStorage<T extends string | number | boolean | object | null>(
  key: string,
  defaultValue: T | (() => T),
  expiryInSeconds: number,
  storage?: StorageLike,
  options: UseStorageOptions<T> = {}
): ComputedRef<any> {
  const fallbackValue = typeof defaultValue === 'function' ? null : defaultValue
  const defaultFn = async () => {
    return typeof defaultValue !== 'function' ? defaultValue : defaultValue()
  }

  const decoratedValue = {
    timestamp: new Date().getTime(),
    actual: fallbackValue,
  }

  const underlying = useStorage<any>(key, decoratedValue, storage, options)

  const proxy = ref(fallbackValue)
  watch(
    underlying,
    async () => {
      const now = new Date()
      if (underlying.value?.actual === null) {
        expiryInSeconds = 10
      }

      const isExpired = (now.getTime() - underlying.value.timestamp) / 1000 > expiryInSeconds

      if (
        typeof underlying.value.actual === 'undefined' ||
        // underlying.value.actual === null ||
        isExpired
      ) {
        underlying.value = {
          timestamp: new Date().getTime(),
          actual: fallbackValue ?? (await defaultFn()),
        }
      }

      if (underlying.value.actual == proxy.value) {
        return
      }

      proxy.value = underlying.value.actual
    },
    { immediate: true }
  )

  watch(proxy, () => {
    underlying.value.actual = proxy.value
    underlying.value.timestamp = new Date().getTime()
  })

  return proxy
}

export const mediaDeviceSupport = async (): Promise<{
  audio: boolean
  video: boolean
}> => {
  const devices = (await navigator.mediaDevices?.enumerateDevices()) || []
  let audio = false,
    video = false

  devices.forEach((device) => {
    if (device.kind == 'audioinput') {
      audio = true
    }
    if (device.kind == 'videoinput') {
      video = true
    }
  })

  return { audio, video }
}

export const random = <T>(array: T[], seed: string): T => {
  // Sum the ASCII codes of each letter
  const number = seed.split('').reduce((number, letter) => {
    return number + letter.charCodeAt(0)
  }, 0)
  // Get "random" index using modulus
  return array[number % array.length]
}

const activeElement = useActiveElement()
export const notUsingInput = computed(
  () =>
    activeElement.value?.tagName !== 'INPUT' &&
    activeElement.value?.tagName !== 'TEXTAREA' &&
    activeElement.value?.className.indexOf('ProseMirror') === -1
)

import { v4 as uuidv4 } from 'uuid'
export const uuid = () => uuidv4()

export const findSmallestMissing = (arr = [], startingNumber = 0) => {
  if (!arr?.length) {
    return startingNumber
  }
  // @ts-ignore
  while (arr.indexOf(startingNumber) !== -1) {
    startingNumber++
  }
  return startingNumber
}
