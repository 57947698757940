import { defineStore } from 'pinia'
import { computed } from 'vue'

import { makeError, makeToast } from '@/helpers'
import { useCurrentUser } from '@/hooks'
import { type MakeMutationHooks, useBrainyStore } from '@/hooks'
import { SubscriptionModel } from '@/stores/models'
import { useTopicStore } from '@/stores/topic.store'

export type Subscription = SubscriptionModel

export const useSubscriptionStore = defineStore('subscriptions', () => {
  const state = useBrainyStore<Subscription>('subscriptions', {
    model: 'SubscriptionDTO',
    makeModel: (attrs) => new SubscriptionModel(attrs),
    relations: ['topic'],
    resolveRelation: (name, target) => {
      if (name == 'topic') {
        const topics = useTopicStore()
        return topics.byId(target.entity_id)
      }
    },
  })

  function byEntityId(entityId: Subscription['entity_id']): Subscription[]
  // @ts-ignore
  // eslint-disable-next-line no-redeclare
  function byEntityId(
    entityId: Subscription['entity_id'],
    userId: Subscription['user_id']
  ): Subscription | null
  // eslint-disable-next-line no-redeclare
  function byEntityId(
    entityId: Subscription['entity_id'],
    userId?: Subscription['user_id']
  ): Subscription[] | Subscription {
    const items = state.filter({
      filter: (item) => item.entity_id === entityId && (!userId || item.user_id === userId),
    })

    if (typeof userId === 'string') {
      return items.value[0] || null
    }

    return items.value
  }

  return {
    ...state,
    groupedByUser: state.$groupedBy('user_id', {
      multiple: true,
    }),
    groupedByEntity: state.$groupedBy('entity_id', {
      multiple: true,
    }),
    allByUser: computed(() => {
      return (userId: string) => {
        return state.filter({
          filter: (sub) => sub.user_id === userId,
        })
      }
    }),
    byEntityId: computed(() => byEntityId),

    // actions
    async subscribe(
      entityId: Subscription['entity_id'],
      userId: Subscription['user_id'] | null = null,
      hooks: Partial<MakeMutationHooks> = {}
    ) {
      const currentUser = useCurrentUser()
      const payload = {
        entity_id: entityId,
        user_id: userId ?? currentUser.value?.id,
      }

      return state.create(payload, {
        onError: () => makeError(`Could not follow`),
        onCommit: () => makeToast(`Followed`),
        ...hooks,
      })
    },
    async unsubscribe(id: Subscription['entity_id'], hooks: Partial<MakeMutationHooks> = {}) {
      const currentUser = useCurrentUser()
      const sub = byEntityId(id, currentUser.value!.id)
      if (!sub) {
        return
      }

      state.delete(sub.id, {
        onError: () => makeError(`Could not unfollow ${sub.topic.name}`),
        onCommit: () => makeToast(`Unfollowed ${sub.topic.name}`),
        ...hooks,
      })
    },
    // subscription store
    async mute(entityId: string, hooks: Partial<MakeMutationHooks> = {}) {
      const sub = state.allMine.value.filter((x) => x.entity_id === entityId)[0]
      state.update(
        sub.id,
        {
          is_muted: true,
        },
        {
          onError: () => makeError(`Could not mute ${sub.topic.name}`),
          onCommit: () => makeToast(`Muted ${sub.topic.name}`),
          ...hooks,
        }
      )
    },
    async unmute(entityId: string, hooks: Partial<MakeMutationHooks> = {}) {
      const sub = state.allMine.value.filter((x) => x.entity_id === entityId)[0]
      state.update(
        sub.id,
        {
          is_muted: false,
        },
        {
          onError: () => makeError(`Could not unmute ${sub.topic.name}`),
          onCommit: () => makeToast(`Unmuted ${sub.topic.name}`),
          ...hooks,
        }
      )
    },
  }
})
