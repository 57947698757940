<template>
  <div class="my-3">
    <SizeBubbleMenu
      :size="currentSize"
      :show="!readOnly && !loading && provider !== 'twitter' && provider !== 'figma'"
      v-bind="{ ...bubbleOptions }"
      @size="setSize"
      @remove="remove"
    >
      <TwitterWrapper v-if="provider === 'twitter'" v-bind="{ id, url: src }" />
      <GithubCard v-else-if="provider === 'github'" v-bind="{ id, src }" />
      <LinkedInWrapper
        v-else-if="provider === 'linkedin'"
        :url="src"
        :original="original"
        @load="loading = false"
      />

      <div v-else-if="src">
        <DocumentEmbed :url="src" :provider="provider" class="mb-1" @load="loading = false" />
        <div class="text-center text-xs mt-3">
          <a target="_blank" :href="original" class="text-grey-500 dark:!text-black-300">
            View original link
          </a>
        </div>
      </div>
    </SizeBubbleMenu>
    <div :style="`--placeholder: 'Paste your ${providerName} link here'`">
      <slot
        class="content-dom placeholder:text-grey-300 placeholder:dark:text-black-400 bg-grey-50 dark:bg-black-600 rounded py-4 px-6 w-full"
      ></slot>
    </div>
  </div>
</template>

<script lang="ts">
import type { EmbedProperties } from '@getbrainy/extension-embed'
import { computed, ref, watch } from 'vue'

import DocumentEmbed from '@/modules/documents/components/DocumentEmbed.vue'
import SizeBubbleMenu from '@/modules/documents/components/Editor/SizeBubbleMenu.vue'
import LinkedInWrapper from '@/modules/documents/components/Editor/TipTap/Embed/LinkedInWrapper.vue'

import GithubCard from './Github/GithubCard.vue'
import TwitterWrapper from './TwitterWrapper.vue'

export default {
  name: 'EmbedComponent',
  components: {
    LinkedInWrapper,
    DocumentEmbed,
    SizeBubbleMenu,
    TwitterWrapper,
    GithubCard,
  },
  props: [
    'provider',
    'id',
    'src',
    'handleInput',
    'remove',
    'currentSize',
    'setSize',
    'readOnly',
    'node',
    // 'original',
  ],

  setup(props: EmbedProperties) {
    const input = ref(props.src)
    const loading = ref(false)
    watch(
      () => props.src,
      () => (loading.value = true),
      { immediate: true }
    )

    const providerMap: Record<string, string> = {
      youtube: 'YouTube, Vimeo or Loom',
      vimeo: 'YouTube, Vimeo or Loom',
      loom: 'YouTube, Vimeo or Loom',
      'google-docs': 'Docs, Slides or Sheets',
      'google-slides': 'Docs, Slides or Sheets',
      'google-sheets': 'Docs, Slides or Sheets',
      figma: 'Figma',
      twitter: 'Twitter',
    }
    const providerName = computed<string>(() => {
      return props.provider ? providerMap[props.provider] : ''
    })

    return {
      bubbleOptions: computed(() => {
        if (props.provider === 'figma') {
          return {
            'hide-default-btn': true,
            'hide-original-btn': true,
            'hide-large-btn': true,
            'hide-full-btn': true,
          }
        }

        if (['google-docs', 'google-slides', 'google-sheets'].includes(<string>props.provider)) {
          return {
            'hide-change-btn': true,
            'hide-original-btn': true,
          }
        }

        return {
          'hide-change-btn': true,
          'hide-original-btn': true,
        }
      }),
      providerName,
      input,
      loading,
      // todo: we should probably save original link
      original: computed(() => props.node.textContent),
    }
  },
}
</script>
