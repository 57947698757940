import { Embed, figma, vimeo, youtube } from '@getbrainy/extension-embed'
import type { EmbedType } from '@getbrainy/extension-embed/src/embed'
import { shallowRef } from 'vue'

import { useTweetsStore } from '@/stores/tweet.store'

import EmbedComponent from './Component.vue'

let twitterStore: ReturnType<typeof useTweetsStore>

export const github: EmbedType = {
  name: 'github',
  regExp:
    '^(?:(?:https?:)?\\/\\/)?(?:www\\.)?(?:m\\.)?(?:github)?\\.com\\/((?:.+?)\\/(?:.+?))(?:(?:[\\?\\.\\/]{1}.+))?$',
  formatUrl: (id) => `https://github.com/${id}`,
  getId: (match) => match[1],
}
export const twitter: EmbedType = {
  name: 'twitter',
  regExp:
    '^(?:(?:https?:)?\\/\\/)?(?:www\\.)?(?:m\\.)?(?:twitter)?\\.com\\/.+?\\/status\\/((?:\\d){19,})(\\?\\S+)?$',
  formatUrl: (id) => {
    if (!twitterStore) {
      twitterStore = useTweetsStore()
    }
    const tweet = twitterStore.byId(id)
    if (!tweet) {
      return '#'
    }

    return `https://twitter.com/${tweet.author.username}/status/${id}`
  },
  getId: (match) => match[1],
}
export const loom: EmbedType = {
  name: 'loom',
  regExp: '^(?:(?:https?:)?\\/\\/)?(?:www\\.)?(?:loom)?\\.com\\/(embed|share)\\/([a-f0-9]+)',
  formatUrl: (id) => `https://www.loom.com/embed/${id}`,
  getId: (match) => match[2],
}
export const googleSlides: EmbedType = {
  name: 'google-slides',
  regExp:
    '^(?:(?:https?:)?\\/\\/)?(?:docs\\.)?(?:google)?\\.com\\/(presentation)\\/d\\/e\\/([a-zA-Z0-9-_]+)',
  formatUrl: (id) =>
    `https://docs.google.com/presentation/d/e/${id}/embed?start=false&loop=false&delayms=3000`,
  getId: (match) => match[2],
}
export const googleDocs: EmbedType = {
  name: 'google-docs',
  regExp:
    '^(?:(?:https?:)?\\/\\/)?(?:docs\\.)?(?:google)?\\.com\\/(document)\\/d\\/e\\/([a-zA-Z0-9-_]+)',
  formatUrl: (id) => `https://docs.google.com/document/d/e/${id}/pub?embedded=true`,
  getId: (match) => match[2],
}
export const googleSheets: EmbedType = {
  name: 'google-sheets',
  regExp:
    '^(?:(?:https?:)?\\/\\/)?(?:docs\\.)?(?:google)?\\.com\\/(spreadsheets)\\/d\\/e\\/([a-zA-Z0-9-_]+)',
  formatUrl: (id) => `https://docs.google.com/spreadsheets/d/e/${id}/pubhtml`,
  getId: (match) => match[2],
}
export const googleDrive: EmbedType = {
  name: 'google-drive',
  regExp: '^(?:(?:https?:)?\\/\\/)?(?:drive\\.)?(?:google)?\\.com\\/(file)\\/d\\/([a-zA-Z0-9-_]+)',
  formatUrl: (id) => `https://drive.google.com/file/d/${id}/preview`,
  getId: (match) => match[2],
}
export const spotify: EmbedType = {
  name: 'spotify',
  regExp: '^https?:\\/\\/open.spotify.com\\/((track|episode)\\/([a-zA-Z0-9]+))',
  formatUrl: (id) => `https://open.spotify.com/embed/${id}`,
  getId: (match) => match[1],
}

export const linkedin: EmbedType = {
  name: 'linkedin',
  regExp:
    '(?:https?:)?//(?:[w]+.)?linkedin.com/(posts|feed/update)/(.*-|urn:li:activity:)([0-9]{19})/?',
  formatUrl: (id) => `https://www.linkedin.com/embed/feed/update/urn:li:activity:${id}`,
  getId: (match) => match[3],
}

export const BrainyEmbed = Embed.configure({
  types: [
    loom,
    youtube,
    vimeo,
    figma,
    twitter,
    googleSlides,
    googleDocs,
    googleSheets,
    github,
    googleDrive,
    spotify,
    linkedin,
  ],
  component: shallowRef(EmbedComponent),
})

export default BrainyEmbed
