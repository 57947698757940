import { type FunctionArgs, tryOnUnmounted, useTimeoutFn } from '@vueuse/core'
import type { PusherChannel, PusherPresenceChannel } from 'laravel-echo/dist/channel'
import { type Ref, nextTick, onMounted, ref } from 'vue'

import useEcho from '@/hooks/useEcho'

interface EchoOptions {
  channel: string
  presence?: boolean
  events?: Record<string, FunctionArgs>
  whispers?: Record<string, FunctionArgs>
  notification?: FunctionArgs
  here?: FunctionArgs
  joining?: FunctionArgs
  leaving?: FunctionArgs
}

export type EchoChannel = PusherChannel | (PusherChannel & PusherPresenceChannel)

export type VoiceChannel = PusherChannel & PusherPresenceChannel

export interface EchoReturn {
  channel: EchoChannel | VoiceChannel
  close: () => void
  socketId: Ref<string | null>
}

const subscriptions: Record<
  string,
  {
    channel: EchoChannel
    subscriptions: number
  }
> = {}

const useEchoChannel = async (options: EchoOptions): Promise<EchoReturn> => {
  const { channel: channelName, presence = false } = options
  const EchoClient = await useEcho()

  const channelNameIndex = `${channelName}-${presence}`
  if (!subscriptions[channelNameIndex]) {
    subscriptions[channelNameIndex] = {
      channel: presence
        ? (EchoClient.join(channelName) as PusherPresenceChannel)
        : (EchoClient.private(channelName) as PusherChannel),
      subscriptions: 0,
    }
  }

  const echo = subscriptions[channelNameIndex]

  if (presence) {
    // @ts-ignore
    ;['here', 'joining', 'leaving'].forEach((ev: 'here' | 'joining' | 'leaving') => {
      const callback = options[ev] as Function | undefined
      if (callback) {
        ;(echo.channel as PusherPresenceChannel)[ev](callback)
      }
    })
  }

  if (options.notification) {
    echo.channel.notification(options.notification)
  }

  const listenToEchoEvents = (startListening = true) => {
    if (subscriptions[channelNameIndex]?.subscriptions) {
      subscriptions[channelNameIndex].subscriptions += startListening ? 1 : -1
    }

    nextTick(() => {
      Object.keys(options.events ?? {}).forEach((ev) => {
        console.log(`[echo] ${startListening ? '' : 'Stop '}Subscribing to channel`, {
          event: ev,
        })

        const callback = options.events?.[ev] as Function
        if (startListening) {
          echo.channel.listen(`.${ev}`, callback)
        } else {
          echo.channel.stopListening(`.${ev}`, callback)
        }
      })
    })
    nextTick(() => {
      Object.keys(options.whispers ?? {}).forEach((ev) => {
        console.log(`[echo] ${startListening ? '' : 'Stop '}Subscribing to whisper`, {
          event: ev,
        })

        const callback = options.whispers?.[ev] as Function
        if (startListening) {
          echo.channel.listenForWhisper(`${ev}`, callback)
        } else {
          echo.channel.stopListeningForWhisper(`${ev}`, callback)
        }
      })
    })
  }

  // onMounted(listenToEchoEvents)
  listenToEchoEvents()

  const socketId = ref<string | null>(null)
  const interval = setInterval(() => {
    if (EchoClient.socketId()) {
      socketId.value = EchoClient.socketId()
      clearInterval(interval)
    }
  }, 50)

  tryOnUnmounted(() => {
    console.log('[echo] unmounted', {
      echo: subscriptions[channelNameIndex],
      channelNameIndex,
      channelName,
    })
  })

  return {
    channel: echo.channel,
    close: () => {
      console.log('[echo] closing, stopping subscriptions', channelName)
      listenToEchoEvents(false)

      useTimeoutFn(() => {
        if (subscriptions[channelNameIndex]?.subscriptions <= 0) {
          console.log('[echo] closing, leaving channel', channelName)
          // EchoClient.leave(channelName)
          delete subscriptions[channelNameIndex]
        }
      }, 500)
    },
    socketId,
  }
}

export default useEchoChannel
