<template>
  <TransitionRoot appear :show="show" as="div" class="command-root">
    <Dialog as="div" class="command-dialog" @close="$emit('close')">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="command-dialog-mask">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel ref="wrapperRef" class="command-dialog-wrapper">
              <Combobox @update:model-value="$emit('select', $event)">
                <div class="command-dialog-header">
                  <slot name="header" />
                </div>
                <div class="command-dialog-body">
                  <slot />
                </div>
              </Combobox>
            </DialogPanel>
          </TransitionChild>
        </div>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { Combobox, Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { onClickOutside } from '@vueuse/core'
import { ref } from 'vue'

defineProps({
  show: { type: Boolean, required: true },
})

const emit = defineEmits(['close', 'select'])

const wrapperRef = ref(null)

onClickOutside(wrapperRef, () => emit('close'))
</script>
