import { computed } from 'vue'
import { useRoute } from 'vue-router'

export default function () {
  const currentRoute = computed(() => useRoute())

  return {
    inEditor: computed(() => ['document.edit'].includes(<string>currentRoute.value?.name)),
    inSettings: computed(() => currentRoute.value?.name?.startsWith('settings.') || false),
    inTopic: computed(() => currentRoute.value?.name === 'topics.show'),
    inExplore: computed(() => currentRoute.value?.name === 'explore'),
    inInbox: computed(() => currentRoute.value?.name === 'inbox'),
  }
}
